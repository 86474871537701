import {Segment} from "../../../model/Segment";
import SegmentListFilter from "./SegmentListFilter";
import {Map} from "immutable";

export enum SegmentListPayloadAction {
    SetSegmentListItemsIsLoading = "SegmentListPayloadAction.SetSegmentListItemsIsLoading",
    SetSegmentListItems = "SegmentListPayloadAction.SetSegmentListItems",
    SetSegmentListTotalItems = "SegmentListPayloadAction.SetSegmentListTotalItems",
    SetSegmentListPosition = "SegmentListPayloadAction.SetSegmentListPosition",
    SetIsSegmentSaving = "SegmentListPayloadAction.SetIsSegmentSaving",
    SetIsUpdatingSegments = "SegmentListPayloadAction.SetIsUpdatingSegments",
    SetProjectUrl = "SegmentListPayloadAction.SetProjectUrl",
    ToggleSelectAll = "SegmentListPayloadAction.ToggleSelectAll",
    SelectSegment = "SegmentListPayloadAction.SelectSegment",
    DeselectSegment = "SegmentListPayloadAction.DeselectSegment",
    ToggleWithControls = "SegmentListPayloadAction.ToggleWithControls",
    ToggleClearTargetAlert = "SegmentListPayloadAction.ToggleClearTargetAlert",
    SetLastClickedSegment = "SegmentListPayloadAction.SetLastClickedSegment",
    PositionToResetAfterIndex = "SegmentListPayloadAction.PositionToResetAfterIndex"
}

interface ISetSegmentListItemsIsLoading {
    action: SegmentListPayloadAction.SetSegmentListItemsIsLoading,
    isLoading: boolean
}

interface ISetSegmentListItemsPayload {
    action: SegmentListPayloadAction.SetSegmentListItems,
    items: Map<number, Segment>,
}

interface ISetSegmentListFilterPayload {
    action: SegmentListPayloadAction.SetSegmentListTotalItems,
    filter: SegmentListFilter
    totalsItems: number
}

interface ISetSegmentListPositionPayload {
    action: SegmentListPayloadAction.SetSegmentListPosition,
    position: number
}

interface ISetIsSegmentSaving {
    action: SegmentListPayloadAction.SetIsSegmentSaving,
    isSegmentSaving: boolean
}

interface ISetIsUpdating {
    action: SegmentListPayloadAction.SetIsUpdatingSegments,
    isUpdating: boolean
}

interface ISetProjectUrlPayload {
    action: SegmentListPayloadAction.SetProjectUrl,
    projectUrl: string | null
}

interface IToggleSelectAll {
    action: SegmentListPayloadAction.ToggleSelectAll,
}

interface IToggleWithControls {
    action: SegmentListPayloadAction.ToggleWithControls,
}

interface IToggleClearTargetAlert {
    action: SegmentListPayloadAction.ToggleClearTargetAlert,
}

interface ISelectSegment {
    action: SegmentListPayloadAction.SelectSegment,
    id: number
}

interface ISetLastClickedSegment {
    action: SegmentListPayloadAction.SetLastClickedSegment,
    position: number | null
}

interface IPositionToResetAfterIndex {
    action: SegmentListPayloadAction.PositionToResetAfterIndex,
    position: number | null
}

interface IDeselectSegment {
    action: SegmentListPayloadAction.DeselectSegment,
    id: number
}

export type SegmentListPayload = ISetSegmentListPositionPayload
    | ISetSegmentListItemsIsLoading
    | ISetSegmentListItemsPayload
    | ISetSegmentListFilterPayload
    | ISetIsSegmentSaving
    | ISetIsUpdating
    | ISetProjectUrlPayload
    | IToggleSelectAll
    | ISelectSegment
    | IDeselectSegment
    | IToggleWithControls
    | ISetLastClickedSegment
    | IToggleClearTargetAlert
    | IPositionToResetAfterIndex;

export function setIsLoading(isLoading: boolean): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetSegmentListItemsIsLoading,
        isLoading: isLoading
    }
}

export function setSegmentListItems(items: Map<number, Segment>): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetSegmentListItems,
        items: items,
    }
}

export function setSegmentListTotalItems(totalItems: number, filter: SegmentListFilter): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetSegmentListTotalItems,
        totalsItems: totalItems,
        filter: filter
    }
}

export function setSegmentListPosition(position: number): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetSegmentListPosition,
        position: position
    }
}

export function setIsSegmentSaving(isSegmentSaving: boolean): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetIsSegmentSaving,
        isSegmentSaving: isSegmentSaving
    }
}

export function setIsUpdating(isUpdating: boolean): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetIsUpdatingSegments,
        isUpdating: isUpdating
    }
}

export function setProjectUrl(projectUrl: string | null): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetProjectUrl,
        projectUrl: projectUrl
    }
}

export function toggleSelectAll(): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.ToggleSelectAll
    }
}

export function selectSegment(id: number): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SelectSegment,
        id: id
    }
}

export function deselectSegment(id: number): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.DeselectSegment,
        id: id
    }
}

export function toggleWithControls(): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.ToggleWithControls
    }
}

export function toggleClearTargetAlert(): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.ToggleClearTargetAlert
    }
}

export function setLastClickedSegment(position: number | null): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetLastClickedSegment,
        position: position
    }
}

export function setPositionToResetAfterIndex(position: number | null): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.PositionToResetAfterIndex,
        position: position
    }
}
