import Immutable, {Set} from "immutable";
import {IFilter} from "../../model/common/Filter";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam} from "../../utils/ListViewUtils";
import dayjs from "dayjs";

export interface ICatEventGroupOperatorsFilter {
}

export interface ICatEventGroupFilter extends ICatEventGroupOperatorsFilter {
    from: Date | null
    to: Date | null
    projectId: number
    isRevertible: boolean | null
    fileName: string | null
    authors: Set<string> | null
    eventTypes: Set<string> | null
}

const CatEventGroupFilterRecord = Immutable.Record<ICatEventGroupFilter>({
    from: null,
    to: null,
    projectId: 0,
    isRevertible: true,
    fileName: null,
    authors: null,
    eventTypes: null
})

export default class CatEventGroupFilter extends CatEventGroupFilterRecord implements IFilter<CatEventGroupFilter> {
    updateWithGridFilterModel(_model: GridFilterModel): CatEventGroupFilter {
        return this;
    }

    updateWithGridSortingModel(_model: GridSortModel): CatEventGroupFilter {
        return this;
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "fileName", this.fileName);
        addSearchParam(result, "authors", this.authors ? this.authors.toArray() : null);
        addSearchParam(result, "eventTypes", this.eventTypes ? this.eventTypes.toArray() : null);
        addSearchParam(result, "from", this.from ? this.from.toISOString() : null);
        addSearchParam(result, "to", this.to ? this.to.toISOString() : null);
        return result.join("&");
    }

    isValid(): boolean {
        return true;
    }

    static fromSearchParams(projectId: number, searchParams: URLSearchParams) {
        let fileName = searchParams.get("fileName");
        if (fileName)
            fileName = decodeURIComponent(fileName);

        let from = searchParams.get("from");
        let to = searchParams.get("to");

        return new CatEventGroupFilter({
            projectId: projectId,
            from: from ? dayjs(from).toDate() : null,
            to: to ? dayjs(to).toDate() : null,
            fileName: fileName,
            authors: Set(searchParams.getAll("authors")),
            eventTypes: Set(searchParams.getAll("eventTypes"))
        });
    }
}