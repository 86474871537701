import {getGridStringOperators, GridColDef, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {GridRowStringOperatorsDefault} from "../globals/Constants";
import {List, Map, Record} from "immutable";
import {OrderType} from "../model/common/Filter";
import {Language} from "../model/Language";

export function defaultColumns(isFilterable: boolean = true): GridColDef[] {
    return [{
        field: 'name',
        sortable: isFilterable,
        filterable: isFilterable,
        headerName: 'Name',
        type: 'string',
        width: 600,
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    }]
}

export function updateWithGridFilterModel<FM extends object, F extends Record<FM>>(
    filter: F,
    model: GridFilterModel,
    availableFilters: Map<any, any>): F {
    let updated = filter;
    availableFilters
        .forEach((operator, filterName) => {
                const foundItem = model.items.find(item =>
                    item.field === filterName);
                if (foundItem)
                    updated = updated.set(filterName, foundItem.value).set(operator, foundItem.operator);
                else
                    updated = updated.set(filterName, null).set(operator, null);
            }
        );
    return updated;
}

export function updateWithGridSortingModel<FM extends OrderType, F extends Record<FM>>(
    filter: F,
    model: GridSortModel,
    availableSortedFields: Map<any, any>): F {
    const emptySort = filter.set("orderBy", null).set("orderDirection", null);
    if (model.length === 0)
        return emptySort;
    const firstItem = model[0];
    const fieldNameServer = availableSortedFields.get(firstItem.field);
    if (fieldNameServer)
        return filter.set("orderBy", fieldNameServer).set("orderDirection", firstItem.sort ? firstItem.sort : null);
    return emptySort;
}

export function filterLanguages(languages: List<Language>,
                                language: string[],
                                languageOperator: string | null) {
    if (language.length === 0 || !languageOperator)
        return languages;
    switch (languageOperator) {
        case "equals":
            return languages.filter(lang =>
                lang.id.toUpperCase() === language[0].toUpperCase());
        case "startsWith":
            return languages.filter(lang =>
                lang.id.toUpperCase().startsWith(language[0].toUpperCase()));
        case "endsWith":
            return languages.filter(lang =>
                lang.id.toUpperCase().endsWith(language[0].toUpperCase()));
        case "contains":
            return languages.filter(lang =>
                lang.id.toUpperCase().includes(language[0].toUpperCase()));
        case "isAnyOf":
            return languages.filter(lang => {
                const filterLanguages = language.map(fl => fl.toUpperCase());
                return filterLanguages.includes(lang.id.toUpperCase());
            });
        default:
            return languages;
    }
}

export function addSearchParam(result: string[],
                               name: string,
                               value: string | string[] | number | number[] | boolean | null) {
    if (value === null)
        return;
    if (Array.isArray(value))
        value.forEach(current => result.push(name + "=" + encodeURIComponent(current)));
    else
        result.push(name + "=" + encodeURIComponent(value));
}

export function getFilterOperators(operators: string[]) {
    return getGridStringOperators().filter( operator =>
        operators.includes(operator.value))
}