import React, {useEffect, useState} from 'react';
import {RouterProvider} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import Box from "@mui/material/Box";
import authStore from "./flux/auth/AuthStore";
import getRouter from "./routes/Router";
import {onecIntTheme} from "./globals/themes/OnecIntTheme";
import {ThemeProvider} from "@mui/material";
import {SnackbarProvider} from 'notistack';
import AlertView from "./components/AlertView";

function App() {
    const initialAuthState = authStore.getState();

    const {initialized} = useKeycloak();
    const [user, setUser] = useState(initialAuthState.user);

    useEffect(() => {
        const authListener = authStore.addListener(() => {
            const state = authStore.getState();
            setUser(state.user);
        });

        return () => authListener.remove();
    }, []);

    if (!initialized || !user) {
        return (
            <h3>Loading...</h3>
        );
    }

    const router = getRouter();

    return (
        <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={onecIntTheme}>
                <AlertView/>
                <Box sx={{height: '100%'}}>
                    <RouterProvider router={router}/>
                </Box>
            </ThemeProvider>
        </SnackbarProvider>
    );
}

export default App;
