import { List } from "immutable";
import {User} from "../../model/User";
import {ITenantResponse, toTenantModel} from "./TenantApiModel";
import {IUserGroupResponse, toUserGroupModel} from "./UserRoleApiModel";

export function toUserModel(response: IUserResponse) {
    return new User({
        id: response.id,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        groups: List(response.roles?.map(r => toUserGroupModel(r))),
        tenant: toTenantModel(response.tenant),
        enabled: response.enabled
    });
}

export function toUserRequest(user: User) {
    return {
        userId: user.id,
        tenantId: user.tenant?.id,
        rolesId: user.groups?.map(role => role.id).toArray(),// TODO change name "role" -> group
        enabled: user.enabled
    } as IUserRequest
}

export interface IUserResponse {
    id: string,
    firstName: string,
    lastName: string,
    email: string | null,
    roles: IUserGroupResponse[],
    tenant: ITenantResponse,
    enabled: boolean
}

interface IUserRequest {
    userId: string,
    tenantId: string | null,
    rolesId: string[],
    enabled: boolean | null
}