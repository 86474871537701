import {List, Record} from "immutable";
import {PretranslateMode} from "./PretranslateMode";
import {SelectionModel} from "./SelectionModel";
import {Language} from "./Language";
import TranslationMemory from "./TranslationMemory";
import WorkflowStep from "./WorkflowStep";

export interface IPretranslateSetting {
    mode: PretranslateMode | null,
    translationMemory: TranslationMemory | null,
    workflowStep: WorkflowStep | null,
    minimalWordsAmount: number,
    targetLanguages: List<Language> | null,
    catFilesSelection: SelectionModel
}

const PretranslateSettingRecord = Record<IPretranslateSetting>({
    mode: null,
    translationMemory: null,
    workflowStep: null,
    minimalWordsAmount: 0,
    targetLanguages: null,
    catFilesSelection: SelectionModel.allSelected()
});

export class PretranslateSetting extends PretranslateSettingRecord {
    static isInvalid(settings: List<PretranslateSetting>) {
        const invalid = settings.find(value => !value.isValid) ?? null;
        return invalid != null;
    }

    get isValid() {
        return this.mode !== null && this.translationMemory !== null;
    };
}
