import {Tooltip} from "@mui/material";
import {OpenProps} from "../../globals/Types";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {TranslationMemoriesPath} from "../../routes/translation-memory/TranslationMemoriesRoute";
import {BackButton, BackButtonText, CustomArrowBack} from "../../globals/CommonComponents";

export default function TranslationMemoriesBackButton(props: OpenProps) {
    const navigate = useNavigate();

    if (!props.isOpen)
        return null;

    return <Tooltip title={"Translation memories"}>
        <BackButton onClick={() => handleBackClicked(navigate)} variant="menu2" size="small">
            <CustomArrowBack fontSize="inherit"/>
            <BackButtonText variant="body2">Translation memories</BackButtonText>
        </BackButton>
    </Tooltip>
}

function handleBackClicked(navigate: NavigateFunction) {
    navigate(TranslationMemoriesPath);
}