import {Container} from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import {DataGridContainer, FoundFragment} from "../../../../globals/CommonComponents";
import {Link} from "react-router-dom";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {styled} from "@mui/material/styles";
import {Segment} from "../../../../model/Segment";
import IconButton from "@mui/material/IconButton";
import {OpenInNew} from "@mui/icons-material";
import {flatGroupedSegmentListActions} from "../../../../flux/segment/list/SegmentListActions";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import FlatGroupedListView from "../../../common/FlatGroupedListView";
import {CatFile} from "../../../../model/CatFile";
import {getSegmentPath} from "../../../../routes/editor/CatFileEditorRoute";

const BreakWord = styled(Typography)({
    verticalAlign: 'top',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
});

const SegmentIdFont = styled(Typography)({
    backgroundColor: '#eee',
    color: '#757575',
    padding: '1px 8px',
    verticalAlign: 'top',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
});

export default function ProjectSearchView() {
    return <FlatGroupedListView actions={flatGroupedSegmentListActions}
                                columns={columns}
                                headerDrawer={(group, key) => {
                                    if (!group.isHeader)
                                        return <Container/>;

                                    switch (group.headerLevel) {
                                        case 1:
                                            return (

                                                <BreakWord variant={"h6"}
                                                           key={`${key}`}>{group.header}</BreakWord>

                                            )
                                        default:
                                            return (

                                                <BreakWord variant={"body2"}
                                                           key={`${key}`}>{group.header}</BreakWord>

                                            )
                                    }
                                }}
    />
}

const columns: GridColDef[] = [
    {
        field: 'source',
        headerName: 'source',
        renderCell: (params: GridRenderCellParams<Segment>) => {
            const segment = params.row;

            const filter = flatGroupedSegmentListActions.state.filter as SegmentListFilter;
            const key = "row-" + segment.id;
            return (<DataGridContainer key={`${key}-data-grid-source`}>
                    <BreakWord variant={"body2"} key={`${key}-break-word-source`}>
                        {drawFormattedText(segment.source, filter.source, key + "source")}
                    </BreakWord>
                    <SegmentIdFont variant={"caption"} key={`${key}-segment-id-source`}>
                        {drawFormattedText(segment.sourceId, filter.sourceId, key + "source")}
                    </SegmentIdFont>
                </DataGridContainer>

            )
        },
        type: 'string',
        flex: 5
    },
    {
        field: 'target',
        headerName: 'target',
        renderCell: (params: GridRenderCellParams<Segment>) => {
            const segment = params.row;

            const filter = flatGroupedSegmentListActions.state.filter as SegmentListFilter;
            const key = "row-" + segment.id;
            return (
                <BreakWord variant={"body2"} key={`${key}-break-word-target`}>
                    {drawFormattedText(segment.translation.target, filter.target, key + "target")}
                </BreakWord>
            )
        },
        type: 'string',
        flex: 5
    },
    {
        field: 'actions',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Segment>) => {
            const segment = params.row;

            const filter = flatGroupedSegmentListActions.state.filter as SegmentListFilter;

            return (
                <Link key={`link-${segment.id}`} to={getSegmentPath(filter
                        .set("catFile", new CatFile({id: segment.catFileId}))
                        .set("language", segment.translation.languageCode),
                    segment.order + 1,
                    false)}>
                    <IconButton key={`icon-button-${segment.id}`}>
                        <OpenInNew key={`open-in-new-${segment.id}`}/>
                    </IconButton>
                </Link>
            )
        },
        type: 'string',
        flex: 1
    }
];

function drawFormattedText(text: string, searchText: string | null, key: string) {
    if (searchText === null)
        return text;

    const textFragments: React.JSX.Element[] = [];
    const lowerCaseText = text.toLowerCase();
    let currentPos = 0;
    while (true) {
        const foundPos = lowerCaseText.indexOf(searchText.toLowerCase(), currentPos);
        if (foundPos === -1) {
            textFragments.push(<span key={`${key}-span-` + currentPos}>{text.substring(currentPos)}</span>);
            break;
        }
        textFragments.push(<span key={`${key}-span-` + currentPos}>{text.substring(currentPos, foundPos)}</span>);
        textFragments.push(
            <FoundFragment key={`${key}-found-fragment-` + currentPos}>
                {text.substring(foundPos, foundPos + searchText.length)}
            </FoundFragment>);
        currentPos = foundPos + searchText.length;
    }
    return textFragments;
}