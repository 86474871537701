import {CommonListActions} from "./CommonListActions";
import {Model} from "../../../model/common/IModel";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {List, Map} from "immutable";
import {IFilter} from "../../../model/common/Filter";
import {InfiniteListActions} from "./InfiniteListActions";

export class GroupedListActions<
    Group extends Model,
    GroupFilter extends IFilter<GroupFilter>,
    Details extends Model,
    DetailsFilter extends IFilter<DetailsFilter>
> extends InfiniteListActions<Group, GroupFilter> {

    constructor(
        fetchModels: (filter: GroupFilter, lastItem?: Group) => Promise<List<Group>>,
        detailsBuilder: () => CommonListActions<Details, DetailsFilter>,
        groupToDetailsFilter: (group: Group) => DetailsFilter,
        resource: AvailableResourceType) {

        super(fetchModels, resource);
        this._detailsBuilder = detailsBuilder;
        this._groupToDetailsFilter = groupToDetailsFilter;
    }

    private _details: Map<Group, CommonListActions<Details, DetailsFilter>> = Map();
    private readonly _groupToDetailsFilter: (group: Group) => DetailsFilter
    private readonly _detailsBuilder: () => CommonListActions<Details, DetailsFilter>;

    async loadMoreItems(filter?: GroupFilter): Promise<void> {
        await super.loadMoreItems(filter);

        const groups = this.store.getState().items;
        for (const group of groups) {
            if (this._details.has(group))
                continue

            this._details = this._details.set(group, this._detailsBuilder());
        }
    }

    getDetails(group: Group) {
        let detailsActions = this._details.get(group);
        if (!detailsActions) {
            detailsActions = this._detailsBuilder();
            this._details = this._details.set(group, detailsActions);
        }

        detailsActions.fetch(this._groupToDetailsFilter(group));

        return detailsActions;
    }
}