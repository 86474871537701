import axios, {AxiosError} from "axios";
import {Page} from "../model/Page";
import {setAlertAction, setWaitingForAlertAction} from "../flux/alert/AlertActions";
import {Pageable} from "../model/Pageable";
import {isCollection, List} from "immutable";
import {IFilter} from "../model/common/Filter";
import {IResponseData} from "./ApiModels";
import {Build} from "../model/Build";
import Alert from "../model/Alert";
import {AlertColor} from "@mui/material";

export const MultipartFormDataConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export function dispatchUnsupportedOperation() {
    setAlertAction(new Alert({
        message: "Unsupported operation",
    }));
}

export function dispatchAlert(message: string, severity: AlertColor) {
    setAlertAction(new Alert({
        message: message,
        severity: severity
    }));
    setWaitingForAlertAction(false);
}

export function dispatchError(error: AxiosError<IResponseData>) {
    setAlertAction(new Alert({
        message: getErrorText(error),
    }));
}

export function dispatchEditorError(error: AxiosError<IResponseData>) {
    setAlertAction(new Alert({
        message: getErrorText(error),
    }));
}

export function dispatchFetchPageError<T>(error: AxiosError<IResponseData>) {
    setAlertAction(new Alert({
        message: getErrorText(error),
    }));
    return new Page<T>();
}

export function dispatchFetchListError<T>(error: AxiosError<IResponseData>) {
    setAlertAction(new Alert({message: getErrorText(error)}));
    return List<T>();
}

export function buildUrl(pathTemplate: string, pathVariables?: any, parameters?: any) {
    let url = new URL(pathTemplate, 'https://dummy.com');

    if (pathVariables)
        url.pathname = url.pathname.replace(/:(\w+)/g, (_substring, key) => pathVariables[key]);

    if (parameters)
        fillParameters(url, parameters);

    return url.pathname + url.search;
}

export function toParameters<F extends IFilter<F>>(
    filter?: F,
    page?: Pageable,
    groupBy?: string[]) {
    const backendPage = !page ? new Pageable().asBackendPage() : page.asBackendPage();
    let parameters: any = {
        page: backendPage.number,
        size: backendPage.size,
    }
    if (!filter)
        return parameters;

    for (const [key, value] of Object.entries(filter.toObject())) {
        if (isEmpty(value) || isEmpty(key))
            continue;

        if (isCollection(value))
            parameters[key] = value.toArray().length === 0 ? Array.of("") : value.toArray();
        else
            parameters[key] = value;
    }

    if (groupBy)
        parameters["groupBy"] = groupBy;

    return parameters;
}

export function emptyAsNull(value: string | number) {
    if (value === 0)
        return null;

    if (value === '')
        return null;

    return value;
}

export function downloadFile(endpoint: string, build: Build) {
    const fileDownload = require("js-file-download");
    axios
        .get(endpoint + "/" + build.id + "/download", {responseType: 'blob'})
        .then(response => {
            const contentDisposition = response.headers['content-disposition'];
            const filename = decodeURIComponent(contentDisposition
                .split(';')[1]
                .trim()
                .split('=')[1]
                .replace(/"/g, ''));
            fileDownload(response.data, filename);
        })
        .catch(dispatchError);
}

function fillParameters(url: URL, parameters: any) {
    const urlParams = new URLSearchParams();
    for (const key in parameters) {
        const value = parameters[key];
        if (isEmpty(value))
            continue;

        if (Array.isArray(value))
            value.forEach(item => urlParams.append(key, item));
        else if (value instanceof Date)
            urlParams.append(key, value.toISOString());
        else
            urlParams.append(key, value);
    }
    url.search = urlParams.toString();
}

function getErrorText(error: AxiosError<IResponseData>) {
    if (!error.response)
        return error.message;

    let text = error.response.data.message;

    if (!text)
        text = error.response.statusText;

    return text;
}

function isEmpty(value: any) {
    return value === null || value === undefined;
}