import TranslationMemoriesSearchView from "../../components/translation-memory/TranslationMemoriesSearchView";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";

export default function TranslationMemoriesSearchRoute() {
    return <TranslationMemoriesSearchView/>
}

export function getTranslationMemorySearchPath(filter: TranslationMemorySegmentFilter) {
    return TranslationMemoriesSearchPath + "?" + filter.toSearchParams();
}

export const TranslationMemoriesSearchId = "TranslationMemoriesSearchRoute";
export const TranslationMemoriesSearchPath = "/translation-memories/search";