import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {generateReportAction} from "../../flux/report/list/ReportListActions";
import {catFileSelectActions} from "../../flux/cat-files/select/CatFileSelectActions";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import ListView from "../common/ListView";
import {CatFile} from "../../model/CatFile";
import {GridColDef} from "@mui/x-data-grid";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {headerWithSelectAll} from "../common/SelectDialog";

type ReportSettingsProps = {
    isOpen: boolean,
    onClose: () => void
}

export default function ReportProjectFiles(props: ReportSettingsProps) {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const projectState = projectPageStore.getState();

    useEffect(() => {
        catFileSelectActions.fetch(new CatFilesFilter({projectId: projectState.project.id}));

        setIsOpen(props.isOpen);

        return () => {};
    }, [projectState.project.id, props.isOpen]);


    return (
        <Dialog open={isOpen}
                onClose={props.onClose}
                fullWidth
                maxWidth="md"
                slotProps={{backdrop: {style: {backgroundColor: 'transparent'}}}}>
            <DialogTitle>Project Files</DialogTitle>
            <DialogContent>
                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.onClose();
                            handleGenerateReportClicked();
                        }}
                        style={{marginBottom: '18px', marginRight: '26px'}}>
                        GENERATE REPORT
                    </Button>
                </Stack>
                <ListView actions={catFileSelectActions}
                          columns={getColumns()}
                          getRowId={(row: CatFile) => row.id.toString() + row.language}
                          navigateProps={null}
                          initialFilter={new CatFilesFilter()}
                          useNewSelectionModel={true}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onClose()
                }}
                        color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function handleGenerateReportClicked() {
    generateReportAction();
}

function getColumns(): GridColDef<CatFile>[] {
    return [{
        field: 'file',
        renderHeader: headerWithSelectAll(catFileSelectActions),
        type: 'string',
        flex: 5,
        sortable: false,
        valueGetter: (params: GridValueGetterParams<CatFile>) => params.row.name
    }
    ]
}