import {List, Map, Record} from "immutable";
import {IFilter} from "../../model/common/Filter";
import {GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../utils/ListViewUtils";
import {isFilterInvalid} from "../../utils/FilterUtils";

interface ICatFilesOperatorsFilter {
    fileOperator: string | null,
    languageOperator: string | null,
    statusOperator: string | null
}

interface ICatFilesFilter extends ICatFilesOperatorsFilter {
    projectId: number,
    file: string | null,
    targetLanguages: List<string>,
    language: string | string[],
    status: List<number> | null,
    orderBy: string | null,
    orderDirection: string | null
}

const CatFilesFilterRecord = Record<ICatFilesFilter>({
    projectId: 0,
    file: null,
    fileOperator: null,
    language: [],
    languageOperator: null,
    status: null,
    statusOperator: null,
    targetLanguages: List<string>(),
    orderBy: null,
    orderDirection: null
});

export default class CatFilesFilter extends CatFilesFilterRecord implements IFilter<CatFilesFilter> {
    updateWithGridFilterModel(model: GridFilterModel): CatFilesFilter {
        const updatedFilterModel: GridFilterModel = {
            items: model.items.map((item) => ({
                ...item,
                field: item.field === 'name' ? 'file' : item.field
            })),
        };
        return updateWithGridFilterModel<ICatFilesFilter, CatFilesFilter>(this, updatedFilterModel, AvailableCatFileFilters);
    }

    updateWithGridSortingModel(model: GridSortModel): CatFilesFilter {
        return updateWithGridSortingModel<ICatFilesFilter, CatFilesFilter>(this, model, AvailableCatFileSortedFields);
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "file", this.file);
        addSearchParam(result, "fileOperator", this.fileOperator);
        addSearchParam(result, "language", this.language);
        addSearchParam(result, "languageOperator", this.languageOperator);
        addSearchParam(result, "status", this.status ? this.status.toArray() : null );
        addSearchParam(result, "statusOperator", this.statusOperator);
        addSearchParam(result, "orderBy", this.orderBy);
        addSearchParam(result, "orderDirection", this.orderDirection);

        return result.join('&');
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.file && this.fileOperator)
            items.push({field: "file", operator: this.fileOperator, value: this.file});
        if (this.language && this.languageOperator)
            items.push({field: "language", operator: this.languageOperator, value: this.language});
        if (this.status && this.statusOperator)
            items.push({field: "status", operator: this.statusOperator, value: this.status});
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        if (this.orderBy && this.orderDirection) {
            const foundEntry = AvailableCatFileSortedFields.findEntry(value =>
                value === this.orderBy);
            if (foundEntry === undefined)
                return [];
            return [{field: foundEntry[0], sort: this.orderDirection as GridSortDirection}];
        }
        return [];
    }

    isValid(): boolean {
        return !(isFilterInvalid(this.file, this.fileOperator)
            || isFilterInvalid(this.language, this.languageOperator)
            || isFilterInvalid(this.status ? this.status.toArray() : null, this.statusOperator));
    }

    static fromSearchParams(projectId: number,
                            targetLanguages: List<string>,
                            searchParams: URLSearchParams) {
        let file = searchParams.get("file");
        if (file)
            file = decodeURIComponent(file);

        return new CatFilesFilter({
            projectId: projectId,
            file: file,
            fileOperator: searchParams.get("fileOperator"),
            language: searchParams.getAll("language").map(language => decodeURIComponent(language)),
            languageOperator: searchParams.get("languageOperator"),
            status: List(searchParams.getAll("status").map(status => Number(status))),
            statusOperator: searchParams.get("statusOperator"),
            targetLanguages: targetLanguages,
            orderBy: searchParams.get("orderBy"),
            orderDirection: searchParams.get("orderDirection")
        })
    }
}

const AvailableCatFileFilters = Map<string, string>()
    .set('file', 'fileOperator')
    .set('language', 'languageOperator')
    .set('status', 'statusOperator');

const AvailableCatFileSortedFields = Map<string, string>()
    .set('file', 'fullName');