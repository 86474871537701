import {
    ButtonSegmentCell,
    ButtonWrapper,
    SaveTranslationButton,
    SegmentCell,
    SegmentsTableRow
} from "../../globals/CommonComponents";
import React, {useEffect, useState} from "react";
import {TranslationMemorySegment} from "../../model/TranslationMemorySegment";
import translationMemorySegmentEditorStore
    from "../../flux/translation-memory-segment/editor/TranslationMemorySegmentEditorStore";
import {Slate} from "slate-react";
import {CustomEditable, CustomEditableTarget} from "../../utils/slate/SlateUtils";
import {RenderedSourceElement} from "../cat-file-editor/frames/segments/rendering/RenderedSourceElement";
import Typography from "@mui/material/Typography";
import {
    setTranslationMemorySegmentAction
} from "../../flux/translation-memory-segment/editor/TranslationMemorySegmentEditorActions";
import {Descendant, Editor, Transforms} from "slate";
import {
    saveTranslationMemorySegmentAction,
    updateCurrentTranslationAction
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";
import slateSerializer from "../../utils/slate/SlateSerializer";
import {RenderedText} from "../cat-file-editor/frames/segments/rendering/RenderedText";
import {RenderedTargetElement} from "../cat-file-editor/frames/segments/rendering/RenderedTargetElement";
import {SlatePlaceholder} from "../../utils/slate/SlateModels";
import {IdSequence} from "../../globals/IdSequence";

type TranslationMemorySegmentViewProps = {
    segment: TranslationMemorySegment,
    index: number,
    style: React.CSSProperties
}

export default function TransaltionMemorySegmentView(props: TranslationMemorySegmentViewProps) {
    const [isEditing, setIsEditing]
        = useState(props.segment.id === translationMemorySegmentEditorStore.getState().segment.id);

    useEffect(() => {
        const translationMemorySegmentEditor = translationMemorySegmentEditorStore
            .addListener(() => {
                const state = translationMemorySegmentEditorStore.getState();
                setIsEditing(state.segment.id === props.segment.id);
            });

        return () => translationMemorySegmentEditor.remove();
    });

    return <SegmentsTableRow isEditing={isEditing} isLocked={false}
                             style={{...props.style, ...{wordBreak: 'break-all'}}} direction={"row"}>
        <SegmentCell style={{minWidth: '50px', width: '50px', whiteSpace: 'nowrap'}}>
            <Typography textAlign={"left"}>{props.index + 1}</Typography>
        </SegmentCell>
        <SegmentCell style={{flexGrow: 1}}>
            <Slate editor={props.segment.sourceEditor} initialValue={props.segment.sourceEditor.children}>
                <CustomEditable readOnly={true}
                                renderElement={renderProps =>
                                    <RenderedSourceElement children={renderProps.children}
                                                           element={renderProps.element}
                                                           attributes={renderProps.attributes}
                                                           onPlaceholder={placeholder =>
                                                               handlePlaceholderClicked(
                                                                   props.segment.targetEditor,
                                                                   placeholder)}
                                                           id={props.segment.id}/>}
                                renderLeaf={(props) =>
                                    <RenderedText children={props.children} attributes={props.attributes}
                                                  leaf={props.leaf} text={props.text}/>}/>
                <Typography style={{marginTop: '0px', paddingTop: '0px', lineHeight: 1.5}} fontSize={12}
                            color={"#97A0AF"}>{props.segment.sourceId}</Typography>
            </Slate>
        </SegmentCell>
        <SegmentCell style={{flexGrow: 1}}>
            <Slate editor={props.segment.targetEditor} initialValue={props.segment.targetEditor.children}
                   onValueChange={value => handleValueChanged(value, props.index)}>
                <CustomEditableTarget onClick={() => handleTargetClicked(props.segment)}
                                      isEditing={isEditing}
                                      style={props.style}
                                      renderElement={(props) =>
                                          <RenderedTargetElement children={props.children} element={props.element}
                                                                 attributes={props.attributes}/>}
                                      renderLeaf={(props) =>
                                          <RenderedText children={props.children} attributes={props.attributes}
                                                        leaf={props.leaf} text={props.text}/>}/>
            </Slate>
        </SegmentCell>
        <ButtonSegmentCell>
            <ButtonWrapper>
                <SaveTranslationButton variant="contained" color="primary"
                                       id={`translate-segment-${props.segment.id}`}
                                       disabled={props.segment.target === props.segment.currentTarget}
                                       onClick={() => handleSaveClicked(props.index, props.segment)}>
                    Save
                </SaveTranslationButton>
            </ButtonWrapper>
        </ButtonSegmentCell>
    </SegmentsTableRow>
}

function handleTargetClicked(segment: TranslationMemorySegment) {
    setTranslationMemorySegmentAction(segment);
}

function handleSaveClicked(index: number, segment: TranslationMemorySegment) {
    saveTranslationMemorySegmentAction(index, segment);
}

function handleValueChanged(value: Descendant[], index: number) {
    updateCurrentTranslationAction(index, slateSerializer.serialize(value));
}

function handlePlaceholderClicked(targetEditor: Editor, placeholder: SlatePlaceholder) {
    const withUniqueId = {...placeholder, id: IdSequence.Instance.next()}
    Transforms.insertNodes(targetEditor, withUniqueId);
}