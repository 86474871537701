import React, {useEffect, useState} from "react";
import {Container, Stack, Tab} from "@mui/material";
import {ResponsiveTabs} from "../../../globals/CommonComponents";
import ConcordanceSearchView from "./concordance-search/ConcordanceSearchView";
import CatInfoView from "./cat-info/CatInfoView";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import ErrorView from "./warnings/ErrorView";
import {styled} from "@mui/material/styles";
import concordanceSearchStore from "../../../flux/concordance-search/ConcordanceSearchStore";

enum TabName {
    ConcordanceSearch = "ConcordanceSearch",
    CatInfo = "CatInfo",
    Error = "Error"
}

const TabContainer = styled(Container)({
    margin: 0,
    maxWidth: '500px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
});

const SimpleTab = styled(Tab)({
    textTransform: 'none',
    minWidth: '55px',
    flexGrow: 1,
    flexShrink: 1,
    height: '42px'
});

export default function BottomRightFrame() {
    const [currentTab, setCurrentTab] = useState<TabName>(TabName.CatInfo);
    const [currentConcordanceSearchType, setCurrentConcordanceSearchType] = useState("");
    const [currentConcordanceSearchText, setCurrentConcordanceSearchText] = useState("");

    const handleTabChanged = (value: TabName) => {
        setCurrentTab(value);
    }

    useEffect(() => {
        const concordanceSearchListener = concordanceSearchStore.addListener(() => {
            const state = concordanceSearchStore.getState();
            let changeTab = false;
            if (currentConcordanceSearchType !== state.concordanceSearchType) {
                setCurrentConcordanceSearchType(state.concordanceSearchType);
                changeTab = true;
            }
            if (currentConcordanceSearchText !== state.concordanceSearchText) {
                setCurrentConcordanceSearchText(state.concordanceSearchText);
                changeTab = true;
            }
            if (changeTab)
                setCurrentTab(TabName.ConcordanceSearch);
        });

        return () => concordanceSearchListener.remove();
    });

    function drawTab() {
        switch (currentTab) {
            case TabName.CatInfo:
                return <CatInfoView/>;
            case TabName.ConcordanceSearch:
                return <ConcordanceSearchView/>;
            case TabName.Error:
                return <ErrorView/>;
        }
    }

    return (
        <TabContainer maxWidth="sm" disableGutters>
            <Stack sx={{height: '100%', padding: 0, maxWidth: '500px'}}>
                <Container disableGutters>
                    <ResponsiveTabs value={currentTab} onChange={(_event, value) =>
                        handleTabChanged(value)}>
                        <SimpleTab label={"INFO"} value={TabName.CatInfo}/>
                        <SimpleTab label={"CONCORDANCE"} value={TabName.ConcordanceSearch}/>
                        <SimpleTab value={TabName.Error}
                                   label={
                                       <div style={{display: 'flex', alignItems: 'center'}}>
                                           <ErrorOutline color="error" style={{fontSize: 20, marginRight: 8}}/>
                                           <span>{"WARNINGS"}</span>
                                       </div>
                                   }
                        />
                    </ResponsiveTabs>
                </Container>
                {drawTab()}
            </Stack>
        </TabContainer>
    )
}