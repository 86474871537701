import {PretranslateSetting} from "../../model/PretranslateSetting";
import TranslationMemory from "../../model/TranslationMemory";
import WorkflowStep from "../../model/WorkflowStep";
import {Language} from "../../model/Language";
import {SelectionModel} from "../../model/SelectionModel";
import Immutable, {List} from "immutable";
import {PretranslateState} from "./PretranslateStore";

// TODO find out more convenient way for serialization|deserialization
export function restore(data: string) {
    if (data.trim() === "")
        return new PretranslateState();

    const parsed = JSON.parse(data).settings as Object[];
    const converted = parsed.map(value => toPretranslateSetting(value));
    return new PretranslateState({settings: List(converted)});
}

function toPretranslateSetting(value: any) {
    return new PretranslateSetting({
        mode: toPretranslateMode(value.mode),
        translationMemory: toTranslationMemory(value.translationMemory),
        workflowStep: toWorkflowStep(value.workflowStep),
        minimalWordsAmount: value.minimalWordsAmount,
        targetLanguages: toLanguageList(value.targetLanguages),
        catFilesSelection: toSelectionModel(value.catFilesSelection)!
    })
}

function toLanguageList(targetLanguages: any)
{
    if(!targetLanguages)
        return null;

    return List((targetLanguages as any[]).map(v => toLanguage(v)!))
}

function toPretranslateMode(value: any) {
    if (!value)
        return null;
    return {
        mode: value.mode,
        presentation: value.presentation
    };
}

function toTranslationMemory(value: any) {
    if (!value)
        return null;

    return new TranslationMemory({
        id: value.id,
        name: value.name,
        sourceLanguage: toLanguage(value.sourceLanguage),
        targetLanguages: toLanguageList(value.targetLanguages)!,
        translationMemoryState: value.translationMemoryState
    })
}

function toLanguage(value: any) {
    if (!value)
        return null;

    return new Language({
        id: value.id,
        name: value.name
    })
}

function toWorkflowStep(value: any) {
    if (!value)
        return null;

    return new WorkflowStep({id: value.id, name: value.name});
}

function toSelectionModel(value: any) {
    if (!value)
        return null;

    return new SelectionModel({
        isSelectAll: value.isSelectAll,
        invertedSelection: Immutable.Set(value.invertedSelection)
    })
}