import {Stack} from "@mui/material";
import React from "react";
import TranslationMemorySegmentList from "./TranslationMemorySegmentList";
import TranslationMemorySegmentListControls from "./TranslationMemorySegmentListControls";
import {CollapseExpandButton} from "../../globals/CommonComponents";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";

type TopFrameProperties = {
    isBottomHidden: boolean,
    setIsBottomHidden: (isHidden: boolean) => void;
}

export default function TopFrame(props: TopFrameProperties) {
    return <Stack sx={{height: '100%', overflow: 'hidden'}}>
        <Stack style={{
            flex: 'auto',
            paddingTop: '15px',
            paddingLeft: '10px',
            paddingRight: '10px',
            overflow: 'hidden'
        }}>
            <TranslationMemorySegmentListControls/>
            <TranslationMemorySegmentList/>
        </Stack>
        <CollapseExpandButton
            isBottomHidden={props.isBottomHidden}
            onClick={() => toggleBottomFrame(props.setIsBottomHidden, props.isBottomHidden)}>
            <span className="button-text">{props.isBottomHidden ? 'SHOW INFO' : 'HIDE INFO'}</span>
            {drawCollapseButtonArrow(props.isBottomHidden)}
        </CollapseExpandButton>
    </Stack>
}

function drawCollapseButtonArrow(isBottomHidden: boolean) {
    return isBottomHidden ? <ArrowDropUp className="arrow-icon"/> : <ArrowDropDown className="arrow-icon"/>;
}

function toggleBottomFrame(setIsBottomHidden: (isHidden: boolean) => void, isBottomHidden: boolean) {
    setIsBottomHidden(!isBottomHidden);
}