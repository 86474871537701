import {dispatchUnsupportedOperation} from "../../globals/ApiGlobals";
import {List} from "immutable";
import {CommonListActions} from "../common/list/CommonListActions";
import {AvailableResourceType} from "../../model/AvailableResources";
import NullFilter from "../common/NullFilter";
import {CatEventTypeModel} from "../../model/CatEventTypeModel";
import {Page} from "../../model/Page";

export const eventTypeActions = new CommonListActions<CatEventTypeModel, NullFilter>(
    async () => new Page<CatEventTypeModel>().setList(CatEventTypeModel.all()),
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<CatEventTypeModel>();
    },
    AvailableResourceType.SegmentActivity
);

export const segmentEventTypeActions = new CommonListActions<CatEventTypeModel, NullFilter>(
    async () => new Page<CatEventTypeModel>().setList(CatEventTypeModel.allSegmentEvents()),
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<CatEventTypeModel>();
    },
    AvailableResourceType.SegmentActivity
);
