import {List} from "immutable";
import {Model} from "../../../model/common/IModel";
import {IFilter} from "../../../model/common/Filter";

export enum InfiniteListAction {
    SetItems = "InfiniteListAction.SetItems",
    SetIsLoading = "InfiniteListAction.SetIsLoading",
}

interface ISetItems<M extends Model, F extends IFilter<F>> {
    action: InfiniteListAction.SetItems,
    storeId: string,
    items: List<M>,
    filter: F,
    hasNext: boolean
}

interface ISetIsLoading {
    action: InfiniteListAction.SetIsLoading,
    storeId: string,
    isLoading: boolean
}

export type InfiniteListPayload = ISetItems<any, any> | ISetIsLoading;

export function setItemsPayload<M extends Model, F extends IFilter<F>>(storeId: string,
                                                                       items: List<M>,
                                                                       hasNext: boolean,
                                                                       filter?: F): InfiniteListPayload {
    return {
        action: InfiniteListAction.SetItems,
        items: items,
        filter: filter,
        storeId: storeId,
        hasNext: hasNext
    }
}

export function setInfiniteListIsLoadingPayload(storeId: string, isLoading: boolean): InfiniteListPayload {
    return {
        action: InfiniteListAction.SetIsLoading,
        isLoading: isLoading,
        storeId: storeId
    }
}