import {Dispatcher} from 'flux';
import {AlertPayload} from "./alert/AlertPayload";
import {TranslationMemoryEditorPayload} from "./translation-memory/editor/TranslationMemoryEditorPayload";
import {ProjectListPayload} from "./project/list/ProjectListPayload";
import {ProjectPagePayload} from "./project/page/ProjectPagePayload";
import {ProjectEditorPayload} from "./project/editor/ProjectEditorPayload";
import {SegmentListPayload} from "./segment/list/SegmentListPayload";
import {SegmentEditorPayload} from "./segment/editor/SegmentEditorPayload";
import {GlossaryEditorPayload} from "./glossary/editor/GlossaryEditorPayload";
import {ConcordanceSearchPayload} from "./concordance-search/ConcordanceSearchPayload";
import {ProjectSearchPayload} from "./project/search/ProjectSearchPayload";
import {PlaceholdersSetListPayload} from "./placeholders-set/list/PlaceholdersSetListPayload";
import {PlaceholdersSetEditorPayload} from "./placeholders-set/editor/PlaceholdersSetEditorPayload";
import {AuthPayload} from "./auth/AuthPayload";
import {PlaceholdersSetSelectPayload} from "./placeholders-set/select/PlaceholdersSetSelectPayload";
import {ReportDataPayload} from "./report/data/ReportDataPayload";
import {ReportListPayload} from "./report/list/ReportListPayload";
import {AvailableResourcesPayload} from "./available-resources/AvailableResourcesPayload";
import {CommonListPayload} from "./common/list/CommonListPayload";
import {CommentListPayload} from "./comment/CommentListPayload";
import {RollbackPayload} from "./event/rollback/RollbackPayload";
import {CommonBuildPayload} from "./common/build/CommonBuildPayload";
import {LanguageListPayload} from "./language/list/LanguageListPayload";
import {UsersListPayload} from "./users/list/UsersListPayload";
import {VendorEditorPayload} from "./vendor/editor/VendorEditorPayload";
import {InfiniteListPayload} from "./common/list/InfinateListPayload";
import {UserGroupsPayload} from "./user-roles/UserGroupsPayload";
import {
    TranslationMemorySegmentEditorPayload
} from "./translation-memory-segment/editor/TranslationMemorySegmentEditorPayload";
import {PretranslatePayload} from "./pretranslate/PretranslatePayload";
import {TranslationMemorySearchPayload} from "./translation-memory/search/TranslationMemorySearchPayload";
import {NotificationPayload} from "./notification/NotificationPayload";

export type Payload =
    | AlertPayload
    | AuthPayload
    | AvailableResourcesPayload
    | CommentListPayload
    | CommonBuildPayload
    | CommonListPayload
    | ConcordanceSearchPayload
    | GlossaryEditorPayload
    | InfiniteListPayload
    | LanguageListPayload
    | NotificationPayload
    | PlaceholdersSetEditorPayload
    | PlaceholdersSetListPayload
    | PlaceholdersSetSelectPayload
    | ProjectEditorPayload
    | ProjectListPayload
    | ProjectPagePayload
    | ProjectSearchPayload
    | ReportDataPayload
    | ReportListPayload
    | RollbackPayload
    | SegmentEditorPayload
    | SegmentListPayload
    | TranslationMemoryEditorPayload
    | TranslationMemorySearchPayload
    | TranslationMemorySegmentEditorPayload
    | UserGroupsPayload
    | UsersListPayload
    | PretranslatePayload
    | VendorEditorPayload;

class CustomDispatcher<Payload> extends Dispatcher<Payload> {
    dispatch(payload: Payload) {
        // console.log('Dispatching payload: ', payload);
        super.dispatch(payload);
    }
}

export const dispatcher = new CustomDispatcher<Payload>();
