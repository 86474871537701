import {IconButton, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {permissionsListActions} from "../../flux/user-permissions/list/PermissionsSelectActions";
import {Permission} from "../../model/Permission";
import {getGridStringOperators, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {PermissionEntityPartType} from "../../model/PermissionEntityPart";
import AddIcon from "@mui/icons-material/Add";
import {PermissionEditor} from "./PermissionEditor";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import ListView from "../common/ListView";
import DeleteIcon from "@mui/icons-material/Delete";
import {initPermissionsEditor} from "../../flux/user-permissions/editor/PermissionsEditorActions";
import {useProject} from "../project/ProjectView";
import PermissionsFilter from "../../flux/user-permissions/PermissionsFilter";
import {GridRowStringOperatorsDefault} from "../../globals/Constants";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {getPermissionsPath} from "../../routes/project/tabs/permissions/PermissionsRoute";
import PermissionActions from "./PermissionActions";

export default function PermissionsList() {
    const {project} = useProject();
    const [searchParams] = useSearchParams();
    const [openEditor, setOpenEditor] = useState(false);
    const [filter, setFilter] = useState<PermissionsFilter | null>(null);
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const filter = PermissionsFilter.fromSearchParams(project.id, searchParams);
        setFilter(filter);
        permissionsListActions.fetch(filter);
    }, [project]);

    return (
        <Stack sx={{height: '100%'}}>
            <PermissionEditor open={openEditor} tab={tab}
                              onClose={() => handleEditorClose(setOpenEditor, navigate)} onTabChange={setTab}/>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => executeOpenEditor(setOpenEditor, navigate, tab)}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton onClick={() => permissionsListActions.deleteSelected()}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
            </Stack>
            {filter && <ListView actions={permissionsListActions} columns={columns}
                                 navigateProps={{navigator: getPermissionsPath}} initialFilter={filter}/>}
        </Stack>
    );
}

function handleEditorClose(setOpenEditor: React.Dispatch<React.SetStateAction<boolean>>,
                           navigate: NavigateFunction) {
    setOpenEditor(false);
    navigate("./");
}

function executeOpenEditor(setOpen: React.Dispatch<React.SetStateAction<boolean>>,
                           navigate: NavigateFunction,
                           tab: number) {
    const projectId = projectPageStore.getState().project.id;
    initPermissionsEditor(projectId, navigate, tab);
    setOpen(true);
}

const columns: GridColDef[] = [
    {
        field: 'username',
        sortable: false,
        headerName: 'User',
        type: 'string',
        width: 200,
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    },
    {
        field: 'catFile',
        sortable: false,
        headerName: 'File',
        type: 'string',
        width: 500,
        valueGetter: params =>
            params.row.getEntityPartTitle(PermissionEntityPartType.CatFile),
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    },
    {
        field: 'language',
        sortable: false,
        headerName: 'Language',
        type: 'string',
        width: 100,
        valueGetter: params =>
            params.row.getEntityPartTitle(PermissionEntityPartType.Language),
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    },
    {
        field: 'workflow',
        sortable: false,
        headerName: 'Workflow',
        type: 'string',
        width: 100,
        valueGetter: params =>
            params.row.getEntityPartTitle(PermissionEntityPartType.WorkflowStep),
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    },
    {
        field: 'readAllowed',
        sortable: false,
        headerName: 'Read',
        type: 'boolean',
        width: 100
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: '',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Permission>) => {
            return (
                <PermissionActions renderCellParams={params}/>
            );
        }
    }
]
