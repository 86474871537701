import React, {useEffect, useState} from "react";
import {Segment} from "../../../../model/Segment";
import {styled} from "@mui/material/styles";
import {Container, Icon, Stack, Tooltip} from "@mui/material";
import {
    checkIfCursorIsOnLastSymbol,
    CustomEditable, CustomEditableTarget,
    editorText,
    getTextBetweenPoints,
    sortRangePoints
} from "../../../../utils/slate/SlateUtils";
import Typography from "@mui/material/Typography";
import {ReactEditor, Slate} from "slate-react";
import {BasePoint, Editor, NodeEntry, Range, Transforms} from "slate";
import {isKeyHotkey} from "is-hotkey";
import Translation from "../../../../model/Translation";
import {
    approveTranslationAction,
    insertTranslationAction,
    saveTranslationAction,
    setSegmentAction,
    updateTranslationFromResourceAction
} from "../../../../flux/segment/editor/SegmentEditorActions";
import {List} from "immutable";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {RenderedTargetElement} from "./rendering/RenderedTargetElement";
import {RenderedText} from "./rendering/RenderedText";
import {RenderedSourceElement} from "./rendering/RenderedSourceElement";
import {WorkflowStepModel} from "../../../../model/WorkflowStepModel";
import LockIcon from '@mui/icons-material/Lock';
import {dateTimeFormat} from "../../../../globals/Utils";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {LoadingButton} from "@mui/lab";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import {ButtonSegmentCell, ButtonWrapper, SegmentCell, SegmentsTableRow} from "../../../../globals/CommonComponents";
import LoadingSegmentView from "./LoadingSegmentView";
import {SlateParagraph, SlatePlaceholder} from "../../../../utils/slate/SlateModels";
import SegmentSelector from "./SegmentSelector";
import {IdSequence} from "../../../../globals/IdSequence";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {dispatcher} from "../../../../flux/Dispatcher";
import {executeConcordanceSearchAction} from "../../../../flux/concordance-search/ConcordanceSearchActions";
import {
    setConcordanceSearchText,
    setConcordanceSearchType
} from "../../../../flux/concordance-search/ConcordanceSearchPayload";
import {ConcordanceSearchType} from "../../../../flux/concordance-search/ConcordanceSearchStore";

type SegmentViewProps = {
    onClick: (event: React.MouseEvent, editor: Editor) => void,
    onShiftSegment: (shift: number) => void,
    segment: Segment,
    position: number,
    isSelected: boolean,
    style: React.CSSProperties
}

const SegmentSourceCell = styled(Container)({
    verticalAlign: 'top',
    padding: '5px 5px !important'
});

export default function SegmentView(props: SegmentViewProps) {
    const segmentEditorState = segmentEditorStore.getState();

    const firstStep = projectPageStore.getState().project.getFirstStep();

    const [currentSegment, setCurrentSegment] = useState(props.segment);
    const sourceEditor = props.segment.sourceEditor;
    const targetEditor = props.segment.translation.editor;
    const [isEditing, setIsEditing] = useState(props.segment.id === segmentEditorState.segment?.id);
    const [filter, setFilter] = useState(segmentListStore.getState().filter);

    useEffect(() => {
        const segmentEditorListener = segmentEditorStore.addListener(() => {
            const state = segmentEditorStore.getState();
            const isSegmentEditing = props.segment.id === state.segment?.id;
            setIsEditing(isSegmentEditing);
            if (isSegmentEditing) {
                setCurrentSegment(state.segment);
                ReactEditor.focus(targetEditor);
            }
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setFilter(state.filter);
        })

        return () => {
            segmentEditorListener.remove();
            segmentListListener.remove();
        }
    });

    if (currentSegment.equals(Segment.Empty)) {
        return <LoadingSegmentView segment={currentSegment} style={props.style} position={props.position}
                                   isEditing={isEditing} isLocked={currentSegment.translation.isLocked}/>
    }

    return (
        <SegmentsTableRow
            onKeyDown={e =>
                handleKeyDown(e, sourceEditor, targetEditor, props, currentSegment)}
            isEditing={isEditing}
            isLocked={currentSegment.translation.isLocked}
            style={{...props.style, ...{wordBreak: 'break-all'}}}
            direction={"row"}>
            <SegmentSelector position={props.position} isSelected={props.isSelected}/>
            <SegmentCell style={{minWidth: '50px', width: '50px', whiteSpace: 'nowrap'}}>
                <Stack>
                    <Typography textAlign={"left"}>{currentSegment.order + 1}</Typography>
                    {drawComments(currentSegment)}
                    {drawLock(currentSegment.translation)}
                </Stack>
            </SegmentCell>
            <SegmentSourceCell style={{flexGrow: 1}}>
                <Slate editor={sourceEditor}
                       initialValue={currentSegment.sourceEditor.children}>
                    <CustomEditable onDOMBeforeInput={(e) => e.preventDefault()}
                                    renderElement={(renderProps) =>
                                        <RenderedSourceElement children={renderProps.children}
                                                               element={renderProps.element}
                                                               attributes={renderProps.attributes}
                                                               onPlaceholder={placeholder =>
                                                                   handlePlaceholderClicked(targetEditor, placeholder)}
                                                               id={currentSegment.id}/>}
                                    renderLeaf={(props) =>
                                        <RenderedText children={props.children} attributes={props.attributes}
                                                      leaf={props.leaf} text={props.text}/>}
                                    style={{outline: 'none', caretColor: 'transparent'}}
                                    onKeyDown={e =>
                                        handleEditorKeyDown(e, sourceEditor, ConcordanceSearchType.Source, currentSegment)}
                                    decorate={entry => filter.source ? decorate(entry, filter.source) : []}/>
                </Slate>
                <Typography style={{marginTop: '0px', paddingTop: '0px', lineHeight: 1.5}} fontSize={12}
                            color={"#97A0AF"}>{currentSegment.sourceId}</Typography>
            </SegmentSourceCell>
            <SegmentCell style={{flexGrow: 1}}>
                <Slate editor={targetEditor}
                       initialValue={targetEditor.children}
                       onChange={() => handleTargetChanged(
                           currentSegment,
                           currentSegment.translation.nextWorkflowStep.isEmpty(),
                           targetEditor)}>
                    <CustomEditableTarget
                        onClick={e =>
                            handleSegmentClicked(e, props.onClick, targetEditor)}
                        isEditing={isEditing}
                        style={props.style}
                        renderElement={(props) =>
                            <RenderedTargetElement children={props.children} element={props.element}
                                                   attributes={props.attributes}/>}
                        renderLeaf={(props) =>
                            <RenderedText children={props.children} attributes={props.attributes}
                                          leaf={props.leaf} text={props.text}/>}
                        onDoubleClick={() => repairDoubleClickSelect(targetEditor)}
                        readOnly={currentSegment.translation.isLocked || !isEditing}
                        onKeyDown={e =>
                            handleEditorKeyDown(e, targetEditor, ConcordanceSearchType.Target, currentSegment)}
                        decorate={entry => filter.target ? decorate(entry, filter.target) : []}
                    />
                </Slate>
            </SegmentCell>
            <ButtonSegmentCell>
                <ButtonWrapper>
                    {drawApproveTranslation(
                        currentSegment,
                        currentSegment.translation.workflowStep ? currentSegment.translation.workflowStep.name : '',
                        currentSegment.translation.nextWorkflowStep.isEmpty(),
                        targetEditor)}
                    {drawWarnings(currentSegment.translation, firstStep)}
                </ButtonWrapper>
            </ButtonSegmentCell>
        </SegmentsTableRow>
    );
}

function completeEditing(editor: ReactEditor, segment: Segment, isApprove: boolean) {
    const text = editorText(editor);
    if (text === "")
        return;

    if (isApprove)
        approveTranslationAction(segment, text);
    else
        saveTranslationAction(segment, text);
}

function handleSegmentClicked(event: React.MouseEvent,
                              onClick: (event: React.MouseEvent, editor: Editor) => void,
                              targetEditor: Editor) {
    onClick(event, targetEditor);
}

function handlePlaceholderClicked(targetEditor: Editor, placeholder: SlatePlaceholder) {
    const withUniqueId = {...placeholder, id: IdSequence.Instance.next()}
    Transforms.insertNodes(targetEditor, withUniqueId);
}

async function handleEditorKeyDown(e: React.KeyboardEvent<HTMLElement>, editor: Editor, editorType: ConcordanceSearchType, currentSegment: Segment) {
    const {selection} = editor;

    if (editorType === ConcordanceSearchType.Target)
        handleTargetEditorKeyDown(e, editor);

    if (selection && !Range.isCollapsed(selection)) {
        if (e.ctrlKey && (/^[kK]$/.test(e.key))) {
            e.preventDefault();
            await callConcordanceSearch(editorType, editor, currentSegment);
            return;
        }
    }
}

function handleTargetEditorKeyDown(e: React.KeyboardEvent<HTMLElement>, editor: Editor) {
    const {selection} = editor;
    const {nativeEvent} = e;

    if (isKeyHotkey('enter', nativeEvent)) {
        e.preventDefault();
        return;
    }

    if (selection && Range.isCollapsed(selection)) {
        if (isKeyHotkey('left', nativeEvent)) {
            e.preventDefault();
            Transforms.move(editor, {
                unit: 'offset',
                reverse: true
            });
            return;
        }
        if (isKeyHotkey('right', nativeEvent)) {
            e.preventDefault();
            Transforms.move(editor, {
                unit: 'offset'
            });
            return;
        }
        if (isKeyHotkey('backspace', nativeEvent)) {
            e.preventDefault();
            Transforms.delete(editor, {
                at: selection.anchor,
                distance: 1,
                unit: 'character',
                reverse: true
            });
            return;
        }
    }
}

function handleKeyDown(e: React.KeyboardEvent,
                       sourceEditor: Editor,
                       targetEditor: Editor,
                       props: SegmentViewProps,
                       segment: Segment) {
    const available = hotKeys.filter(value => value.isHotkey(e));
    if (available.isEmpty())
        return;

    const sourceText = editorText(sourceEditor);
    const targetText = editorText(targetEditor);
    e.preventDefault();

    available.first()!.action({
        keyEvent: e,
        segmentViewProps: props,
        segment: segment,
        sourceText: sourceText,
        targetText: targetText,
        editor: targetEditor
    });
}

function handleTargetChanged(segment: Segment, isLastStep: boolean, editor: Editor) {
    if (!isLastStep)
        return;
    const editingOperations = editor.operations.filter(op => op.type !== "set_selection");

    if (editingOperations.length <= 0)
        return;

    saveTranslationAction(segment, editorText(editor));
    setSegmentAction(segment.withPreviousWorkflowStep());
}

/**
 *  Workaround for error: 'Failed to execute 'setEnd' on 'Range': There is no child at offset {}'
 *  Open issue: https://github.com/ianstormtaylor/slate/issues/5435
 *              https://github.com/udecode/plate/issues/2883
 *  Error description:  Slate crashes and displays the error message when you select text followed by a readOnly element (placeholder) and then press the delete key.
 *                      This occurs on word double-click selection, which partially selects the placeholder node
 *  Workaround description: To address the fix, the selection range is adjusted by setting the endOffset to the end of the selected text node.
 */
function repairDoubleClickSelect(targetEditor: Editor) {
    const selection = targetEditor.selection;
    if (!selection || selection.anchor === selection.focus)
        return;
    const segmentSourceParagraph: any = targetEditor.children[0];
    const childNodes = segmentSourceParagraph.children;
    const selectedChild = childNodes[selection.anchor.path[1]];
    let lastChildPosition = {
        offset: 0,
        path: [...selection.anchor.path]
    };
    const endSelectionPosition = countLastChildPosition(lastChildPosition, selectedChild);
    if (endSelectionPosition.path[0] === selection.focus.path[0] && endSelectionPosition.path[1] === selection.focus.path[1])
        // If no extra elements like placeholders were selected, then leave original selection position
        endSelectionPosition.offset = selection.focus.offset;
    Transforms.setSelection(targetEditor, {
        anchor: selection.anchor,
        focus: endSelectionPosition
    });
}

function countLastChildPosition(pos: BasePoint, currentChild: any) {
    const childText = !!currentChild.text ? currentChild.text : (!!currentChild.rowText ? currentChild.rowText : '');
    pos.offset = childText.length;
    const lastChildIndex = currentChild?.children?.length - 1;
    if (!currentChild?.children || lastChildIndex === -1)
        return pos;
    pos.path.push(lastChildIndex);
    const lastChild = currentChild.children[lastChildIndex];
    return countLastChildPosition(pos, lastChild);
}

function drawLock(translation: Translation) {
    const lock = translation.lock;

    if (lock == null)
        return null;

    const date = dateTimeFormat(lock.created);
    return (
        <Tooltip title={`Locked by ${lock.username} at ${date}`}>
            <LockIcon color={"primary"}/>
        </Tooltip>)
}

function drawComments(segment: Segment) {
    if (segment.commentsCount <= 0)
        return null;

    return (
        <Stack direction={"row"} alignItems={"center"}>
            <Icon>
                <img src={"/003-24x24.svg"} alt={"comments"} width={21} height={20}/>
            </Icon>
            <Typography fontSize={14} fontWeight={"bold"} color={"#158aff"}>
                {segment.commentsCount}
            </Typography>
        </Stack>
    )
}

function drawWarnings(translation: Translation, firstStep: WorkflowStepModel) {
    const hasErrors = translation.markupErrorModels.size > 0;
    const tryToApprove = translation.tryToApprove;
    const notFirstStep = firstStep && translation.workflowStep.id !== firstStep.id;

    if (hasErrors && (tryToApprove || notFirstStep)) {
        return <Tooltip title={translation.markupErrorModels.map(errorModel => errorModel.error).join('\n')}>
            <ErrorOutline color={'error'}/>
        </Tooltip>;
    }
}

function drawApproveTranslation(segment: Segment,
                                nextWorkflowStepName: string,
                                isLastStep: boolean,
                                targetEditor: ReactEditor) {
    const translation = segment.translation;
    return (
        <LoadingButton
            variant="contained"
            color="primary"
            disabled={translation.isLocked || isLastStep}
            onClick={() => completeEditing(targetEditor, segment, true)}
            id={`approve-segment-${segment.id}`}
            sx={{
                height: '30px',
                padding: '4px 10px',
                margin: 0,
                display: 'inline-flex',
                justifyContent: 'center',
            }}
        >
            {returnButtonsWorkflowStep(nextWorkflowStepName)}
        </LoadingButton>
    )
}

function returnButtonsWorkflowStep(nextWorkflowStepName: string) {
    return nextWorkflowStepName.toUpperCase();
}

function decorate(entry: NodeEntry, filter: string) {
    const node = entry[0];
    const path = entry[1];

    if (!("text" in node))
        return [];

    const nodeText = node.text;
    if (!nodeText)
        return [];

    const filtered = findByFilter(nodeText, filter);

    return filtered.map(index => {
        return {
            anchor: {
                path,
                offset: index,
            },
            focus: {
                path,
                offset: index + (filter ? filter.length : 0),
            },
            decoration: "mark",
        };
    });
}

function findByFilter(text: string, filter: string) {
    if (!filter)
        return [];
    const result = [];
    let index = text.indexOf(filter, 0);
    while (index !== -1) {
        result.push(index);
        index = text.indexOf(filter, index + 1);
    }
    return result;
}

async function callConcordanceSearch(searchType: ConcordanceSearchType, editor: Editor, currentSegment: Segment) {
    const initialProjectState = projectPageStore.getState();
    const project = initialProjectState.project;
    const selection = editor.selection;
    if (!selection)
        return;

    const sortedPoints = sortRangePoints(selection.anchor, selection.focus);
    const text = getTextBetweenPoints(sortedPoints, editor.children[0] as SlateParagraph);

    dispatcher.dispatch(setConcordanceSearchText(text));
    dispatcher.dispatch(setConcordanceSearchType(searchType));
    await executeConcordanceSearchAction(text, searchType, project.source.id, currentSegment.translation.languageCode, project.translationMemories);
}

interface IHotkeyContext {
    keyEvent: React.KeyboardEvent
    segmentViewProps: SegmentViewProps,
    segment: Segment,
    sourceText: string,
    targetText: string,
    editor: Editor
}

interface ISegmentHotkeys {
    isHotkey: (e: React.KeyboardEvent) => boolean
    action: (context: IHotkeyContext) => Promise<void>
}

const hotKeys = List<ISegmentHotkeys>(
    [
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && e.key === 'Enter',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const text = context.targetText;
                if (text === "")
                    return;

                const result = await approveTranslationAction(context.segment, text);
                if (result)
                    props.onShiftSegment(1);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.altKey && e.key === 'Enter',
            action: async (context: IHotkeyContext) => {
                Transforms.insertText(context.editor, '\n');
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && (e.key === 'Insert' || e.code === "KeyI"),
            action: async (context: IHotkeyContext) => {
                await insertTranslationAction(context.segment.sourceEditor);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && /^[1-9]$/.test(e.key),
            action: async (context: IHotkeyContext) => {
                const id = parseInt(context.keyEvent.key) - 1;
                const tmTranslation = segmentStore.getState().searchResults.list.get(id);
                if (!tmTranslation)
                    return;

                await updateTranslationFromResourceAction(tmTranslation)
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.altKey && /^[1-9]$/.test(e.key),
            action: async (context: IHotkeyContext) => {
                const id = parseInt(context.keyEvent.key);
                const props = context.segmentViewProps;
                const segmentSourceParagraph = props.segment.sourceEditor.children[0] as SlateParagraph;
                const placeholder = segmentSourceParagraph.children
                    .map(value => value as SlatePlaceholder)
                    .filter(child => !!child)
                    .find(child => child.id === id);
                if (!!placeholder)
                    handlePlaceholderClicked(context.editor, placeholder);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'Enter',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const text = context.targetText;
                if (text !== "")
                    await saveTranslationAction(context.segment, text);

                props.onShiftSegment(1);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'ArrowUp',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;

                props.onShiftSegment(-1);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'ArrowDown',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const segmentSourceParagraph: any = context.editor.children[0];
                const childNodes = segmentSourceParagraph.children;
                const selection = context.editor.selection;

                if (checkIfCursorIsOnLastSymbol(selection, childNodes)) {
                    props.onShiftSegment(1);
                    return;
                }

                const lastChild = childNodes[childNodes.length - 1];
                const childText = !!lastChild.text ? lastChild.text : (!!lastChild.rowText ? lastChild.rowText : '');

                const lastSymbolPoint = {
                    offset: childText.length,
                    path: [0, childNodes.length - 1]
                };
                context.editor.setSelection({
                    anchor: lastSymbolPoint,
                    focus: lastSymbolPoint
                });
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'F8',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const segmentSourceParagraph: any = props.segment.sourceEditor.children[0] as SlatePlaceholder;
                const placeholders = segmentSourceParagraph.children;
                placeholders.forEach((placeholder: any) => handlePlaceholderClicked(context.editor, placeholder));
            }
        }
    ]);
