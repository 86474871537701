import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError} from "../globals/ApiGlobals";
import {List} from "immutable";
import {EmbeddedResponse} from "../globals/ApiModels";
import {INotificationResponse, toModel} from "./model/NotificationApiModel";
import NotificationModel from "../model/NotificationModel";
import {EventSource} from "eventsource";

export function alertNotification() {
    const url = buildUrl(endpoint + "/alert");
    return axios
        .post(url)
        .then((response: AxiosResponse<EmbeddedResponse<INotificationResponse[]>>) => {
            const array = response
                .data
                ._embedded
                .map(value => toModel(value));

            return List(array);
        })
        .catch(reason => {
            dispatchError(reason);
            return List<NotificationModel>();
        });
}

export function alertSubscription(callback: (r: List<NotificationModel>) => void) {
    const es = new EventSource(`${endpoint}/alert-subscription`, {
        fetch: (input, init) =>
            fetch(input, {
                ...init,
                headers: {
                    ...init!.headers,
                    Authorization: axios.defaults.headers.common['Authorization'] as string,
                },
            }),
    })

    es.addEventListener('message', (event) => {
        const result = JSON.parse(event.data) as INotificationResponse[];
        const models = List(result.map(value => toModel(value)));
        callback(models)
    })

    window.addEventListener('beforeunload', function () {
        es.close();
    });
}

export function fetchNotifications() {
    const url = buildUrl(endpoint, {}, {orderBy: 'createdAt', orderDirection: 'desc'})
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponse<INotificationResponse[]>>) => {
            const notifications = response.data._embedded.map(value => toModel(value));
            return List(notifications);
        })
        .catch(reason => {
            dispatchError(reason);
            return List<NotificationModel>();
        });
}

export function deleteNotification(notification: NotificationModel) {
    const url = buildUrl(endpoint, {}, {id: notification.id})
    return axios
        .delete(url)
        .catch(dispatchError)
}

export function deleteAllNotifications() {
    const url = buildUrl(endpoint, {}, {state: "Alerted"})
    return axios
        .delete(url)
        .catch(dispatchError)
}


const endpoint = "/api/notifications/notifications";