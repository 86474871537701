import LazyLoadedList from "../common/LazyLoadedList";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";
import {Size} from "react-virtualized-auto-sizer";
import {List} from "immutable";
import {TranslationMemorySegmentEntity} from "../../model/common/ListEntity";
import {TranslationMemorySegment} from "../../model/TranslationMemorySegment";
import {getItemSize} from "../../utils/EditorUtils";
import {editorText} from "../../utils/slate/SlateUtils";
import React, {CSSProperties} from "react";
import {Grid2, LinearProgress, ListItemText, Stack} from "@mui/material";
import {ListContainer, ListHeaderItem} from "../common/FlatGroupedListView";
import FoundTranslationMemorySegmentView from "./FoundTranslationMemorySegmentView";
import Box from "@mui/material/Box";

export default function TranslationMemoriesSearchView() {
    return <Stack height={"100%"}>
        <ListContainer>
            <ListHeaderItem>
                <Grid2 container width={"100%"}>
                    <Grid2 size={4}>
                        <ListItemText primary={"Source"}/>
                    </Grid2>
                    <Grid2 size={4}>
                        <ListItemText primary={"Target"}/>
                    </Grid2>
                    <Grid2 size={4}>
                        <ListItemText primary={"Translation memory"}/>
                    </Grid2>
                </Grid2>
            </ListHeaderItem>
        </ListContainer>
        <Stack style={{height: "100%", overflow: "hidden"}}>
            <Stack style={{
                flex: 'auto',
                paddingTop: '15px',
                paddingLeft: '10px',
                paddingRight: '10px',
                overflow: 'hidden'
            }}>
                <Box sx={{flex: 1}}>
                    <LazyLoadedList actions={translationMemorySegmentListActions} itemSize={itemSize}
                                    itemsConverter={itemsConverter}
                                    drawItems={(index, _size, style, items) =>
                                        drawSegment(index, style, items)}/>
                </Box>
            </Stack>
        </Stack>
    </Stack>
}

function itemsConverter(items: List<TranslationMemorySegment>): List<TranslationMemorySegmentEntity> {
    let result = List<TranslationMemorySegmentEntity>();
    items.forEach(item => result = result.push({segment: item}));
    return result;
}

function drawSegment(index: number, style: CSSProperties, items: List<TranslationMemorySegmentEntity>) {
    const entity = items.get(index)!;
    if (!entity) {
        return <Box style={style}>
            <LinearProgress/>
        </Box>;
    }

    return <FoundTranslationMemorySegmentView segment={entity.segment} style={style}/>;
}

function itemSize(index: number, size: Size, items: List<TranslationMemorySegmentEntity>) {
    let item = items.get(index)?.segment;
    if (!item)
        item = new TranslationMemorySegment();

    return getItemSize(editorText(item.sourceEditor), item.sourceId, size.width / 3 - 10, 100);
}