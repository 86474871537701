import Box from "@mui/material/Box";
import {
    Autocomplete,
    Button,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid2,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import {Language} from "../../../model/Language";
import {WorkflowStepModel, WorkflowStepNames, WorkflowStepType} from "../../../model/WorkflowStepModel";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import Project from "../../../model/Project";
import {getProjectPath} from "../../../routes/project/ProjectsRoute";
import {List} from "immutable";
import {NavigateFunction, useNavigate} from "react-router-dom";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import {createProjectAction} from "../../../flux/project/list/ProjectListActions";
import {
    setProjectNameAction,
    setSourceLanguageAction,
    setStepAction,
    setTargetLanguagesAction,
    setWorkflowEditorOpenAction,
    setWorkflowStepsAction,
    setWorkflowTypeAction
} from "../../../flux/project/editor/ProjectEditorActions";
import languageListStore from "../../../flux/language/list/LanguageListStore";
import {handleLanguageOptionRendered, handleTargetRendered} from "../../../utils/LanguageUtils";

const CustomDivider = styled(Box)({
    height: '2rem',
    width: '50%',
    borderRight: '1px dashed #c8c6ce'
});

const StageButton = styled(Button)({
    borderRadius: '2rem',
    textTransform: 'none'
});

export default function ProjectSettingsEditor() {
    const supportedLanguages = languageListStore.getState().languages;
    const initialEditorState = projectEditorStore.getState();

    const [files, setFiles] = useState(initialEditorState.files);
    const [multiLanguage, setMultiLanguage] = useState(initialEditorState.multiLanguage);
    const [projectName, setProjectName] = useState(initialEditorState.projectName);
    const [sourceLanguage, setSourceLanguage] = useState<Language | null>(initialEditorState.sourceLanguage);
    const [targetLanguages, setTargetLanguages] = useState(initialEditorState.targetLanguages);
    const [workflowType, setWorkflowType] = useState<string>(initialEditorState.workflowType);
    const [workflowSteps, setWorkflowSteps] = useState<WorkflowStepModel | null>(initialEditorState.workflowSteps);
    const [workflowEditorOpen, setWorkflowEditorOpen] = useState(initialEditorState.workflowEditorOpen);
    const [stageButtonAnchor, setStageButtonAnchor] = useState<HTMLElement | null>(null);
    const [addStageButtonAnchor, setAddStageButtonAnchor] = useState<HTMLElement | null>(null);
    const [stageMenuOpen, setStageMenuOpen] = useState(false);
    const [addStageMenuOpen, setAddStageMenuOpen] = useState(false);
    const [workflowStageIndex, setWorkflowStageIndex] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setFiles(state.files);
            setMultiLanguage(state.multiLanguage);
            setProjectName(state.projectName);
            setSourceLanguage(state.sourceLanguage);
            setTargetLanguages(state.targetLanguages);
            setWorkflowType(state.workflowType);
            setWorkflowSteps(state.workflowSteps);
            setWorkflowEditorOpen(state.workflowEditorOpen);
        });

        return () => projectEditorListener.remove();
    });

    return (
        <Box mt={2}>
            <Grid2 container spacing={2}>
                <Grid2 size={12} key={"project-name-field"}>
                    <TextField variant={"standard"} label={"Project name"} fullWidth={true}
                               onChange={(e) => setProjectNameAction(e.target.value)}
                               value={projectName}/>
                </Grid2>
                <Grid2 size={4} key={"source-language-field"}>
                    <Autocomplete renderInput={(params) =>
                        <TextField {...params} label={"Source"} variant={"standard"}/>}
                                  renderOption={handleLanguageOptionRendered}
                                  options={supportedLanguages.getLanguages().filter(language =>
                                      !targetLanguages.find(targetLanguage => targetLanguage === language)).toArray()}
                                  getOptionLabel={(option: Language) => option.name}
                                  value={sourceLanguage}
                                  onChange={(_event, value) => setSourceLanguageAction(value)}/>
                </Grid2>
                <Grid2 size={8} key={"target-languages-field"}>
                    <Autocomplete multiple
                                  options={supportedLanguages.getLanguages().filter(language =>
                                      language !== sourceLanguage).toArray()}
                                  renderInput={(params) =>
                                      <TextField {...params} label={"Target"} variant={"standard"}
                                                 value={targetLanguages}/>}
                                  renderOption={handleLanguageOptionRendered}
                                  renderTags={handleTargetRendered}
                                  getOptionLabel={(option: Language) => option.name}
                                  value={targetLanguages.toArray()}
                                  onChange={(_e, value) => setTargetLanguagesAction(List(value))}/>
                </Grid2>
                <Grid2 key={"workflow-field"}>
                    <FormControl>
                        <FormLabel>Choose a workflow:</FormLabel>
                        <RadioGroup row value={workflowType}
                                    onChange={(_e, value) =>
                                        handleWorkflowTypeChanged(value)}>
                            <FormControlLabel control={<Radio/>} label={"Default"} value={"default"}/>
                            <FormControlLabel control={<Radio/>} label={"Custom"} value={"custom"}/>
                        </RadioGroup>
                    </FormControl>
                    <Collapse in={workflowEditorOpen}>
                        <Stack divider={<CustomDivider/>}>
                            {drawWorkflowSteps(
                                workflowSteps,
                                workflowStageIndex,
                                stageMenuOpen,
                                setStageButtonAnchor,
                                setStageMenuOpen,
                                setWorkflowStageIndex,
                                setAddStageButtonAnchor,
                                setAddStageMenuOpen)}
                        </Stack>
                        <Menu open={addStageMenuOpen} anchorEl={addStageButtonAnchor}
                              onClose={() => handleAddStageMenuClosed(setAddStageButtonAnchor, setAddStageMenuOpen)}>
                            {WorkflowStepNames.map(option =>
                                <MenuItem onClick={() =>
                                    handleAddStageMenuItemSelected(
                                        option,
                                        workflowSteps,
                                        setWorkflowSteps,
                                        setAddStageButtonAnchor,
                                        setAddStageMenuOpen)}>
                                    {option}
                                </MenuItem>)}
                        </Menu>
                        <Menu open={stageMenuOpen} anchorEl={stageButtonAnchor}
                              onClose={() => handleStageMenuClosed(setStageButtonAnchor, setStageMenuOpen)}>
                            {WorkflowStepNames.map(option =>
                                <MenuItem onClick={() =>
                                    handleStageMenuItemSelected(
                                        option,
                                        workflowSteps,
                                        workflowStageIndex,
                                        setStageButtonAnchor,
                                        setStageMenuOpen)}>
                                    {option}
                                </MenuItem>
                            )}
                            <Divider/>
                            <MenuItem onClick={() =>
                                handleStageMenuItemRemoved(
                                    workflowSteps,
                                    workflowStageIndex,
                                    setWorkflowSteps,
                                    setStageButtonAnchor,
                                    setStageMenuOpen)}>
                                <Typography color={"#ff0000"}>Remove</Typography>
                            </MenuItem>
                        </Menu>
                    </Collapse>
                </Grid2>
            </Grid2>
            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} mt={2}>
                <Button onClick={() => setStepAction(0)}>Back</Button>
                <Button variant={"contained"}
                        onClick={async () =>
                            await handleCreateProject(
                                sourceLanguage,
                                projectName,
                                targetLanguages,
                                workflowSteps,
                                files,
                                multiLanguage,
                                navigate)}
                        disabled={!allFieldsFilled(projectName, sourceLanguage, targetLanguages)}>
                    Create &gt;
                </Button>
            </Stack>
        </Box>
    );
}

function handleWorkflowTypeChanged(value: string) {
    setWorkflowTypeAction(value);
    switch (value) {
        case "default":
            setWorkflowStepsAction(null);
            setWorkflowEditorOpenAction(false);
            break;
        case "custom":
            setWorkflowEditorOpenAction(true);
            break;
    }
}

function handleWorkflowStageClicked(event: React.MouseEvent<HTMLButtonElement>,
                                    index: number,
                                    setStageButtonAnchor: (value: HTMLElement | null) => void,
                                    setStageMenuOpen: (value: boolean) => void,
                                    setWorkflowStageIndex: (value: number) => void) {
    setStageButtonAnchor(event.currentTarget);
    setStageMenuOpen(true);
    setWorkflowStageIndex(index);
}

function handleAddStageClicked(event: React.MouseEvent<HTMLButtonElement>,
                               setAddStageButtonAnchor: (value: HTMLElement | null) => void,
                               setAddStageMenuOpen: (value: boolean) => void) {
    setAddStageButtonAnchor(event.currentTarget);
    setAddStageMenuOpen(true);
}

function handleAddStageMenuClosed(setAddStageButtonAnchor: (value: HTMLElement | null) => void,
                                  setAddStageMenuOpen: (value: boolean) => void) {
    setAddStageButtonAnchor(null);
    setAddStageMenuOpen(false);
}

function handleAddStageMenuItemSelected(option: string,
                                        workflowSteps: WorkflowStepModel | null,
                                        setWorkflowSteps: (value: WorkflowStepModel | null) => void,
                                        setAddStageButtonAnchor: (value: HTMLElement | null) => void,
                                        setAddStageMenuOpen: (value: boolean) => void) {
    let currentWorkflowSteps = workflowSteps;
    if (!currentWorkflowSteps)
        setWorkflowStepsAction(new WorkflowStepModel(undefined, option, WorkflowStepType.Manual, []));
    else
        currentWorkflowSteps.addStep(option, WorkflowStepType.Manual);
    handleAddStageMenuClosed(setAddStageButtonAnchor, setAddStageMenuOpen);
}

function handleStageMenuClosed(setStageButtonAnchor: (value: HTMLElement | null) => void,
                               setStageMenuOpen: (value: boolean) => void) {
    setStageButtonAnchor(null);
    setStageMenuOpen(false);
}

function handleStageMenuItemRemoved(workflowSteps: WorkflowStepModel | null,
                                    workflowStageIndex: number,
                                    setWorkflowSteps: (value: WorkflowStepModel | null) => void,
                                    setStageButtonAnchor: (value: HTMLElement | null) => void,
                                    setStageMenuOpen: (value: boolean) => void) {
    if (!workflowSteps)
        return;
    setWorkflowStepsAction(workflowSteps.removeStep(workflowStageIndex));
    handleStageMenuClosed(setStageButtonAnchor, setStageMenuOpen);
}

function handleStageMenuItemSelected(option: string,
                                     workflowSteps: WorkflowStepModel | null,
                                     workflowStageIndex: number,
                                     setStageButtonAnchor: (value: HTMLElement | null) => void,
                                     setStageMenuOpen: (value: boolean) => void) {
    if (!workflowSteps)
        return;
    workflowSteps.editStep(workflowStageIndex, option);
    handleStageMenuClosed(setStageButtonAnchor, setStageMenuOpen);
}

async function handleCreateProject(sourceLanguage: Language | null,
                                   projectName: string,
                                   targetLanguages: List<Language>,
                                   workflowSteps: WorkflowStepModel | null,
                                   files: List<File>,
                                   multiLanguage: boolean,
                                   navigate: NavigateFunction) {
    if (!sourceLanguage)
        return;
    const projectData = new Project({
        name: projectName,
        source: sourceLanguage,
        targets: targetLanguages
    });
    const createdProject = await createProjectAction(projectData, workflowSteps, files, multiLanguage);
    navigate(getProjectPath(createdProject.id));
}

function allFieldsFilled(projectName: string,
                         sourceLanguage: Language | null,
                         targetLanguages: List<Language>) {
    return projectName !== '' && sourceLanguage !== null && targetLanguages.size > 0;
}

function drawWorkflowSteps(workflowSteps: WorkflowStepModel | null,
                           workflowStageIndex: number,
                           stageMenuOpen: boolean,
                           setStageButtonAnchor: (value: HTMLElement | null) => void,
                           setStageMenuOpen: (value: boolean) => void,
                           setWorkflowStageIndex: (value: number) => void,
                           setAddStageButtonAnchor: (value: HTMLElement | null) => void,
                           setAddStageMenuOpen: (value: boolean) => void) {
    const result: React.JSX.Element[] = [];
    if (workflowSteps) {
        let currentStage = workflowSteps;
        for (let currentIndex = 0; ; ++currentIndex) {
            result.push(<StageButton variant={"outlined"}
                                     onClick={(e) =>
                                         handleWorkflowStageClicked(
                                             e,
                                             currentIndex,
                                             setStageButtonAnchor,
                                             setStageMenuOpen,
                                             setWorkflowStageIndex)}
                                     endIcon={currentIndex === workflowStageIndex && stageMenuOpen
                                         ? <ExpandLess/>
                                         : <ExpandMore/>}>
                {currentStage.name}
            </StageButton>);
            if (currentStage.next.length === 0)
                break;
            currentStage = currentStage.next[0];
        }
    }

    result.push(<StageButton variant={"contained"}
                             onClick={e =>
                                 handleAddStageClicked(e, setAddStageButtonAnchor, setAddStageMenuOpen)}>
        Add stage
    </StageButton>);
    return result;
}