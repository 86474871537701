import {TranslationMemorySegment} from "../../model/TranslationMemorySegment";
import {createSlate} from "../../utils/slate/SlateUtils";
import {ITranslationMemoryResponse, toTranslationMemoryModel} from "./TranslationMemoryApiModel";

export function toTranslationMemorySegmentModel(response: ITranslationMemorySegmentResponse) {
    return new TranslationMemorySegment({
        id: response.id,
        source: response.source,
        sourceId: response.sourceId === null ? "" : response.sourceId,
        previousSource: response.previousSource === null ? "" : response.previousSource,
        nextSource: response.nextSource === null ? "" : response.nextSource,
        target: response.target,
        language: response.language,
        editorName: response.editorName === null ? "" : response.editorName,
        lastEditDateTime: response.lastEditDateTime,
        sourceEditor: createSlate(response.source),
        targetEditor: createSlate(response.target),
        currentTarget: response.target,
        translationMemory: toTranslationMemoryModel(response.translationMemory)
    });
}

export interface ITranslationMemorySegmentSearchResponse {
    results: ITranslationMemorySegmentResponse[]
}

export interface ITranslationMemorySegmentResponse {
    id: string,
    source: string,
    sourceId: string | null,
    previousSource: string,
    nextSource: string,
    target: string,
    language: string,
    editorName: string | null,
    lastEditDateTime: string | null,
    translationMemory: ITranslationMemoryResponse
}