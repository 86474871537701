import {Record} from "immutable";

interface UserRoleType {
    id: string,
    name: string
}

const UserRoleRecord = Record<UserRoleType>({
    id: '',
    name: ''
});

export class UserGroup extends UserRoleRecord {
}

export const VendorManagerRole = "Vendor mamagers";
export const LinguistRole = "Linguists";