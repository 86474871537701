import {TranslationMemorySegment} from "../../../model/TranslationMemorySegment";

export enum TranslationMemorySegmentPayloadAction {
    SetTranslationMemorySegment = "TranslationMemorySegmentPayloadAction.SetTranslationMemorySegment"
}

interface ISetSegmentPayload {
    action: TranslationMemorySegmentPayloadAction.SetTranslationMemorySegment
    segment: TranslationMemorySegment
}

export type TranslationMemorySegmentEditorPayload = ISetSegmentPayload;

export function setTranslationMemorySegment(segment: TranslationMemorySegment): TranslationMemorySegmentEditorPayload {
    return {
        action: TranslationMemorySegmentPayloadAction.SetTranslationMemorySegment,
        segment: segment
    }
}