import {Bold, InfoContainer} from "../../../../globals/CommonComponents";
import React, {useEffect, useState} from "react";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import Typography from "@mui/material/Typography";
import {Box, Container, Divider, Grid2, Paper, Stack} from "@mui/material";
import GlossaryPairSearchResult from "../../../../model/GlossaryPairSearchResult";
import TranslationMemorySearchResult from "../../../../model/TranslationMemorySearchResult";
import TargetDiffView from "../../../common/TargetDiffView";

export default function CatInfoView() {

    const segmentState = segmentEditorStore.getState();
    const [glossaryPair, setGlossaryPair] = useState(segmentState.selectedGlossaryPair);
    const [tmSuggest, setTMSuggest] = useState(segmentState.tmSuggest);

    useEffect(() => {
        const segmentEditorListener = segmentEditorStore.addListener(() => {
            const state = segmentEditorStore.getState();
            setGlossaryPair(state.selectedGlossaryPair);
            setTMSuggest(state.tmSuggest);
        });

        return () => segmentEditorListener.remove();
    });

    return (
        <InfoContainer disableGutters sx={{ px: 1 }}>
            {drawGlossaryInfo(glossaryPair)}
            {drawTMInfo(tmSuggest)}
        </InfoContainer>
    );
}

function drawGlossaryInfo(glossaryPair: GlossaryPairSearchResult | null) {
    if (!glossaryPair)
        return;

    return (
        <Container>
            <Box textAlign={"center"} m={1}>
                <Typography>{"Glossary " + glossaryPair.glossary}</Typography>
            </Box>
            <Divider/>
            <Grid2 container>
                <Grid2 size={3}>
                    <Bold>{"Source: "}</Bold>
                </Grid2>
                <Grid2 size={9}>
                    <Typography>{glossaryPair.source}</Typography>
                </Grid2>
                <Grid2 size={3}>
                    <Bold>{"Target: "}</Bold>
                </Grid2>
                <Grid2 size={9}>
                    <Typography>{glossaryPair.target}</Typography>
                </Grid2>
                <Grid2 size={3}>
                    <Bold>{"Comment: "}</Bold>
                </Grid2>
                <Grid2 size={9}>
                    <Typography>{glossaryPair.comment}</Typography>
                </Grid2>
            </Grid2>
        </Container>
    )
}

function drawTMInfo(tmSearchResult: TranslationMemorySearchResult | null) {
    if (!tmSearchResult)
        return;

    return (
        <Stack marginY={1} spacing={1}>
            <Paper>
                <Stack spacing={1} padding={1}>
                    <Typography fontWeight={"bold"} fontSize={12}>Match difference</Typography>
                    <TargetDiffView htmlDiff={tmSearchResult.diff}></TargetDiffView>
                </Stack>
            </Paper>
            <Paper>
                <Stack spacing={1} padding={1}>
                    <Typography fontWeight={"bold"} fontSize={12}>Translation memory</Typography>
                    {drawProperty("Name", tmSearchResult.translationMemory)}
                    {tmSearchResult.editorName !== "" && drawProperty("Editor", tmSearchResult.editorName)}
                    {tmSearchResult.lastEditDateTime
                        && drawProperty("Changed", tmSearchResult.lastEditDateTime.toLocaleString())}
                </Stack>
            </Paper>
        </Stack>
    );
}

function drawProperty(header: string, value: string) {
    return <Typography fontSize={12} sx={{wordWrap: 'break-word'}}>
        <strong>{header + ": "}</strong>
        {value}
    </Typography>
}
