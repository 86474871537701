import {Model} from "../../../model/common/IModel";
import {List} from "immutable";
import {IFilter} from "../../../model/common/Filter";
import {dispatcher} from "../../Dispatcher";
import {AvailableResourceType} from "../../../model/AvailableResources";
import availableResourcesStore from "../../available-resources/AvailableResourcesStore";
import {InfiniteListStore} from "./InfinateListStore";
import {setInfiniteListIsLoadingPayload, setItemsPayload} from "./InfinateListPayload";

export class InfiniteListActions<M extends Model, F extends IFilter<F>> {
    constructor(fetchModels: (filter: F, lastItem?: M) => Promise<List<M>>,
                resource: AvailableResourceType) {
        this.store = new InfiniteListStore();
        this._fetchModels = fetchModels;
        this.storeId = this.store.storeId;
        this._resource = resource;
    }

    public readonly store: InfiniteListStore<M, F>;
    public readonly storeId: string;
    private readonly _fetchModels: (filter: F, lastItem?: M) => Promise<List<M>>;
    private readonly _resource: AvailableResourceType;

    public async loadMoreItems(filter?: F) {

        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, true, false, false);

        if (!available)
            return;

        if (this.store.getState().isLoading)
            return;

        if (!filter)
            filter = this.store.getState().filter;

        if (!filter)
            return;

        dispatcher.dispatch(setInfiniteListIsLoadingPayload(this.storeId, true));
        if (!filter.equals(this.store.getState().filter))
            this.refresh(filter);

        const lastItem = this.store.getState().items.last();

        const items = await this._fetchModels(filter, lastItem);
        const updated = this.store.getState().items.concat(items);
        dispatcher.dispatch(setItemsPayload(this.storeId, updated, !items.isEmpty(), filter));

        dispatcher.dispatch(setInfiniteListIsLoadingPayload(this.storeId, false));
    }

    public refresh(filter?: F) {
        if (!filter)
            filter = this.store.getState().filter;

        dispatcher.dispatch(setItemsPayload(this.storeId, List<M>(), true, filter));
    }

    get state() {
        return this.store.getState();
    }

    public addListener(callback: () => void) {
        return this.store.addListener(callback);
    }
}