import {ReduceStore} from "flux/utils";
import {Record} from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {TranslationMemorySegment} from "../../../model/TranslationMemorySegment";
import {
    TranslationMemorySegmentEditorPayload,
    TranslationMemorySegmentPayloadAction
} from "./TranslationMemorySegmentEditorPayload";

interface ITranslationMemorySegmentState {
    segment: TranslationMemorySegment
}

const TranslationMemorySegmentStateFactory = Record<ITranslationMemorySegmentState>({
    segment: new TranslationMemorySegment()
});

export class TranslationMemorySegmentState extends TranslationMemorySegmentStateFactory {
}

export class TranslationMemorySegmentEditorStore extends ReduceStore<TranslationMemorySegmentState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): TranslationMemorySegmentState {
        return new TranslationMemorySegmentState();
    }

    reduce(state: TranslationMemorySegmentState, payload: TranslationMemorySegmentEditorPayload): TranslationMemorySegmentState {
        switch(payload.action) {
            case TranslationMemorySegmentPayloadAction.SetTranslationMemorySegment:
                return state.set("segment", payload.segment);
            default:
                return state;
        }
    }
}

const translationMemorySegmentEditorStore = new TranslationMemorySegmentEditorStore();
export default translationMemorySegmentEditorStore;