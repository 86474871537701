import {IconButton, Stack, Tab, Tabs, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {List} from "immutable";
import {ReactSetter} from "../../globals/Types";
import PageBreadcrumbs from "../PageBreadcrumbs";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {User} from "../../model/User";
import VendorUsersListFilter from "../../flux/vendor/VendorUsersListFilter";
import vendorEditorStore from "../../flux/vendor/editor/VendorEditorStore";
import {
    addVendorLinguistAction,
    addVendorManagersAction,
    deleteVendorLinguistAction,
    deleteVendorManagersAction,
    saveVendorAction,
    vendorLinguistsListActions,
    vendorManagersListActions
} from "../../flux/vendor/editor/VendorEditorActions";
import AddIcon from "@mui/icons-material/Add";
import SelectDialog from "../common/SelectDialog";
import {defaultColumns} from "../../utils/ListViewUtils";
import {userListActions} from "../../flux/users/select/UserListActions";
import DeleteIcon from "@mui/icons-material/Delete";
import UsersListFilter from "../../flux/users/UsersListFilter";
import userGroupsStore from "../../flux/user-roles/UserGroupsStore";
import {LinguistRole, VendorManagerRole} from "../../model/UserGroup";
import {NavigateFunction, Outlet, useNavigate} from "react-router-dom";
import {VendorLinguistsPath} from "../../routes/vendor/VendorLinguistsRoute";
import {VendorManagersPath} from "../../routes/vendor/VendorManagersRoute";

export function VendorEditor() {
    const initialRolesState = userGroupsStore.getState();
    const linguistsRole = initialRolesState.getRoleByName(LinguistRole);
    const vendorManagersRole = initialRolesState.getRoleByName(VendorManagerRole);

    const initialVendorState = vendorEditorStore.getState();
    const initialVendor = initialVendorState.vendor

    const [vendor, setVendor] = useState(initialVendor);
    const [linguistsFilter, setLinguistFilter] = useState(linguistsRole !== undefined
        ? new UsersListFilter({userGroup: List.of(linguistsRole.id)})
        : new UsersListFilter());
    const [managersFilter, setManagersFilter] = useState(vendorManagersRole !== undefined
        ? new UsersListFilter({userGroup: List.of(vendorManagersRole.id)})
        : new UsersListFilter());
    const [name, setName] = useState(initialVendor.name);
    const [emptyName, setEmptyName] = useState(false)
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [isSelectLinguists, setIsSelectLinguists] = useState(false);
    const [isSelectManagers, setIsSelectManagers] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const vendorListener = vendorEditorStore.addListener(() => {
            const state = vendorEditorStore.getState();
            setVendor(state.vendor);
            const filter = new VendorUsersListFilter({vendorId: state.vendor.id});
            vendorLinguistsListActions.fetch(filter);
            vendorManagersListActions.fetch(filter);
        });

        const userGroupsListener = userGroupsStore.addListener(() => {
            const state = userGroupsStore.getState();
            const linguistsRole = state.getRoleByName(LinguistRole);
            const vendorManagersRole = state.getRoleByName(VendorManagerRole);

            if (linguistsRole)
                setLinguistFilter(new UsersListFilter({userGroup: List.of(linguistsRole.id)}));

            if (vendorManagersRole)
                setManagersFilter(new UsersListFilter({userGroup: List.of(vendorManagersRole.id)}));
        });

        return () => {
            vendorListener.remove();
            userGroupsListener.remove();
        }
    }, []);

    return (
        <Stack direction={"column"} alignItems={"start"} spacing={2} padding={2}>
            <PageBreadcrumbs/>
            <SelectDialog
                open={isSelectLinguists}
                actions={userListActions}
                onClose={() => setIsSelectLinguists(false)}
                columns={defaultColumns(false)}
                navigateProps={null}
                initialFilter={linguistsFilter}
                onSelect={users => addVendorLinguistAction(vendor, users.toList())}>
            </SelectDialog>
            <SelectDialog
                open={isSelectManagers}
                actions={userListActions}
                onClose={() => setIsSelectManagers(false)}
                columns={defaultColumns(false)}
                navigateProps={null}
                initialFilter={managersFilter}
                onSelect={users => addVendorManagersAction(vendor, users.toList())}>
            </SelectDialog>
            <TextField fullWidth={true}
                       id="vendor-name"
                       label="Name"
                       variant="standard"
                       value={name}
                       error={emptyName}
                       helperText={emptyName ? "empty name" : ""}
                       onBlur={() => onSave(name, setEmptyName)}
                       onChange={(e) => {
                           setEmptyName(false);
                           setName(e.target.value);
                       }}/>
            <Tabs value={currentTab} onChange={(_e, value) =>
                handleTabChanged(setCurrentTab, value, navigate)}>
                <Tab label={"Linguists"}/>
                <Tab label={"Managers"}/>
            </Tabs>
            <Stack direction={"row"}>
                <IconButton onClick={() => {
                    if (currentTab === 0) {
                        setIsSelectLinguists(true);
                        userListActions.fetch(linguistsFilter);
                    } else {
                        setIsSelectManagers(true);
                        userListActions.fetch(managersFilter);
                    }
                }}>
                    <AddIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    if (currentTab === 0)
                        deleteVendorLinguistAction(vendor, vendorLinguistsListActions.selected.toList().map(v => v.id));
                    else
                        deleteVendorManagersAction(vendor, vendorManagersListActions.selected.toList().map(v => v.id));

                    userListActions.fetch(new UsersListFilter());
                }}>
                    <DeleteIcon/>
                </IconButton>
            </Stack>
            <Outlet/>
        </Stack>
    );
}

function handleTabChanged(setCurrentTab: ReactSetter<number>, value: number, navigate: NavigateFunction) {
    switch (value) {
        case 0:
            navigate(VendorLinguistsPath);
            break;
        case 1:
            navigate(VendorManagersPath);
            break;
    }
    setCurrentTab(value);
}

function onSave(name: string, setEmptyName: ReactSetter<boolean>) {
    if (name.trim() === '') {
        setEmptyName(true)
        return;
    }

    const vendor = vendorEditorStore.getState().vendor.set("name", name);
    saveVendorAction(vendor);
}

export function userColumns(onDelete: (id: string) => {}): GridColDef[] {
    return [
        {
            field: 'firstName',
            headerName: 'First name',
            width: 200,
            filterable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 200,
            filterable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            filterable: false,
            sortable: false,
            getActions: (params: GridRowParams<User>) => [
                <GridActionsCellItem label={'Delete'} icon={<DeleteIcon/>}
                                     onClick={() => {
                                         onDelete(params.row.id);
                                     }}/>
            ]
        }
    ];
}