import {ReduceStore} from "flux/utils";
import {dispatcher, Payload} from "../../Dispatcher";
import {Record} from "immutable";
import {TranslationMemorySearchAction, TranslationMemorySearchPayload} from "./TranslationMemorySearchPayload";

interface ITranslationMemorySearchState {
    isOpen: boolean
}

const TranslationMemorySearchStateFactory = Record<ITranslationMemorySearchState>({
    isOpen: false
});

class TranslationMemorySearchState extends TranslationMemorySearchStateFactory {
}

export class TranslationMemorySearchStore extends ReduceStore<TranslationMemorySearchState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): TranslationMemorySearchState {
        return new TranslationMemorySearchState();
    }

    reduce(state: TranslationMemorySearchState, payload: TranslationMemorySearchPayload): TranslationMemorySearchState {
        switch(payload.action) {
            case TranslationMemorySearchAction.SetSearchMenuOpen:
                const isOpen = payload.searchMenuOpen;
                return isOpen ? state.set("isOpen", isOpen) : this.getInitialState();
            default:
                return state;
        }
    }
}

const translationMemorySearchStore = new TranslationMemorySearchStore();
export default translationMemorySearchStore;