import Alert from "../../model/Alert";
import {List} from "immutable";

export enum AlertAction {
    SetAlert = "AlertAction.SetAlert",
    SetWaitingForAlert = "AlertAction.SetWaitingForAlert",
    IsInitialized = "AlertAction.IsInitialized"
}

interface ISetAlertPayload {
    action: AlertAction.SetAlert,
    alert: List<Alert>
}

interface ISetWaitingForAlert {
    action: AlertAction.SetWaitingForAlert,
    waitingForAlert: boolean
}

interface ISetAlertsIdInitialized {
    action: AlertAction.IsInitialized,
}

export type AlertPayload = ISetAlertPayload | ISetWaitingForAlert | ISetAlertsIdInitialized;

export function setAlertList(alert: List<Alert>): AlertPayload {
    return {
        action: AlertAction.SetAlert,
        alert: alert
    }
}

export function setWaitingForAlert(waitingForAlert: boolean): AlertPayload {
    return {
        action: AlertAction.SetWaitingForAlert,
        waitingForAlert: waitingForAlert
    }
}

export function setAlertsIdInitialized(): AlertPayload {
    return {
        action: AlertAction.IsInitialized,
    }
}

