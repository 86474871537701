import Immutable, {List} from "immutable";
import {dispatcher} from "../../Dispatcher";
import {
    setIsUpdateTranslationsOpen,
    setIsUploadFilesOpen,
    setMultiLanguageMode,
    setProjectFiles,
    setProjectName,
    setSourceLanguage,
    setStep,
    setTargetLanguages,
    setWorkflowEditorOpen,
    setWorkflowSteps,
    setWorkflowType
} from "./ProjectEditorPayload";
import {initProjectAction} from "../page/ProjectPageActions";
import {WorkflowStepModel} from "../../../model/WorkflowStepModel";
import {Language} from "../../../model/Language";

export function setProjectFilesAction(files: Immutable.List<File>) {
    dispatcher.dispatch(setProjectFiles(files));
}

export function setStepAction(step: number) {
    dispatcher.dispatch(setStep(step));
}

export function editNewProjectAction() {
    initProjectAction();
    dispatcher.dispatch(setIsUploadFilesOpen(true));
}

export function setIsOpenUploadFilesAction(open: boolean) {
    dispatcher.dispatch(setIsUploadFilesOpen(open));
}

export function setIsOpenUpdateTranslationsAction(open: boolean) {
    dispatcher.dispatch(setIsUpdateTranslationsOpen(open));
}

export function setMultiLanguageModeAction(multiLanguage: boolean) {
    dispatcher.dispatch(setMultiLanguageMode(multiLanguage));
}

export function setProjectNameAction(projectName: string) {
    dispatcher.dispatch(setProjectName(projectName));
}

export function setSourceLanguageAction(sourceLanguage: Language | null) {
    dispatcher.dispatch(setSourceLanguage(sourceLanguage));
}

export function setTargetLanguagesAction(targetLanguages: List<Language>) {
    dispatcher.dispatch(setTargetLanguages(targetLanguages));
}

export function setWorkflowTypeAction(workflowType: string) {
    dispatcher.dispatch(setWorkflowType(workflowType));
}

export function setWorkflowStepsAction(workflowSteps: WorkflowStepModel | null) {
    dispatcher.dispatch(setWorkflowSteps(workflowSteps));
}

export function setWorkflowEditorOpenAction(workflowEditorOpen: boolean) {
    dispatcher.dispatch(setWorkflowEditorOpen(workflowEditorOpen));
}
