import {
    deleteTranslations,
    fetchGroupedSegments,
    fetchSegments,
    getSegmentPositionByOrder,
    lockTranslations,
    replaceTranslations,
    setWorkflow,
    unlockTranslations
} from "../../../api/SegmentApi";
import {dispatcher} from "../../Dispatcher";
import segmentListStore from "./SegmentListStore";
import {fetchProjectAction} from "../../project/page/ProjectPageActions";
import {fetchFile} from "../../../api/FileApi";
import {setSegmentByPositionAction} from "../editor/SegmentEditorActions";
import {Pageable} from "../../../model/Pageable";
import SegmentListFilter from "./SegmentListFilter";
import {Segment} from "../../../model/Segment";
import Translation from "../../../model/Translation";
import {Language} from "../../../model/Language";
import {CommonListActions} from "../../common/list/CommonListActions";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import Immutable, {List, Map} from "immutable";
import GroupedListItem from "../../../model/GropedListItem";
import {fetchSupportedLanguages} from "../../../api/LocaleApi";
import {
    deselectSegment,
    selectSegment,
    setIsLoading,
    setIsUpdating,
    setLastClickedSegment,
    setPositionToResetAfterIndex,
    setSegmentListItems,
    setSegmentListTotalItems,
    toggleClearTargetAlert,
    toggleSelectAll,
    toggleWithControls
} from "./SegmentListPayload";
import {CatFile} from "../../../model/CatFile";
import {catFileListActions} from "../../cat-files/CatFileListActions";
import CatFilesFilter from "../../cat-files/CatFilesFilter";
import {fetchProjectUsersAction} from "../../users/list/UsersListActions";
import {SelectionModel} from "../../../model/SelectionModel";
import {MaxPreloadedItems, RowsPerPageDefault} from "../../../globals/Constants";
import segmentEditorStore from "../editor/SegmentEditorStore";
import {setSegment} from "../editor/SegmentEditorPayload";
import {getSegmentPath} from "../../../routes/editor/CatFileEditorRoute";
import {setAlertAction} from "../../alert/AlertActions";
import Alert from "../../../model/Alert";
import {NavigateFunction} from "react-router-dom";
import {segmentEventTypeActions} from "../../event/EventTypeActions";
import {CatEventTypeModel} from "../../../model/CatEventTypeModel";
import NullFilter from "../../common/NullFilter";

export async function refreshSegmentListPageAction() {
    const currentFilter = segmentListStore.getState().filter;
    dispatcher.dispatch(setSegmentListTotalItems(0, currentFilter))

    await fetchSegmentListTotalItemsAction(segmentListStore.getState().filter, true);

    dispatcher.dispatch(setSegmentListItems(Map()));

    // This preload is necessary for cases when segments amount is too small,
    // so InfiniteLoader doesn't want to call loadMoreItems function
    await loadMoreSegmentByPositionAction(0);
}

export async function fetchSegmentListTotalItemsAction(filter: SegmentListFilter, force = false) {
    const currentFilter = segmentListStore.getState().filter;
    if (filter.equals(currentFilter) && !force)
        return;

    const segments = await fetchSegments(filter);
    dispatcher.dispatch(setSegmentListTotalItems(segments.pageable.totalElements, filter))
}

export function isSegmentPositionLoadedAction(position: number) {
    return !!segmentListStore.getState().items.get(position);
}

export async function loadMoreSegmentByPositionAction(position: number) {
    if (segmentListStore.getState().isLoading)
        return;
    dispatcher.dispatch(setIsLoading(true));
    const size = RowsPerPageDefault;
    const pageNumber = ~~(position / size);
    const pageable = new Pageable({size: size}).setBackendPage(pageNumber);

    const page = await fetchSegments(segmentListStore.getState().filter, pageable);
    let items = segmentListStore.getState().items;

    if (items.size >= MaxPreloadedItems) // cleanup
        items = Map();

    const segments = page.list
    const offset = pageNumber * size;
    for (let i = 0; i < segments.size; i++)
        items = items.set(i + offset, segments.get(i)!);

    dispatcher.dispatch(setSegmentListItems(items));
    dispatcher.dispatch(setIsLoading(false));
}

export function updateTranslationAction(segmentId: number, translation: Translation) {
    if (translation.equals(Translation.UnavailableTranslation))
        return;

    updateCurrentSegmentInListAction(segmentId, (segment) =>
        segment.set("translation", segment.translation.updateData(translation))
    );
}

export function updateCurrentSegmentInListAction(segmentId: number, updater: (segment: Segment) => Segment) {
    const state = segmentListStore.getState();
    const updated = state
        .items
        .map(value => {
            if (value.id !== segmentId)
                return value;

            return updater(value);
        });

    dispatcher.dispatch(setSegmentListItems(updated));

    const current = segmentEditorStore.getState().segment;
    if (current?.id === segmentId)
        dispatcher.dispatch(setSegment(updater(current)));
}

export async function deleteSegmentTranslationsAction(selection: SelectionModel) {
    dispatcher.dispatch(setIsUpdating(true));
    const state = segmentListStore.getState();
    await deleteTranslations(selection, state.filter);
    await refreshSegmentListPageAction();
    dispatcher.dispatch(setIsUpdating(false));
}

export async function initSegmentListEditor(projectId: number,
                                            language: string,
                                            catFileId: number,
                                            currentPosition: number | null,
                                            searchParams: URLSearchParams) {
    const listState = segmentListStore.getState();
    const currentFilter = listState.filter;

    if (currentFilter.projectId !== projectId) {
        await fetchSupportedLanguages();
        await fetchProjectAction(projectId);
        await fetchProjectUsersAction(projectId);
        await catFileListActions.fetch(new CatFilesFilter({projectId: projectId}));
    }

    let catFile: CatFile | undefined = currentFilter.nonNullCatFile;
    if (catFile.id !== catFileId)
        catFile = await fetchFile(catFileId, Language.fromSupported(language));
    if (!catFile)
        return;

    let filter = SegmentListFilter.fromSearchParams(catFile, language, searchParams);
    await fetchSegmentListTotalItemsAction(filter);
    const needToSetDefault = !currentPosition || currentPosition >= segmentListStore.getState().totalsItems;
    await setSegmentByPositionAction(needToSetDefault ? 0 : currentPosition);
    await segmentEventTypeActions.fetch(new NullFilter());
    await updateSelectedEvents(filter, CatEventTypeModel.allSegmentEvents());
}

export function resetSegmentListEditor() {
    dispatcher.dispatch(setSegmentListTotalItems(0, new SegmentListFilter()));
}

export function toggleSelectAllAction() {
    dispatcher.dispatch(toggleSelectAll());
    rerenderList(0);
}

export function toggleSegmentSelectionAction(position: number) {
    if (!isSegmentPositionLoadedAction(position))
        return;

    const state = segmentListStore.getState();
    const segment = state.items.get(position)!;
    if (state.selection.isSelected(segment.id))
        dispatcher.dispatch(deselectSegment(segment.id));
    else
        dispatcher.dispatch(selectSegment(segment.id));
    rerenderList(position);
}

function rerenderList(position: number) {
    dispatcher.dispatch(setPositionToResetAfterIndex(position));
    dispatcher.dispatch(setPositionToResetAfterIndex(null));
}

export function toggleWithControlsAction() {
    dispatcher.dispatch(toggleWithControls());
}

export function toggleClearTargetAlertAction() {
    dispatcher.dispatch(toggleClearTargetAlert());
}

export function setLastClickedSegmentAction(position: number | null) {
    dispatcher.dispatch(setLastClickedSegment(position));
}

export async function lockTranslationsAction(isLocking: boolean, lockAllLanguages: boolean) {
    const state = segmentListStore.getState();
    let filter = state.filter;
    if (lockAllLanguages)
        filter = state.filter.set("language", null);

    dispatcher.dispatch(setIsUpdating(true));

    if (isLocking)
        await lockTranslations(state.selection, filter);
    else
        await unlockTranslations(state.selection, filter);

    await refreshSegmentListPageAction();

    dispatcher.dispatch(setIsUpdating(false));
}

export async function setWorkflowAction(workflowStepId: number) {
    const state = segmentListStore.getState();

    dispatcher.dispatch(setIsUpdating(true));

    await setWorkflow(state.selection, state.filter, workflowStepId);

    await refreshSegmentListPageAction();

    dispatcher.dispatch(setIsUpdating(false));
}

export async function pointToSegmentAction(position: number) {
    const segmentListState = segmentListStore.getState();

    const filter = segmentListState.filter;
    const totalItems = segmentListState.totalsItems;
    const isValid = totalItems > position && position >= 0;

    if (!isValid)
        return;

    // do not use navigate - it drops slate cursor position
    window.history.replaceState(null, '', getSegmentPath(filter, position + 1));
    await setSegmentByPositionAction(position);
}

export async function replaceTranslationsAction(textToReplace: string, replaceWith: string) {
    const filter = segmentListStore.getState().filter;
    const value = await replaceTranslations(filter, textToReplace, replaceWith);
    if (!value)
        return;
    setAlertAction(new Alert({
        message: "Replaced " + value + " items",
        severity: "success",
    }));
}

export async function goToSegmentPositionByOrderAction(segmentOrder: number,
                                                       filter: SegmentListFilter,
                                                       navigate: NavigateFunction) {
    dispatcher.dispatch(setIsLoading(true));
    dispatcher.dispatch(setSegmentListItems(Map()));

    let positionNumber = await getSegmentPositionByOrder(segmentOrder, filter);
    if (!positionNumber || positionNumber === -1)
        positionNumber = 0;

    navigate(getSegmentPath(filter, positionNumber), {replace: true});
    dispatcher.dispatch(setIsLoading(false));
}

async function updateSelectedEvents(filter: SegmentListFilter, allSegmentEvents: Immutable.List<CatEventTypeModel>) {
    const selected = segmentEventTypeActions.state.selected;

    let eventTypesSet: Immutable.Set<CatEventTypeModel> = Immutable.Set<CatEventTypeModel>();
    if (!!filter.eventTypes)
        eventTypesSet = allSegmentEvents
            .filter(event => filter.eventTypes?.contains(event.id))
            .toSet();

    if (!selected.equals(eventTypesSet)) {
        segmentEventTypeActions.select(eventTypesSet);
    }
}

export const flatGroupedSegmentListActions
    = new CommonListActions<GroupedListItem<Segment>, SegmentListFilter>(
    fetchGroupedSegments,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<GroupedListItem<Segment>>();
    },
    AvailableResourceType.Segments)
