import {ReduceStore} from "flux/utils";
import {List, Record} from 'immutable';
import {Model} from "../../../model/common/IModel";
import {dispatcher, Payload} from "../../Dispatcher";
import {IFilter} from "../../../model/common/Filter";
import {InfiniteListAction} from "./InfinateListPayload";

interface IInfiniteListState<M extends Model, F extends IFilter<F>> {
    isLoading: boolean,
    hasNext: boolean,
    items: List<M>,
    filter: F | null,
}

const InfiniteListStateFactory = Record<IInfiniteListState<any, any>>({
    isLoading: false,
    hasNext: true,
    items: List(),
    filter: null
});

class InfiniteListState<M extends Model, F extends IFilter<F>>
    extends InfiniteListStateFactory implements IInfiniteListState<M, F> {
}

export class InfiniteListStore<M extends Model, F extends IFilter<F>>
    extends ReduceStore<InfiniteListState<M, F>, Payload> {

    constructor() {
        super(dispatcher);
        this._storeId = this.getDispatchToken();
    }

    private readonly _storeId: string;

    get storeId() {
        return this._storeId;
    }

    getInitialState() {
        return new InfiniteListState();
    }

    reduce(state: InfiniteListState<M, F>, payload: Payload): InfiniteListState<M, F> {
        if (!("storeId" in payload))
            return state;

        if (payload.storeId !== this._storeId)
            return state;

        switch (payload.action) {
            case InfiniteListAction.SetItems:
                return state
                    .set("items", payload.items)
                    .set("hasNext", payload.hasNext)
                    .set("filter", payload.filter);
            case InfiniteListAction.SetIsLoading:
                return state.set("isLoading", payload.isLoading)
            default:
                return state;
        }
    }
}