import {CommonBuildStore} from "./CommonBuildStore";
import {hasModel, timeoutPromise} from "../../../globals/Utils";
import {dispatcher} from "../../Dispatcher";
import {addBuildPayload, removeBuildPayload} from "./CommonBuildPayload";
import {Build} from "../../../model/Build";
import {BuildModel} from "../../../model/BuildModel";
import SegmentListFilter from "../../segment/list/SegmentListFilter";
import {Model} from "../../../model/common/IModel";

export class CommonBuildActions<M extends BuildModel> {

    constructor(build: (model: M) => Promise<Build>,
                fetchBuild: (build: Build) => Promise<Build>,
                download: (build: Build) => void) {
        this.store = new CommonBuildStore();
        this._buildModel = build;
        this._fetchBuildModel = fetchBuild;
        this._downloadBuildModel = download;
        this.storeId = this.store.storeId;
    }

    public readonly store: CommonBuildStore<M>;
    public readonly storeId: string;
    protected readonly _buildModel: (model: M) => Promise<Build>;
    protected readonly _fetchBuildModel: (build: Build) => Promise<Build>;
    protected readonly _downloadBuildModel: (build: Build) => void;

    get state() {
        return this.store.getState();
    }

    public addListener(callback: () => void) {
        return this.store.addListener(callback);
    }

    public async build<B extends M>(buildModel: B, translationsMode: boolean = false) {
        let model
        if (buildModel instanceof SegmentListFilter)
            model = buildModel.nonNullCatFile;
        else
            model = buildModel as Model

        if (hasModel(model, this.store.getState().building))
            return;

        dispatcher.dispatch(addBuildPayload(this.storeId, model));

        let build = await this.receiveBuild(buildModel, translationsMode);
        while (true) {
            await timeoutPromise(1000);
            build = await this._fetchBuildModel(build);
            if (build.isAvailable() || build.isError())
                break;
        }
        if (build.isAvailable() )
            this._downloadBuildModel(build);

        dispatcher.dispatch(removeBuildPayload(this.storeId, model));
    }

    protected async receiveBuild(model: M, translationsMode: boolean = false) {
        return await this._buildModel(model);
    }
}