import {ReduceStore} from "flux/utils";
import Immutable, {List} from "immutable";
import {dispatcher, Payload} from "../Dispatcher";
import {NotificationAction, NotificationPayload} from "./NotificationPayload";
import NotificationModel from "../../model/NotificationModel";

interface INotificationState {
    notificationList: List<NotificationModel>
}

const NotificationStateFactory = Immutable.Record<INotificationState>({
    notificationList: List()
});

class NotificationState extends NotificationStateFactory {
}

export class NotificationStore extends ReduceStore<NotificationState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): NotificationState {
        return new NotificationState();
    }

    reduce(state: NotificationState, payload: NotificationPayload): NotificationState {
        switch (payload.action) {
            case NotificationAction.SetNotificationList:
                return state.set("notificationList", payload.notificationList);
            default:
                return state;
        }
    }
}

const notificationStore = new NotificationStore();
export default notificationStore;
