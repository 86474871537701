import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import React, {useEffect, useRef, useState} from "react";
import {List} from "immutable";
import {VariableSizeList} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {InfiniteListActions} from "../../flux/common/list/InfiniteListActions";
import {Model} from "../../model/common/IModel";
import {IFilter} from "../../model/common/Filter";
import {ListEntity} from "../../model/common/ListEntity";

export interface IListEntityProps<M extends Model, F extends IFilter<F>, E extends ListEntity> {
    actions: InfiniteListActions<M, F>,
    itemSize: (index: number, size: Size, items: List<E>) => number,
    itemsConverter: (items: List<M>) => List<E>,
    drawItems: (index: number, size: Size, style: React.CSSProperties, items: List<E>) => React.JSX.Element
}

export default function LazyLoadedList<M extends Model, F extends IFilter<F>, E extends ListEntity>(
    props: IListEntityProps<M, F, E>) {
    const listRef = useRef<VariableSizeList | null>(null);
    const [hasNext, setHasNext] = useState(true);
    const [items, setItems] = useState(List<E>);

    useEffect(() => {
        const storeListener = props.actions.addListener(() => {
            const state = props.actions.state;
            setItems(props.itemsConverter(state.items));
            setHasNext(state.hasNext);
        });

        return () => storeListener.remove();
    });

    return <AutoSizer>
        {size =>
            drawLazyLoadedList(props.actions, items, size, props.itemSize, props.drawItems, listRef, hasNext)}
    </AutoSizer>
}

function drawLazyLoadedList<M extends Model, F extends IFilter<F>, E extends ListEntity>(
    actions: InfiniteListActions<M, F>,
    items: List<E>,
    size: Size,
    itemSize: (index: number, size: Size, items: List<E>) => number,
    drawItems: (index: number, size: Size, style: React.CSSProperties, items: List<E>) => React.JSX.Element,
    listRef: React.MutableRefObject<VariableSizeList | null>,
    hasNext: boolean) {
    const itemCount = hasNext ? items.size + 1 : items.size;
    return (
        <InfiniteLoader
            isItemLoaded={index => !hasNext || index < items.size}
            itemCount={itemCount}
            loadMoreItems={() => actions.loadMoreItems()}>
            {({onItemsRendered}) => (
                <VariableSizeList itemCount={itemCount} onItemsRendered={onItemsRendered} ref={listRef}
                                  width={size.width} height={size.height}
                                  itemSize={index => itemSize(index, size, items)}>
                    {({index, style}) => drawItems(index, size, style, items)}
                </VariableSizeList>
            )}
        </InfiniteLoader>
    );
}