import {List, Map, Record} from "immutable";
import {GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel} from "@mui/x-data-grid";
import {IFilter} from "../../model/common/Filter";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../utils/ListViewUtils";
import {isFilterInvalid} from "../../utils/FilterUtils";

interface IUsersListOperatorFilter {
    nameOperator: string | null,
    firstNameOperator: string | null,
    lastNameOperator: string | null,
    emailOperator: string | null,
    userGroupOperator: string | null,
    tenantOperator: string | null,
    enabledOperator: string | null
}

interface IUsersListFilter extends IUsersListOperatorFilter {
    projectId: number | null,
    onlySubordinate: boolean,
    name: string | string[],
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    userGroup: List<string> | null,
    tenant: string | null,
    enabled: string | null,
    orderBy: string | null,
    orderDirection: string | null
}

const UsersListFilterRecord = Record<IUsersListFilter>({
    projectId: null,
    onlySubordinate: true,
    name: [],
    nameOperator: null,
    firstName: null,
    firstNameOperator: null,
    lastName: null,
    lastNameOperator: null,
    email: null,
    emailOperator: null,
    userGroup: null,
    userGroupOperator: null,
    tenant: null,
    tenantOperator: null,
    enabled: null,
    enabledOperator: null,
    orderBy: null,
    orderDirection: null
});

export default class UsersListFilter extends UsersListFilterRecord implements IFilter<UsersListFilter> {
    updateWithGridFilterModel(model: GridFilterModel) {
        return updateWithGridFilterModel<IUsersListFilter, UsersListFilter>(this, model, AvailableUserFilters);
    }

    updateWithGridSortingModel(model: GridSortModel) {
        return updateWithGridSortingModel<IUsersListFilter, UsersListFilter>(this, model, AvailableUserSortedFields);
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "name", this.name);
        addSearchParam(result, "nameOperator", this.nameOperator);
        addSearchParam(result, "firstName", this.firstName);
        addSearchParam(result, "firstNameOperator", this.firstNameOperator);
        addSearchParam(result, "lastName", this.lastName);
        addSearchParam(result, "lastNameOperator", this.lastNameOperator);
        addSearchParam(result, "email", this.email);
        addSearchParam(result, "emailOperator", this.emailOperator);
        addSearchParam(result, "userGroup", this.userGroup ? this.userGroup.toArray() : null);
        addSearchParam(result, "userGroupOperator", this.userGroupOperator);
        addSearchParam(result, "tenant", this.tenant);
        addSearchParam(result, "tenantOperator", this.tenantOperator);
        addSearchParam(result, "enabled", this.enabled);
        addSearchParam(result, "orderBy", this.orderBy);
        addSearchParam(result, "orderDirection", this.orderDirection);
        return result.join('&');
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.name && this.nameOperator)
            items.push({field: 'name', operator: this.nameOperator, value: this.name});
        if (this.firstName && this.firstNameOperator)
            items.push({field: 'firstName', operator: this.firstNameOperator, value: this.firstName});
        if (this.lastName && this.lastNameOperator)
            items.push({field: 'lastName', operator: this.lastNameOperator, value: this.lastName});
        if (this.email && this.emailOperator)
            items.push({field: 'email', operator: this.emailOperator, value: this.email});
        if (this.userGroup && this.userGroupOperator)
            items.push({field: 'userGroup', operator: this.userGroupOperator, value: this.userGroup});
        if (this.tenant && this.tenantOperator)
            items.push({field: 'tenant', operator: this.tenantOperator, value: this.tenant});
        if (this.enabled && this.enabledOperator)
            items.push({field: 'enabled', operator: this.enabledOperator, value: this.enabled})
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        if (this.orderBy && this.orderDirection) {
            const foundEntry = AvailableUserSortedFields.findEntry(value =>
                value === this.orderBy);
            if (foundEntry === undefined)
                return [];
            return [{field: foundEntry[0], sort: this.orderDirection as GridSortDirection}];
        }
        return [];
    }

    isValid(): boolean {
        return !(isFilterInvalid(this.name, this.nameOperator)
            || isFilterInvalid(this.firstName, this.firstNameOperator)
            || isFilterInvalid(this.lastName, this.lastNameOperator)
            || isFilterInvalid(this.email, this.emailOperator)
            || isFilterInvalid(this.userGroup ? this.userGroup.toArray() : null, this.userGroupOperator)
            || isFilterInvalid(this.tenant, this.tenantOperator)
            || isFilterInvalid(this.enabled, this.enabledOperator));
    }

    static fromSearchParams(projectId: number | null, searchParams: URLSearchParams) {
        return new UsersListFilter({
            projectId: projectId,
            name: searchParams.getAll("name").map(name => decodeURIComponent(name)),
            nameOperator: searchParams.get("nameOperator"),
            firstName: searchParams.get("firstName"),
            firstNameOperator: searchParams.get("firstNameOperator"),
            lastName: searchParams.get("lastName"),
            lastNameOperator: searchParams.get("lastNameOperator"),
            email: searchParams.get("email"),
            emailOperator: searchParams.get("emailOperator"),
            userGroup: List(searchParams.getAll("userGroup")),
            userGroupOperator: searchParams.get("userGroupOperator"),
            tenant: searchParams.get("tenant"),
            tenantOperator: searchParams.get("tenantOperator"),
            enabled: searchParams.get("enabled"),
            enabledOperator: searchParams.get("enabledOperator"),
            orderBy: searchParams.get("orderBy"),
            orderDirection: searchParams.get("orderDirection")
        });
    }
}

const AvailableUserFilters = Map<string, string>()
    .set('name', 'nameOperator')
    .set('firstName', 'firstNameOperator')
    .set('lastName', 'lastNameOperator')
    .set('email', 'emailOperator')
    .set('userGroup', 'userGroupOperator')
    .set('tenant', 'tenantOperator')
    .set('enabled', 'enabledOperator');

const AvailableUserSortedFields = Map<string, string>()
    .set('name', 'name')
    .set('firstName', 'firstName')
    .set('lastName', 'lastName')
    .set('email', 'email')
    .set('userGroup', 'userGroup')
    .set('tenant', 'tenant')
    .set('enabled', 'enabled');