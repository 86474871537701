import Box from "@mui/material/Box";
import LazyLoadedList from "../common/LazyLoadedList";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";
import {TranslationMemorySegment} from "../../model/TranslationMemorySegment";
import {List} from "immutable";
import {TranslationMemorySegmentEntity} from "../../model/common/ListEntity";
import {Container, LinearProgress} from "@mui/material";
import React, {CSSProperties} from "react";
import TranslationMemorySegmentView from "./TranslationMemorySegmentView";
import {getItemSize} from "../../utils/EditorUtils";
import {editorText} from "../../utils/slate/SlateUtils";
import {Size} from "react-virtualized-auto-sizer";

export default function TranslationMemorySegmentList() {
    return <Box sx={{flex: 1}}>
        <LazyLoadedList actions={translationMemorySegmentListActions} itemSize={itemSize}
                        itemsConverter={itemsConverter}
                        drawItems={(index, _size, style, items) =>
                            drawSegment(index, style, items)}/>
    </Box>
}

function itemsConverter(items: List<TranslationMemorySegment>): List<TranslationMemorySegmentEntity> {
    let result = List<TranslationMemorySegmentEntity>();
    items.forEach(item => result = result.push({segment: item}));
    return result;
}

function drawSegment(index: number, style: CSSProperties, items: List<TranslationMemorySegmentEntity>) {
    const entity = items.get(index)!;
    if (!entity) {
        return (
            <Container style={style}>
                <LinearProgress/>
            </Container>
        );
    }

    return <TranslationMemorySegmentView segment={entity.segment} index={index} style={style}/>;
}

function itemSize(index: number, size: Size, items: List<TranslationMemorySegmentEntity>) {
    let item = items.get(index)?.segment;
    if (!item)
        item = new TranslationMemorySegment();

    return getItemSize(editorText(item.sourceEditor), item.sourceId, (size.width - 300) / 2 - 50);
}