import Project from "../../../model/Project";
import {createProject, deleteProjects, fetchProjects} from "../../../api/ProjectApi";
import {WorkflowStepModel} from "../../../model/WorkflowStepModel";
import Immutable, {List} from "immutable";
import {uploadFilesAction} from "../page/ProjectPageActions";
import {CommonListActions} from "../../common/list/CommonListActions";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {AvailableResourceType} from "../../../model/AvailableResources";
import ProjectListItem from "../../../model/ProjectListItem";
import ProjectListFilter from "./ProjectListFilter";

class ProjectListActions extends CommonListActions<ProjectListItem, ProjectListFilter> {

    constructor() {
        super(
            fetchProjects,
            async (toDelete: Immutable.Set<ProjectListItem>) => {
                await deleteProjects(toDelete);
            },
            async () => {
                dispatchUnsupportedOperation();
                return List<ProjectListItem>();
            },
            AvailableResourceType.Projects);
    }
}

export const projectListActions = new ProjectListActions();

export async function createProjectAction(project: Project,
                                          workflowSteps: WorkflowStepModel | null,
                                          files: List<File>,
                                          multiLanguage: boolean) {
    const created = await createProject(project, workflowSteps);
    await uploadFilesAction(files, created.id, multiLanguage);
    await projectListActions.refresh();
    return created;
}