import {Record} from "immutable";
import {AlertColor} from "@mui/material";

interface IAlert {
    message: string,
    severity: AlertColor,
}

const AlertRecord = Record<IAlert>({
    message: "",
    severity: "error",
});

export default class Alert extends AlertRecord {
};