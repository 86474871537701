import {
    deleteVendorManagersAction,
    vendorLinguistsListActions,
    vendorManagersListActions
} from "../../flux/vendor/editor/VendorEditorActions";
import {List} from "immutable";
import ListView from "../common/ListView";
import React, {useEffect, useState} from "react";
import { userColumns } from "./VendorEditor";
import vendorEditorStore from "../../flux/vendor/editor/VendorEditorStore";
import VendorUsersListFilter from "../../flux/vendor/VendorUsersListFilter";

export function VendorLinguistsEditor() {
    const initialVendor = vendorEditorStore.getState().vendor;
    const [vendor, setVendor] = useState(initialVendor);
    const [vendorFilter, setVendorFilter] = useState(new VendorUsersListFilter({vendorId: initialVendor.id}));

    useEffect(() => {
        const vendorListener = vendorEditorStore.addListener(() => {
            const state = vendorEditorStore.getState();
            setVendor(state.vendor);
            const filter = new VendorUsersListFilter({vendorId: state.vendor.id});
            setVendorFilter(filter);
            vendorLinguistsListActions.fetch(filter);
            vendorManagersListActions.fetch(filter);
        });

        return () => vendorListener.remove();
    });

    return <ListView actions={vendorLinguistsListActions}
                     columns={userColumns(id => deleteVendorManagersAction(vendor, List.of(id)))}
                     navigateProps={null}
                     initialFilter={vendorFilter}>
    </ListView>;
}