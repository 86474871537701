import {UserGroup} from "../../model/UserGroup";

export function toUserGroupModel(role: IUserGroupResponse) {
    return new UserGroup({
        id: role.id,
        name: role.name
    });
}

export interface IUserGroupResponse {
    id: string,
    name: string
}