import Immutable from "immutable";

interface IPretranslateStatus {
    total: number,
    competed: number,
    isCanceling: boolean
}

const PretranslateStatusRecord = Immutable.Record<IPretranslateStatus>({
    total: 0,
    competed: 0,
    isCanceling: false
});

export class PretranslateStatus extends PretranslateStatusRecord {
    get inProgress() {
        return this.total !== 0 || this.competed !== 0;
    }

    get percent() {
        if (this.total === 0)
            return 100;

        return Math.round(100 * this.competed / this.total)
    }
}