export enum TranslationMemorySearchAction {
    SetSearchMenuOpen = "TranslationMemorySearchAction.SetSearchMenuOpen"
}

interface ISetSearchMenuOpen {
    action: TranslationMemorySearchAction.SetSearchMenuOpen,
    searchMenuOpen: boolean
}

export type TranslationMemorySearchPayload = ISetSearchMenuOpen;

export function setTranslationMemorySearchMenuOpen(isOpen: boolean) {
    return {
        action: TranslationMemorySearchAction.SetSearchMenuOpen,
        searchMenuOpen: isOpen
    }
}