import {fetchProject, saveGlossaries, savePlaceholdersSet, saveTranslationMemories} from "../../../api/ProjectApi";
import {dispatcher} from "../../Dispatcher";
import {setFilesState, setProjectPayload} from "./ProjectPagePayload";
import {updateTranslationForLanguage, updateTranslations, uploadFile} from "../../../api/FileApi";
import {CatFile, FileState} from "../../../model/CatFile";
import {List} from "immutable";
import Project from "../../../model/Project";
import {setWaitingForAlertAction} from "../../alert/AlertActions";
import TranslationMemory, {ProjectTranslationMemory} from "../../../model/TranslationMemory";
import {Language} from "../../../model/Language";

export function initProjectAction() {
    dispatcher.dispatch(setProjectPayload(new Project()));
}

export async function uploadFilesAction(files: List<File>, id: number, multiLanguage: boolean) {
    files.map(file => uploadFile(file, id, file.name, multiLanguage));
}

export async function updateProjectFilesTranslationsForLanguageAction(files: List<File>,
                                                                      id: number,
                                                                      language: Language) {
    files.map(file => updateTranslationForLanguage(file, id, language));
}

export function setFileStateAction(fileState: FileState) {
    dispatcher.dispatch(setFilesState(List<number>(), fileState));
}

export function uploadFileAction(file: File,
                                 catFile: CatFile,
                                 projectId: number,
                                 fileName: string,
                                 multiLanguage: boolean) {
    dispatcher.dispatch(setFilesState(List.of(catFile.id), FileState.SegmentsReading));
    uploadFile(file, projectId, fileName, multiLanguage)
}

export function updateTranslationsAction(file: File, catFile: CatFile, projectId: number) {
    dispatcher.dispatch(setFilesState(List.of(catFile.id), FileState.Translating))
    updateTranslations(file, catFile, projectId);
}

export async function savePlaceholdersSetAction(id: number,
                                                placeholdersSetId: number | null,
                                                refuseSaveSegmentsWithPlaceholderErrors: boolean) {
    const project = await savePlaceholdersSet(id, placeholdersSetId, refuseSaveSegmentsWithPlaceholderErrors);
    dispatcher.dispatch(setProjectPayload(project));
}

export async function saveGlossariesAction(id: number, glossaryIds: List<number>) {
    const project = await saveGlossaries(id, glossaryIds.toArray());
    dispatcher.dispatch(setProjectPayload(project));
}

export async function saveTranslationMemoriesAction(id: number,
                                                    translationMemories: List<TranslationMemory>,
                                                    writableTm: ProjectTranslationMemory | null) {
    const project = await saveTranslationMemories(id, translationMemories, writableTm);
    dispatcher.dispatch(setProjectPayload(project));
    setWaitingForAlertAction(writableTm !== null);
}

export async function fetchProjectAction(id: number) {
    const project = await fetchProject(id);
    dispatcher.dispatch(setProjectPayload(project));
    return project;
}
