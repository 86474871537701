export class FontSizeCalc {

    public static Instance = new FontSizeCalc();

    calcHeight(text: string, width: number, fontFamily: string, fontSize: string, wordBreak: string) {
        if (text.length < 40)
            return 24;
        const id = "calc_height";
        let tester = document.getElementById(id);
        if (!tester)
            tester = document.createElement("p");

        document.body.appendChild(tester);
        tester.id = id;
        tester.style.fontFamily = fontFamily;
        tester.style.fontSize = fontSize;
        tester.style.position = 'fixed';
        tester.style.visibility = 'hidden';
        tester.style.wordBreak = wordBreak;
        tester.style.width = width + 'px';
        tester.innerHTML = text;
        return tester.clientHeight;
    }
}