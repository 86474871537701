// TODO - That's a workaround for error https://github.com/BipanKishore/resizable-panes/issues/127
// For some reason it occurs only when only 1 pane is visible (filters pane), so additional empty pane is added to avoid it
import {Pane} from "resizable-panes-react";
import {FontSizeCalc} from "../globals/FontSizeCalc";

export function drawWorkaroundContainer() {
    return (
        <Pane id="workaround-pane" size={0} maxSize={0} className="workaround-pane"/>
    );
}

// TODO: remove hardcode. Should be find out how to calc properly height
export function getItemSize(sourceText: string, sourceIdText: string, width: number, minHeight: number = 76) {
    const source = FontSizeCalc
        .Instance
        .calcHeight(
            sourceText,
            width,
            'Source Sans Pro,Arial,sans-serif',
            '1rem',
            'break-word');
    const sourceId = FontSizeCalc
        .Instance
        .calcHeight(
            sourceIdText,
            width,
            'Roboto',
            '12px',
            'break-all');
    return Math.max(minHeight, source + sourceId + 10);

}