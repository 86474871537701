import {Grid2, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {grey} from "@mui/material/colors";
import React, {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";
import {getTranslationMemoryPath} from "../../routes/editor/TranslationMemoryEditorRoute";
import IconButton from "@mui/material/IconButton";
import {ClearIcon} from "@mui/x-date-pickers";
import {ReactSetter} from "../../globals/Types";

export default function TranslationMemorySegmentListControls() {
    const [filter, setFilter]
        = useState<TranslationMemorySegmentFilter | null>(translationMemorySegmentListActions.state.filter);
    const [source, setSource] = useState(translationMemorySegmentListActions.state.filter.source);
    const [target, setTarget] = useState(translationMemorySegmentListActions.state.filter.target);

    const navigate = useNavigate();

    useEffect(() => {
        const segmentListListener = translationMemorySegmentListActions.addListener(() => {
            const state = translationMemorySegmentListActions.state;
            setFilter(state.filter);
            setSource(state.filter.source);
            setTarget(state.filter.target);
        });

        return () => segmentListListener.remove();
    }, []);

    if (!filter)
        return null;

    return <Grid2 container rowSpacing={0.5} columnSpacing={1} alignItems={"center"} sx={{padding: '0px'}}>
        <Grid2 size={6}>
            <TextField size="small" fullWidth placeholder="Search in Source"
                       onChange={event => setSource(event.target.value)}
                       value={source}
                       onBlur={() => handleSaveFilter(filter, source, target, navigate)}
                       onKeyDown={e =>
                           handelOnCompleteEdit(e, filter, source, target, navigate)}
                       slotProps={{
                           input: {
                               style: {height: '30px'},
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon sx={{color: grey[500]}}/>
                                   </InputAdornment>
                               ),
                               endAdornment: (
                                   <InputAdornment position={"end"} onClick={() =>
                                       handleClearSource(filter, setSource, target, navigate)}>
                                       <IconButton disableRipple={true}>
                                           <ClearIcon/>
                                       </IconButton>
                                   </InputAdornment>
                               )
                           }
                       }}/>
        </Grid2>
        <Grid2 size={6}>
            <TextField
                size="small"
                fullWidth
                placeholder="Search in Target"
                onChange={event => setTarget(event.target.value)}
                value={target}
                onBlur={() => handleSaveFilter(filter, source, target, navigate)}
                onKeyDown={e => handelOnCompleteEdit(e, filter, source, target, navigate)}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{color: grey[500]}}/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position={"end"} onClick={() =>
                                handleClearTarget(filter, source, setTarget, navigate)}>
                                <IconButton disableRipple={true}>
                                    <ClearIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {height: '30px'}
                    }
                }}/>
        </Grid2>
    </Grid2>
}

function handleSaveFilter(filter: TranslationMemorySegmentFilter,
                          source: string,
                          target: string,
                          navigate: NavigateFunction) {
    navigate(getTranslationMemoryPath(filter.set("source", source).set("target", target)), {replace: true});
}

function handelOnCompleteEdit(event: React.KeyboardEvent<HTMLDivElement>,
                              filter: TranslationMemorySegmentFilter,
                              source: string,
                              target: string,
                              navigate: NavigateFunction) {
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            handleSaveFilter(filter, source, target, navigate);
        }
    }
}

function handleClearSource(filter: TranslationMemorySegmentFilter,
                           setSource: ReactSetter<string>,
                           target: string,
                           navigate: NavigateFunction) {
    setSource("");
    handleSaveFilter(filter, "", target, navigate);
}

function handleClearTarget(filter: TranslationMemorySegmentFilter,
                           source: string,
                           setTarget: ReactSetter<string>,
                           navigate: NavigateFunction) {
    setTarget("");
    handleSaveFilter(filter, source, "", navigate);
}