import {InputAdornment, Menu, MenuItem} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import {DropdownArrow, DropdownMenuLinkShort, MenuLinkText, SearchField} from "../../globals/CommonComponents";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {Search} from "@mui/icons-material";
import TranslationMemory from "../../model/TranslationMemory";
import {OpenProps, ReactSetter} from "../../globals/Types";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";
import {getTranslationMemoryPath} from "../../routes/editor/TranslationMemoryEditorRoute";
import {Language} from "../../model/Language";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";

export default function TranslationMemoryLanguageDropdown(props: OpenProps) {
    const [filter, setFilter]
        = useState<TranslationMemorySegmentFilter | null>(translationMemorySegmentListActions.state.filter);
    const [searchLanguageFilter, setSearchLanguageFilter] = useState("");
    const [selectLanguageMenuAnchor, setSelectLanguageMenuAnchor] = useState<HTMLElement | null>(null);
    const [selectLanguageMenuOpen, setSelectLanguageMenuOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const translationMemorySegmentListListener = translationMemorySegmentListActions
            .addListener(() => {
                setFilter(translationMemorySegmentListActions.state.filter);
            });

        return () => translationMemorySegmentListListener.remove();
    });

    if (!props.isOpen || !filter)
        return null;

    return <Box>
        <DropdownMenuLinkShort variant="menu" onClick={e =>
            handleSelectLanguageMenuOpen(e, setSelectLanguageMenuAnchor, setSelectLanguageMenuOpen)}>
            <MenuLinkText>
                {filter.translationMemory.sourceLanguage?.id.toUpperCase() + " → " +
                    filter.language?.id.toUpperCase()}
            </MenuLinkText>
            <DropdownArrow fontSize="inherit"/>
        </DropdownMenuLinkShort>
        <Menu open={selectLanguageMenuOpen} anchorEl={selectLanguageMenuAnchor}
              onClose={() => handleSelectLanguageMenuClosed(
                  setSelectLanguageMenuAnchor,
                  setSelectLanguageMenuOpen,
                  setSearchLanguageFilter)}>
            {drawLanguageMenuItems(
                filter.translationMemory,
                searchLanguageFilter,
                filter,
                setSelectLanguageMenuAnchor,
                setSelectLanguageMenuOpen,
                setSearchLanguageFilter,
                navigate)}
        </Menu>
    </Box>;
}

function handleSelectLanguageMenuOpen(event: React.MouseEvent<HTMLElement>,
                                      setSelectLanguageMenuAnchor: ReactSetter<HTMLElement | null>,
                                      setSelectLanguageMenuOpen: ReactSetter<boolean>) {
    setSelectLanguageMenuAnchor(event.currentTarget);
    setSelectLanguageMenuOpen(true);
}

function handleSelectLanguageMenuClosed(setSelectLanguageMenuAnchor: ReactSetter<HTMLElement | null>,
                                        setSelectLanguageMenuOpen: ReactSetter<boolean>,
                                        setSearchLanguageFilter: ReactSetter<string>) {
    setSelectLanguageMenuAnchor(null);
    setSelectLanguageMenuOpen(false);
    setSearchLanguageFilter("");
}

function drawLanguageMenuItems(translationMemory: TranslationMemory,
                               searchLanguageFilter: string,
                               filter: TranslationMemorySegmentFilter,
                               setSelectLanguageMenuAnchor: ReactSetter<HTMLElement | null>,
                               setSelectLanguageMenuOpen: ReactSetter<boolean>,
                               setSearchLanguageFilter: ReactSetter<string>,
                               navigate: NavigateFunction) {
    const menuItems = [<SearchField key={"language-menu-search"} size={"small"}
                                    placeholder={"Search..."}
                                    slotProps={{
                                        input: {
                                            startAdornment: <InputAdornment position={"start"}>
                                                <Search/>
                                            </InputAdornment>
                                        }
                                    }}
                                    onChange={e =>
                                        handleSearchLanguageTextChanged(e, setSearchLanguageFilter)}
                                    onKeyDown={e => e.stopPropagation()}/>];

    translationMemory.targetLanguages.map(targetLanguage => {
        if (!targetLanguage.name.toLowerCase().includes(searchLanguageFilter))
            return;
        menuItems.push(<MenuItem key={"language-menu-" + targetLanguage.id}
                                 onClick={() => handleSelectLanguage(
                                     targetLanguage,
                                     filter,
                                     setSelectLanguageMenuAnchor,
                                     setSelectLanguageMenuOpen,
                                     setSearchLanguageFilter,
                                     navigate)}>
            {translationMemory.sourceLanguage?.id.toUpperCase() + " - " + targetLanguage.id.toUpperCase()}
        </MenuItem>);
    });
    return menuItems;
}

function handleSearchLanguageTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                         setSearchLanguageFilter: (s: string) => void) {
    setSearchLanguageFilter(event.target.value.toLowerCase());
}

function handleSelectLanguage(language: Language,
                              filter: TranslationMemorySegmentFilter,
                              setSelectLanguageMenuAnchor: ReactSetter<HTMLElement | null>,
                              setSelectLanguageMenuOpen: ReactSetter<boolean>,
                              setSearchLanguageFilter: ReactSetter<string>,
                              navigate: NavigateFunction) {
    handleSelectLanguageMenuClosed(setSelectLanguageMenuAnchor, setSelectLanguageMenuOpen, setSearchLanguageFilter);
    navigate(getTranslationMemoryPath(filter.set("language", language)), {replace: true});
}