import {CommonBuildActions} from "./CommonBuildActions";
import Project from "../../../model/Project";
import {Build} from "../../../model/Build";
import {Model} from "../../../model/common/IModel";
import {buildCatFiles, buildCatFilesTranslations} from "../../../api/ProjectApi";
import {downloadCatFiles, fetchCatFilesBuild} from "../../../api/ProjectBuildApi";
import {catFileListActions} from "../../cat-files/CatFileListActions";
import {SelectionModel} from "../../../model/SelectionModel";

class SelectedCatFilesBuildActions<M extends Model> extends CommonBuildActions<M> {
    constructor(buildWithSelection: (model: M, selection: SelectionModel) => Promise<Build>,
                buildTranslations: (model: M, selection: SelectionModel) => Promise<Build>,
                fetchBuild: (build: Build) => Promise<Build>,
                download: (build: Build) => void) {
        super((m: M) => buildWithSelection(m, {} as SelectionModel), fetchBuild, download);
        this._buildTranslationsModel = buildTranslations;
        this._buildModelWithSelection = buildWithSelection;
    }

    private readonly _buildTranslationsModel: (model: M, selection: SelectionModel) => Promise<Build>;
    private readonly _buildModelWithSelection: (model: M, selection: SelectionModel) => Promise<Build>;

    protected async receiveBuild(model: M, translationsMode: boolean) {
        const state = catFileListActions.state;
        const selectionModel = state.selectionModel;
        if (translationsMode)
            return await this._buildTranslationsModel(model, selectionModel);
        return await this._buildModelWithSelection(model, selectionModel);
    }
}

export const selectedCatFilesBuildActions
    = new SelectedCatFilesBuildActions<Project>(buildCatFiles, buildCatFilesTranslations, fetchCatFilesBuild, downloadCatFiles);