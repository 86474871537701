import AdminUsersList from "../../components/admin/AdminUsersList";
import UsersListFilter from "../../flux/users/UsersListFilter";

export default function AdminUsersRoute(){
    return (<AdminUsersList/>);
}

export function getAdminUsersPath(filter: UsersListFilter) {
    return AdminUsersPath + "?" + filter.toSearchParams();
}

export const AdminUsersId = "AdminUsers";
export const AdminUsersPath = "/admin/users";