import React, {useEffect, useState} from "react";
import {Page} from "../../model/Page";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Model} from "../../model/common/IModel";
import {IFilter} from "../../model/common/Filter";
import {CommonListActions} from "../../flux/common/list/CommonListActions";
import GroupedListItem from "../../model/GropedListItem";
import {CircularProgress, Grid, List, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomPagination from "./CustomPagination";
import ListItem from "@mui/material/ListItem";
import {styled} from "@mui/material/styles";

export const ListContainer = styled(List)({
    border: '1px solid gray',
    borderRadius: '4px',
    padding: '0px 4px 0px 4px'
});

export const ListHeaderItem = styled(ListItem)({
    fontWeight: 'bold'
});

export const ListDataItem = styled(ListItem)({
    borderTop: '1px solid gray',
    padding: '0px 4px 0px 4px'
});

export interface IFlatGroupedListViewProps<M extends Model, G extends GroupedListItem<M>, F extends IFilter<F>> {
    actions: CommonListActions<G, F>,
    columns: GridColDef[],
    headerDrawer?: (group: GroupedListItem<M>, key: String) => React.JSX.Element;
}

export default function FlatGroupedListView<M extends Model, G extends GroupedListItem<M>, F extends IFilter<F>>(props: IFlatGroupedListViewProps<M, G, F>) {

    const state = props.actions.state;
    const [available, setAvailable] = useState<Page<G>>(state.page);
    const [isLoading, setIsLoading] = useState(state.isLoading);

    useEffect(() => {
        const storeListener = props.actions.addListener(() => {
            const state = props.actions.state;
            setIsLoading(state.isLoading);
            setAvailable(state.page);
        });
        return () => storeListener.remove();
    });

    if (isLoading)
        return <CircularProgress key={"circular-progress"}/>;

    const result = new ListBuilder().build(available, props.columns, props.headerDrawer);

    result.push(<CustomPagination
        onChange={page => props.actions.fetch(undefined, state.page.setPageNumber(page).pageable)}
        pageable={available.pageable}
        key={"custom-pagination"}
        onRowsPerPageChange={e => props.actions.fetch(undefined, state.page.setSize(Number(e.target.value)).pageable)}/>)
    return result;
}


class ListBuilder<M extends Model, G extends GroupedListItem<M>> {
    build(page: Page<G>, columns: GridColDef[], headerDrawer?: (group: G, key: String) => React.JSX.Element) {
        for (const row of page.list)
            this.processRow(row, columns, headerDrawer);

        if (this._rows.length > 0)
            this._result.push(this.drawSearchResultList(this._rows, columns));

        return this._result;
    }

    processRow(row: G, columns: GridColDef[], headerDrawer?: (group: G, key: String) => React.JSX.Element) {
        this._keyId = this._keyId + 1;
        if (row.isHeader)
            this.processHeader(row, columns, headerDrawer);
        else
            this._rows.push(row.element);
    }

    processHeader(row: G, columns: GridColDef[], headerDrawer?: (group: G, key: String) => React.JSX.Element) {
        if (this._rows.length > 0) {
            this._result.push(this.drawSearchResultList(this._rows, columns));
            this._rows = [];
        }

        if (headerDrawer === undefined)
            this._result.push(<Typography variant={"h6"} key={"header-" + this._keyId}>{row.header}</Typography>)
        else
            this._result.push(headerDrawer(row, "header-" + this._keyId));
    }

    drawSearchResultList<M extends Model>(rows: M[], columns: GridColDef[]) {
        return (
            <ListContainer key={"list-" + this._keyId}>
                <ListHeaderItem key={"list-header-" + this._keyId}>
                    <Grid key={"list-header-grid-" + this._keyId} container spacing={2}>
                        {this.drawHeader(columns)}
                    </Grid>
                </ListHeaderItem>
                {this.drawRows(rows, columns)}
            </ListContainer>
        );
    }

    drawHeader(columns: GridColDef[]) {
        return <>
            {columns.map((column, index) => (
                <Grid
                    item
                    xs={index === 2 ? 'auto' : true}
                    style={{flex: index === 2 ? 'none' : 1}}
                    key={"list-" + this._keyId + "-header-column-container-" + column.field}
                >
                    <ListItemText
                        primary={column.headerName}
                        key={"list-" + this._keyId + "-header-list-item-" + column.field}/>
                </Grid>
            ))}
        </>;
    }

    drawRows<M extends Model>(rows: M[], columns: GridColDef[]) {
        return <>
            {rows.map((row) => (
                <ListDataItem key={"list-" + this._keyId + "-row-list-item-" + row.id}>
                    <Grid container spacing={2} key={"list-" + this._keyId + "-row-grid-" + row.id}>
                        {this.buildColumnsForRow(columns, row)}
                    </Grid>
                </ListDataItem>
            ))}
        </>;
    }

    buildColumnsForRow<M extends Model>(columns: GridColDef[], row: M) {
        return <>
            {columns.map((column, index) => (
                <Grid
                    item
                    xs={index === 2 ? 'auto' : true}
                    style={{flex: index === 2 ? 'none' : 1}}
                    key={"list-" + this._keyId + "-row-" + row.id + "-column-container-" + column.field}
                >
                    {column.renderCell ? (
                        column.renderCell({
                            row: row,
                            field: column.field,
                            value: (row as any)[column.field]
                        } as GridRenderCellParams<M>)
                    ) : (
                        <ListItemText
                            primary={(row as any)[column.field]}
                            secondary={column.headerName}
                            key={"row-" + row.id + "-list-item-" + column.field}
                        />
                    )}
                </Grid>
            ))}
        </>;
    }

    _result: React.JSX.Element[] = [];
    _rows: M[] = [];
    _keyId = 1;
}
