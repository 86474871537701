import {List} from "immutable";
import {PretranslateSetting} from "../../model/PretranslateSetting";
import {PretranslateStatus} from "../../model/PretranslateStatus";

export enum PretranslateAction {
    SetSettings = "PretranslateAction.SetSettings",
    Restore = "PretranslateAction.Restore",
    SetStatus = "PretranslateAction.SetStatus",
}

interface ISetPretranslateSettingsPayload {
    action: PretranslateAction.SetSettings,
    settings: List<PretranslateSetting>
}

interface IRestorePretranslateSettingsPayload {
    action: PretranslateAction.Restore,
    data: string
}

interface ISetPretranslateStatusPayload {
    action: PretranslateAction.SetStatus,
    status: PretranslateStatus
}

export type PretranslatePayload = ISetPretranslateSettingsPayload
    | IRestorePretranslateSettingsPayload
    | ISetPretranslateStatusPayload;

export function setPretranslateSettingsPayload(settings: List<PretranslateSetting>): PretranslatePayload {
    return {
        action: PretranslateAction.SetSettings,
        settings: settings
    }
}

export function restorePretranslateSettingsPayload(data: string): PretranslatePayload {
    return {
        action: PretranslateAction.Restore,
        data: data
    }
}

export function setPretranslateStatusPayload(status: PretranslateStatus): PretranslatePayload {
    return {
        action: PretranslateAction.SetStatus,
        status: status
    }
}