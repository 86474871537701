import {Record} from "immutable";

interface IUserSetting {
    key: string
    data: string
}

const UserSettingRecord = Record<IUserSetting>({
    key: "",
    data: ""
})

export class UserSetting extends UserSettingRecord {
    get isEmpty() {
        return this.data.trim() === "";
    }
}