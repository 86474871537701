import {RenderElementProps} from "slate-react";
import {Placeholder, SlateParagraph} from "../../../../../globals/CommonComponents";
import {Tooltip} from "@mui/material";
import {placeholderContentPresentation, InlineChromiumBugfix} from "../../../../../utils/slate/SlateUtils";
import React from "react";
import {SlateEntityType, SlatePlaceholder} from "../../../../../utils/slate/SlateModels";

type RenderedSourceElementProps = {
    id: number | string,
    onPlaceholder: (placeholder: SlatePlaceholder) => void
}

export function RenderedSourceElement(props: RenderElementProps & RenderedSourceElementProps) {
    const {attributes, children, element} = props;
    switch (element.type) {
        case SlateEntityType.Paragraph:
            return <SlateParagraph {...attributes}>{children}</SlateParagraph>
        case SlateEntityType.Placeholder:
            return (
                <Tooltip title={placeholderContentPresentation(element.content)} >
                    <Placeholder {...attributes}
                                 contentEditable={false}
                                 onCopy={event => event.preventDefault()}
                                 onClick={() => props.onPlaceholder(element)}
                                 id={`placeholder-${props.id}-${element.order}`}
                                 tabIndex={0}
                                 style={{marginBottom: '1px'}}>
                        <InlineChromiumBugfix/>
                        {children}
                        <InlineChromiumBugfix/>
                    </Placeholder>
                </Tooltip>
            );
        case SlateEntityType.EscapedText:
            return <span {...attributes}>{children}</span>
        default:
            return <p {...attributes}>{children}</p>;
    }
}
