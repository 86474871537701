import TranslationMemorySegmentFilter from "../TranslationMemorySegmentFilter";
import {fetchTranslationMemory} from "../../../api/TranslationMemoryApi";
import {Language} from "../../../model/Language";
import {InfiniteListActions} from "../../common/list/InfiniteListActions";
import {fetchTranslationMemorySegments, saveTranslationMemorySegment} from "../../../api/TranslationMemorySegmentApi";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {TranslationMemorySegment} from "../../../model/TranslationMemorySegment";
import {dispatcher} from "../../Dispatcher";
import {setItemsPayload} from "../../common/list/InfinateListPayload";
import {editorText} from "../../../utils/slate/SlateUtils";

export async function initTranslationMemoryEditor(translationMemoryId: string,
                                                  language: string,
                                                  searchParams: URLSearchParams) {
    const translationMemory = await fetchTranslationMemory(translationMemoryId);
    const filter = TranslationMemorySegmentFilter
        .fromSearchParams(translationMemory, Language.fromSupported(language), searchParams);
    translationMemorySegmentListActions.refresh(filter);
    await translationMemorySegmentListActions.loadMoreItems(filter);
}

export function saveTranslationMemorySegmentAction(segmentIndex: number, segment: TranslationMemorySegment) {
    saveTranslationMemorySegment(segment, editorText(segment.targetEditor))
        .then(result =>
            modifyTranslationAction(segmentIndex, () => result));
}

export function updateCurrentTranslationAction(segmentIndex: number, translation: string) {
    return modifyTranslationAction(
        segmentIndex,
        value => saveCurrentTranslation(value, translation))
}

export function modifyTranslationAction(segmentIndex: number,
                                        modifier: (value: TranslationMemorySegment) => TranslationMemorySegment) {
    const updated = translationMemorySegmentListActions
        .state
        .items
        .map(((value, index) => {
            if (index !== segmentIndex)
                return value;
            return modifier(value);
        }));

    dispatcher.dispatch(setItemsPayload(
        translationMemorySegmentListActions.storeId,
        updated,
        translationMemorySegmentListActions.state.hasNext,
        translationMemorySegmentListActions.state.filter));
}

function saveCurrentTranslation(value: TranslationMemorySegment, translation: string) {
    return value.set("currentTarget", translation)
}

export const translationMemorySegmentListActions
    = new InfiniteListActions<TranslationMemorySegment, TranslationMemorySegmentFilter>(
    fetchTranslationMemorySegments,
    AvailableResourceType.TranslationMemories);