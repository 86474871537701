import React, {useRef, useState} from "react";
import {CircularProgress, Menu, MenuItem, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {lockTranslationsAction} from "../../../../flux/segment/list/SegmentListActions";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {ReactSetter} from "../../../../globals/Types";

type LockSegmentButtonProps = {
    buttons: {
        title: string,
        value: boolean
    }[],
    isLocking: boolean,
    tooltipTitle: string
};

export default function LockSegmentButton(props: LockSegmentButtonProps) {
    const [isOpen, setIsOpen] = useState(false);
    const lockButtonRef = useRef<HTMLButtonElement | null>(null);

    return (<Box>
        <Tooltip title={props.tooltipTitle}>
            <IconButton onClick={e => setIsOpen(true)} ref={lockButtonRef}>
                {segmentListStore.getState().longTasks.isUpdatingSegments ? (
                    <CircularProgress size="20px"/>
                ) : (
                    <img src={`/${props.tooltipTitle}.svg`} alt={props.tooltipTitle}/>
                )}
            </IconButton>
        </Tooltip>
        <Menu open={isOpen} onClose={() => setIsOpen(false)} anchorEl={lockButtonRef.current}>
            { drawButtonOptions(props, setIsOpen) }
        </Menu>
    </Box>)
}

function drawButtonOptions(props: LockSegmentButtonProps,
                           setIsOpen: ReactSetter<boolean>) {
    return props.buttons.map((option, index) => (
        <MenuItem key={index}
                  onClick={(event) => {
                      setIsOpen(false);
                      return lockTranslationsAction(props.isLocking, option.value);
                  }}>
            {option.title}
        </MenuItem>
    ));
}
