import {PretranslateSetting} from "../../model/PretranslateSetting";
import {dispatcher} from "../Dispatcher";
import {
    restorePretranslateSettingsPayload,
    setPretranslateSettingsPayload,
    setPretranslateStatusPayload
} from "./PretranslatePayload";
import pretranslateStore from "./PretranslateStore";
import {FileState} from "../../model/CatFile";
import {cancelPretranslate, pretranslate, pretranslateStatus} from "../../api/PretranslateApi";
import {setFileStateAction} from "../project/page/ProjectPageActions";
import {fetchSetting, saveSetting} from "../../api/UserSettingsApi";
import {UserSetting} from "../../model/UserSetting";
import projectPageStore from "../project/page/ProjectPageStore";

export async function setPretranslateSettingAction(setting: PretranslateSetting, step: number) {
    const updated = pretranslateStore.getState().settings.setIn([step], setting);

    dispatcher.dispatch(setPretranslateSettingsPayload(updated));

    await saveSettings();
}

export async function addPretranslateSettingAction() {
    const updated = pretranslateStore.getState().settings.push(new PretranslateSetting());

    dispatcher.dispatch(setPretranslateSettingsPayload(updated));

    await saveSettings();
}

export async function removePretranslateSettingAction(step: number) {
    const updated = pretranslateStore.getState().settings.remove(step);
    dispatcher.dispatch(setPretranslateSettingsPayload(updated));

    await saveSettings();
}

export async function pretranslateAction() {
    const projectId = projectPageStore.getState().project.id;
    setFileStateAction(FileState.Pretranslating);
    await pretranslate(projectId, pretranslateStore.getState().settings);
    await refreshPretranslateStatusAction();
}

export async function cancelPretranslateAction() {
    const projectId = projectPageStore.getState().project.id;
    await cancelPretranslate(projectId);

}

export async function restorePretranslateSettings() {
    const project = projectPageStore.getState().project;
    const setting = await fetchSetting(key(project.id));
    dispatcher.dispatch(restorePretranslateSettingsPayload(setting.data));
    await refreshPretranslateStatusAction()
}

export async function refreshPretranslateStatusAction() {
    const project = projectPageStore.getState().project;
    const status = await pretranslateStatus(project.id)
    dispatcher.dispatch(setPretranslateStatusPayload(status))
}

async function saveSettings() {
    const project = projectPageStore.getState().project;
    const data = JSON.stringify(pretranslateStore.getState().toJS())

    await saveSetting(new UserSetting({key: key(project.id), data: data}))
}

function key(projectId: number) {
    return `Pretranslate_settings_project_id_${projectId}`;
}