import TranslationMemorySegmentFilter from "../flux/translation-memory-segment/TranslationMemorySegmentFilter";
import {buildUrl, dispatchEditorError, dispatchError} from "../globals/ApiGlobals";
import axios, {AxiosResponse} from "axios";
import {
    ITranslationMemorySegmentResponse,
    ITranslationMemorySegmentSearchResponse, toTranslationMemorySegmentModel
} from "./model/TranslationMemorySegmentApiModel";
import {TranslationMemorySegment} from "../model/TranslationMemorySegment";
import {List} from "immutable";
import {editorText} from "../utils/slate/SlateUtils";

export function fetchTranslationMemorySegments(filter: TranslationMemorySegmentFilter,
                                               lastItem?: TranslationMemorySegment) {
    const url = buildUrl(endpoint + "/search", {}, {
        translationMemoryId: filter.translationMemory.id,
        translationMemoryName: filter.translationMemoryName,
        source: filter.source,
        target: filter.target,
        sourceId: filter.sourceId,
        matchCase: filter.matchCase,
        language: filter.language,
        pageSize: filter.pageSize,
        searchAfterSource: lastItem ? editorText(lastItem.sourceEditor) : null,
        searchAfterSourceId: lastItem ? lastItem.sourceId : null,
        searchAfterPreviousSource: lastItem ? lastItem.previousSource : null,
        searchAfterNextSource: lastItem ? lastItem.nextSource : null
    });

    return axios
        .get(url)
        .then((response: AxiosResponse<ITranslationMemorySegmentSearchResponse>) => {
            return List(response.data.results.map(toTranslationMemorySegmentModel));
        })
        .catch(error => {
            dispatchError(error);
            return List<TranslationMemorySegment>();
        })
}

export function saveTranslationMemorySegment(segment: TranslationMemorySegment,
                                             target: string) {
    return axios
        .post(endpoint + "/" + segment.id + "/translation", {
            languageCode: segment.language,
            target: target
        })
        .then((response: AxiosResponse<ITranslationMemorySegmentResponse>) => {
            return toTranslationMemorySegmentModel(response.data);
        })
        .catch(reason => {
            dispatchEditorError(reason);
            return new TranslationMemorySegment();
        });
}

const endpoint = "/api/pretranslate/translation-memory-segments";