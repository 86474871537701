import {Pageable} from "../../../model/Pageable";
import {
    listIsLoadingPayload,
    selectionModelDeselect,
    selectionModelSelect,
    setPagePayload,
    setSelectedPayload, setSelectionModel,
    toggleSelectAll
} from "./CommonListPayload";
import {Model} from "../../../model/common/IModel";
import {Page} from "../../../model/Page";
import {CommonListStore} from "./CommonListStore";
import {List, Set} from "immutable";
import {IFilter} from "../../../model/common/Filter";
import {dispatcher} from "../../Dispatcher";
import NullFilter from "../NullFilter";
import {AvailableResourceType} from "../../../model/AvailableResources";
import availableResourcesStore from "../../available-resources/AvailableResourcesStore";
import {Mutex} from "async-mutex";
import {SelectionModel} from "../../../model/SelectionModel";

export class CommonListActions<M extends Model, F extends IFilter<F>> {
    constructor(fetchModels: (filter: F, page?: Pageable) => Promise<Page<M>>,
                deleteModels: (toDelete: Set<M>) => Promise<void>,
                saveModels: (model: List<M>) => Promise<List<M>>,
                resource: AvailableResourceType) {
        this.store = new CommonListStore();
        this._fetchModels = fetchModels;
        this._deleteModels = deleteModels;
        this._saveModels = saveModels;
        this.storeId = this.store.storeId;
        this._resource = resource;
    }

    public readonly store: CommonListStore<M, F>;
    public readonly storeId: string;
    private readonly _fetchModels: (filter: F, page?: Pageable) => Promise<Page<M>>;
    private readonly _deleteModels: (toDelete: Set<M>) => Promise<void>;
    private readonly _saveModels: (model: List<M>) => Promise<List<M>>;
    private readonly _resource: AvailableResourceType;

    private mutex = new Mutex();

    public async saveAll(models: List<M>) {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, false, true, false);

        if (!available)
            return;

        this.setIsLoading();

        await this._saveModels(models);
        await this.refresh();
    }

    public async delete(model: M) {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, false, false, true);

        if (!available)
            return;

        this.setIsLoading();

        await this._deleteModels(Set.of(model));
        await this.refresh();
    }

    public async deleteSelected() {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, false, false, true);

        if (!available)
            return;

        this.setIsLoading();

        const toDelete: Set<M> = this.selected.toSet();
        await this._deleteModels(toDelete);
        dispatcher.dispatch(setSelectedPayload(this.storeId, Set()));
        await this.refresh();
    }

    public async refresh(showLoading: boolean = true) {
        const release = await this.mutex.acquire()
        try {
            await this._forceFetch(this.state.filter, this.state.page.pageable, showLoading);
        } finally {
            release();
        }
    }

    public async fetch(filter?: F, page?: Pageable) {
        const notChanged = this.filterNotChanged(filter) && this._pageNotChanged(page);
        if (notChanged && !this.state.isInitial)
            return;

        const state = this.store.getState();
        if (!page)
            page = state.page.pageable;

        if (!filter)
            filter = state.filter as F;

        dispatcher.dispatch(setPagePayload(
            this.storeId,
            state.page.setPage(page),
            filter,
            this.state.selectionModel)
        );

        const release = await this.mutex.acquire();
        try {
            await this._forceFetch(filter, page);
        } finally {
            release();
        }
    }

    public select(selection: Set<M>) {
        dispatcher.dispatch(setSelectedPayload(this.storeId, selection));
    }

    public toggleSelection(model: Set<M>) {
        let selection = this.state.selected;
        if (selection.has(model))
            selection = selection.delete(model);
        else
            selection = selection.add(model);

        dispatcher.dispatch(setSelectedPayload(this.storeId, selection));
    }

    public toggleSelectAllAction() {
        dispatcher.dispatch(toggleSelectAll(this.storeId));
    }

    public selectionModelSelect(id: number | string) {
        dispatcher.dispatch(selectionModelSelect(id, this.storeId));
    }

    public selectionModelDeselect(id: number | string) {
        dispatcher.dispatch(selectionModelDeselect(id, this.storeId));
    }

    public setIsLoading() {
        dispatcher.dispatch(listIsLoadingPayload(this.storeId, true))
    }

    public setSelectionModel(selection: SelectionModel) {
        dispatcher.dispatch(setSelectionModel(selection, this.storeId));
    }

    get state() {
        return this.store.getState();
    }

    get selected() {
        return this.state.selected;
    }

    get selection() {
        return this.state.selectionModel;
    }

    public addListener(callback: () => void) {
        return this.store.addListener(callback);
    }

    private async _forceFetch(filter: F, page: Pageable, showLoading: boolean = true) {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, true, false, false);

        if (!available)
            return;

        if (showLoading)
            dispatcher.dispatch(listIsLoadingPayload(this.storeId, true));

        const currentFilter = filter ? filter : this.state.filter;
        if (!currentFilter)
            return;

        const updatedFilter = filter ? filter : this.state.filter;
        if (!updatedFilter.equals(currentFilter))
            return;

        if(!currentFilter.isValid())
            return;

        const models = await this._fetchModels(currentFilter, page);
        dispatcher.dispatch(setPagePayload(this.storeId, models, currentFilter, this.state.selectionModel));
        dispatcher.dispatch(listIsLoadingPayload(this.storeId, false));
    }

    private filterNotChanged(filter?: F) {
        if (filter)
            return filter.equals(this.store.getState().filter as F);
        else
            return new NullFilter().equals(this.store.getState().filter);
    }

    private _pageNotChanged(page?: Pageable) {
        if (page)
            return page.equals(this.store.getState().page.pageable);
        else
            return true;
    }
}

// TODO: remove it. Functions which used it, should be a proper promise
export function promiseWorkaround() {
    return new Promise(resolve => setTimeout(resolve, 1));
}