import SegmentListFilter from "../../flux/segment/list/SegmentListFilter";
import CatFileEditorView from "../../components/cat-file-editor/CatFileEditorView";

export default function CatFileEditorRoute(){
    return <CatFileEditorView/>;
}

export function getSegmentPath(filter: SegmentListFilter, position: number | null, withFilter: boolean = true) {
    return CatFileEditorPath
            .replaceAll(":projectId", String(filter.projectId))
            .replaceAll(":fileId", String(filter.nonNullCatFile.id))
            .replaceAll(":language", filter.nonNullLanguage)
            .replaceAll(":position", position !== null ? String(position) : "null")
        + (withFilter ? "?" + filter.toSearchParams() : "");
}

// TODO: Get rid of (:position = null) in url
export const CatFileEditorPath = "/editor/project/:projectId/file/:fileId/language/:language/" +
    "position/:position";
export const CatFileEditorId = "CatFileEditorRoute";