import {UserSetting} from "../../model/UserSetting";

export function toModel(response: IUserSettingResponse) {
    return new UserSetting({
        key: response.key,
        data: response.data ?? ""
    })
}

export interface IUserSettingResponse {
    key: string
    data: string
}