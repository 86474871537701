import Immutable, {List} from "immutable";
import {Language} from "../../../model/Language";
import {WorkflowStepModel} from "../../../model/WorkflowStepModel";

export enum ProjectEditorAction {
    SetIsUploadFilesOpen = "ProjectEditorAction.SetIsUploadFilesOpen",
    SetIsUpdateTranslationsOpen = "ProjectEditorAction.SetIsUpdateTranslationsOpen",
    SetFiles = "ProjectEditorAction.SetFiles",
    SetStep = "ProjectEditorAction.SetStep",
    SetMultiLanguageMode = "ProjectEditorAction.SetMultiLanguageMode",
    SetProjectName = "ProjectEditorAction.SetProjectName",
    SetSourceLanguage = "ProjectEditorAction.SetSourceLanguage",
    SetTargetLanguages = "ProjectEditorAction.SetTargetLanguages",
    SetWorkflowType = "ProjectEditorAction.SetWorkflowType",
    SetWorkflowSteps = "ProjectEditorAction.SetWorkflowSteps",
    SetWorkflowEditorOpen = "ProjectEditorAction.SetWorkflowEditorOpen"
}

interface ISetFilesPayload {
    action: ProjectEditorAction.SetFiles,
    files: Immutable.List<File>
}

interface ISetStep {
    action: ProjectEditorAction.SetStep,
    step: number
}

interface ISetIsUploadFilesOpen {
    action: ProjectEditorAction.SetIsUploadFilesOpen,
    uploadFilesOpen: boolean
}

interface ISetIsUpdateTranslationsOpen {
    action: ProjectEditorAction.SetIsUpdateTranslationsOpen,
    updateTranslationsOpen: boolean
}

interface ISetMultiLanguageMode {
    action: ProjectEditorAction.SetMultiLanguageMode,
    multiLanguageMode: boolean
}

interface ISetProjectName {
    action: ProjectEditorAction.SetProjectName,
    projectName: string
}

interface ISetSourceLanguage {
    action: ProjectEditorAction.SetSourceLanguage,
    sourceLanguage: Language | null
}

interface ISetTargetLanguages {
    action: ProjectEditorAction.SetTargetLanguages,
    targetLanguages: List<Language>
}

interface ISetWorkflowType {
    action: ProjectEditorAction.SetWorkflowType,
    workflowType: string
}

interface ISetWorkflowSteps {
    action: ProjectEditorAction.SetWorkflowSteps,
    workflowSteps: WorkflowStepModel | null
}

interface ISetWorkflowEditorOpen {
    action: ProjectEditorAction.SetWorkflowEditorOpen,
    workflowEditorOpen: boolean
}

export type ProjectEditorPayload = ISetFilesPayload |
    ISetStep |
    ISetIsUploadFilesOpen |
    ISetIsUpdateTranslationsOpen |
    ISetMultiLanguageMode |
    ISetProjectName |
    ISetSourceLanguage |
    ISetTargetLanguages |
    ISetWorkflowType |
    ISetWorkflowSteps |
    ISetWorkflowEditorOpen;

export function setProjectFiles(files: Immutable.List<File>): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetFiles,
        files: files
    }
}

export function setStep(step: number): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetStep,
        step: step
    }
}

export function setIsUploadFilesOpen(uploadFilesOpen: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetIsUploadFilesOpen,
        uploadFilesOpen: uploadFilesOpen
    }
}

export function setIsUpdateTranslationsOpen(updateTranslationsOpen: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetIsUpdateTranslationsOpen,
        updateTranslationsOpen: updateTranslationsOpen
    }
}

export function setMultiLanguageMode(multiLanguage: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetMultiLanguageMode,
        multiLanguageMode: multiLanguage
    }
}

export function setProjectName(projectName: string): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetProjectName,
        projectName: projectName
    }
}

export function setSourceLanguage(sourceLanguage: Language | null): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetSourceLanguage,
        sourceLanguage: sourceLanguage
    }
}

export function setTargetLanguages(targetLanguages: List<Language>): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetTargetLanguages,
        targetLanguages: targetLanguages
    }
}

export function setWorkflowType(workflowType: string): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetWorkflowType,
        workflowType: workflowType
    }
}

export function setWorkflowSteps(workflowSteps: WorkflowStepModel | null): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetWorkflowSteps,
        workflowSteps: workflowSteps
    }
}

export function setWorkflowEditorOpen(workflowEditorOpen: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetWorkflowEditorOpen,
        workflowEditorOpen: workflowEditorOpen
    }
}
