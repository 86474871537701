import {UserGroup} from "../../model/UserGroup";
import {List} from "immutable";

export enum UserGroupsAction {
    SetUserGroup = "UserGroupsAction.SetUserGroup"
}

interface ISetUserGroups {
    action: UserGroupsAction.SetUserGroup,
    groups: List<UserGroup>
}

export type UserGroupsPayload = ISetUserGroups;

export function setUserGroups(roles: List<UserGroup>): UserGroupsPayload {
    return {
        action: UserGroupsAction.SetUserGroup,
        groups: roles
    }
}

