import {Box, Checkbox, InputLabel, ListItemText, MenuItem, OutlinedInput, SelectChangeEvent} from "@mui/material";
import {GridFilterInputValueProps, GridFilterItem} from "@mui/x-data-grid";
import {CustomBox, CustomChipM1, CustomFormControl, StyledSelect} from "../../globals/CommonComponents";
import {List} from "immutable";
import {UserGroup} from "../../model/UserGroup";
import userGroupsStore from "../../flux/user-roles/UserGroupsStore";
import {useEffect, useState} from "react";

export default function SelectGroup(props: GridFilterInputValueProps) {
    const filterValue = props.item.value ? props.item.value as List<string> : undefined;
    const [roles, setRoles] = useState(userGroupsStore.getState().groups);

    useEffect(() => {
        const userGroupsListener = userGroupsStore.addListener(() => {
            setRoles(userGroupsStore.getState().groups);
        });

        return () => userGroupsListener.remove();
    })

    return <Box>
        <CustomFormControl>
            <InputLabel id={"select-group-label"}>Filter value</InputLabel>
            <StyledSelect labelId={"select-group-label"} multiple value={filterValue ? filterValue.toArray() : []}
                          onChange={e =>
                              handleSelectChanged(e as SelectChangeEvent<string[]>, props.item, props.applyValue)}
                          input={<OutlinedInput label={"Value"}/>}
                          renderValue={selected => drawFilterElement(selected as string[], roles)}>
                {roles.map(role => <MenuItem value={role.id} key={"select-group-" + role}>
                    <Checkbox checked={filterValue ? filterValue.includes(role.id) : false}/>
                    <ListItemText primary={role.name}/>
                </MenuItem>)}
            </StyledSelect>
        </CustomFormControl>
    </Box>;
}

function drawFilterElement(selected: string[], roles: List<UserGroup>) {
    return <CustomBox>{selected.map(value => drawFilterElementItem(roles, value))}</CustomBox>;
}

function drawFilterElementItem(roles: List<UserGroup>, value: string) {
    const foundRole = roles.find(r => value === r.id);
    if (!foundRole)
        return null;
    return <CustomChipM1 label={foundRole.name} variant={"outlined"}
                         key={"selected-cat-file-status-" + value} size={"small"}/>;
}

function handleSelectChanged(e: SelectChangeEvent<string[]>,
                             item: GridFilterItem,
                             applyValue: (value: GridFilterItem) => void) {
    const selectValue = e.target.value;
    const updatedValue = List(typeof (selectValue) === 'string' ? selectValue.split(',') : selectValue);
    applyValue({...item, value: updatedValue.isEmpty() ? undefined : updatedValue});
}