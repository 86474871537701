import {ConcordanceSearchResult} from "../../model/ConcordanceSearch";
import {Page} from "../../model/Page";
import {ConcordanceSearchType} from "./ConcordanceSearchStore";

export enum ConcordanceSearchPayloadAction {
    SetConcordanceSearchResults = "ConcordanceSearchPayloadAction.SetConcordanceSearchResults",
    SetConcordanceSearchType = "ConcordanceSearchPayloadAction.SetConcordanceSearchType",
    SetConcordanceSearchText = "ConcordanceSearchPayloadAction.SetConcordanceSearchText"
}

interface ISetConcordanceSearchResultsPayload {
    action: ConcordanceSearchPayloadAction.SetConcordanceSearchResults,
    page: Page<ConcordanceSearchResult>
}

interface ISetConcordanceSearchTypePayload {
    action: ConcordanceSearchPayloadAction.SetConcordanceSearchType,
    type: ConcordanceSearchType
}

interface ISetConcordanceSearchTextPayload {
    action: ConcordanceSearchPayloadAction.SetConcordanceSearchText,
    text: string
}

export type ConcordanceSearchPayload = ISetConcordanceSearchResultsPayload
    | ISetConcordanceSearchTypePayload
    | ISetConcordanceSearchTextPayload;

export function setConcordanceSearchResults(page: Page<ConcordanceSearchResult>)
    : ConcordanceSearchPayload {
    return {
        action: ConcordanceSearchPayloadAction.SetConcordanceSearchResults,
        page: page
    }
}

export function setConcordanceSearchType(type: ConcordanceSearchType): ConcordanceSearchPayload {
    return {
        action: ConcordanceSearchPayloadAction.SetConcordanceSearchType,
        type: type
    }
}

export function setConcordanceSearchText(text: string): ConcordanceSearchPayload {
    return {
        action: ConcordanceSearchPayloadAction.SetConcordanceSearchText,
        text: text
    }
}
