import {Container, Paper, Stack} from "@mui/material";
import {InfoContainer} from "../../globals/CommonComponents";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import translationMemorySegmentEditorStore
    from "../../flux/translation-memory-segment/editor/TranslationMemorySegmentEditorStore";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";

export default function BottomFrame() {
    const editorState = translationMemorySegmentEditorStore.getState();

    const [filter, setFilter]
        = useState<TranslationMemorySegmentFilter | null>(translationMemorySegmentListActions.state.filter);
    const [segment, setSegment] = useState(editorState.segment);

    useEffect(() => {
        const editorListener = translationMemorySegmentEditorStore.addListener(() => {
            const state = translationMemorySegmentEditorStore.getState();
            setSegment(state.segment);
        });

        const segmentListListener = translationMemorySegmentListActions.addListener(() => {
            setFilter(translationMemorySegmentListActions.state.filter);
        });

        return () => {
            editorListener.remove();
            segmentListListener.remove();
        }
    });

    return <Stack sx={{height: '100%', padding: 0, maxWidth: '500px'}}>
        <Container disableGutters>
            <InfoContainer disableGutters sx={{ px: 1 }}>
                <Stack marginY={1} spacing={1}>
                    <Paper>
                        <Stack spacing={1} padding={1}>
                            {filter && drawProperty("Name", filter.translationMemory.name)}
                            {segment.editorName !== "" && drawProperty("Editor", segment.editorName)}
                            {segment.lastEditDateTime
                                && drawProperty("Changed", segment.lastEditDateTime.toLocaleString())}
                        </Stack>
                    </Paper>
                </Stack>
            </InfoContainer>
        </Container>
    </Stack>;
}

function drawProperty(header: string, value: string)
{
    return <Typography fontSize={12} sx={{ wordWrap: 'break-word' }}>
        <strong>{header + ": "}</strong>
        {value}
    </Typography>
}