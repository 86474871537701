import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Button,
    Chip,
    Dialog,
    DialogTitle, FormControlLabel,
    Stack, Switch,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {adminUserListActions} from "../../flux/users/select/AdminUserListActions";
import {Tenant} from "../../model/Tenant";
import {tenantListActions} from "../../flux/tenant/TenantListActions";
import {User} from "../../model/User";
import TextFieldSelect from "../common/TextFieldSelect";
import {List} from "immutable";
import {UserGroup} from "../../model/UserGroup";
import userGroupsStore from "../../flux/user-roles/UserGroupsStore";

interface IProps {
    user: User,
    open: boolean,
    onClose: () => void
}

export function UserEditor(props: IProps) {
    const [tenant, setTenant] = useState<Tenant>(props.user.tenant);
    const [groups, setGroups] = useState<List<UserGroup>>(props.user.groups);
    const [enabled, setEnabled] = useState<boolean>(props.user.enabled);
    const [existedRoles, setExistedRoles] = useState(userGroupsStore.getState().groups);

    useEffect(() => {
        const userGroupsListener = userGroupsStore.addListener(() => {
            setExistedRoles(userGroupsStore.getState().groups);
        });

        return () => userGroupsListener.remove();
    });

    useEffect(() => {
        setTenant(props.user.tenant);
        setGroups(props.user.groups);
        setEnabled(props.user.enabled);
    }, [props.user]);

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>{"User " + props.user.name}</DialogTitle>
            <Stack direction={"row"} justifyContent={"flex-end"}>
                <FormControlLabel control={<Switch
                    defaultChecked={props.user.enabled}
                    onChange={(_e, checked) => setEnabled(checked)}/>}
                                  label={enabled ? "Active" : "Blocked"}/>
            </Stack>
            <Stack direction={"column"} alignItems={"center"} spacing={2} padding={2}>
                <TextFieldSelect onSelect={selected => setTenant(selected)} label={"Tenant"}
                                 getPresentation={model => model.name} actions={tenantListActions}
                                 value={tenant}/>
                <Autocomplete fullWidth multiple options={existedRoles.toArray()}
                              renderInput={params =>
                                  <TextField {...params} label={"Role"} variant={"standard"} value={groups}/>}
                              renderOption={(props,
                                             option) =>
                                  <li {...props} key={"role-opt-" + option}>{option.name}</li>}
                              renderTags={renderTags}
                              onChange={(_e, value) =>
                                  setGroups(List(value))}
                              value={groups.toArray()}/>

                <Button fullWidth={true} variant={"contained"}
                        onClick={() => onSave(tenant, groups, enabled, props)}>
                    Save
                </Button>
            </Stack>
        </Dialog>
    );
}

function onSave(tenant: Tenant, roles: List<UserGroup>, enabled: boolean, props: IProps) {
    adminUserListActions.saveAll(List.of(props.user
        .set("tenant", tenant)
        .set("groups", roles)
        .set("enabled", enabled)));
    props.onClose();
}

function renderTags(selected: UserGroup[], getTagProps: AutocompleteRenderGetTagProps) {
    return selected.map((value, index) => (
        <Chip {...getTagProps({index})} size={"small"} key={"target-" + value.id} label={value.name}/>
    ));
}