import React, {useState} from "react";
import {Autocomplete, Button, Stack, TextField} from "@mui/material";
import pretranslateModes, {PretranslateMode} from "../../model/PretranslateMode";
import WorkflowStep from "../../model/WorkflowStep";
import TranslationMemory from "../../model/TranslationMemory";
import {PretranslateSetting} from "../../model/PretranslateSetting";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import {
    addPretranslateSettingAction,
    removePretranslateSettingAction,
    setPretranslateSettingAction
} from "../../flux/pretranslate/PretranslateActions";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import pretranslateStore from "../../flux/pretranslate/PretranslateStore";
import {catFileSelectActions, CatFileSelectActions} from "../../flux/cat-files/select/CatFileSelectActions";
import SelectDialog, {headerWithSelectAll} from "../common/SelectDialog";
import {getGridStringOperators, GridColDef} from "@mui/x-data-grid";
import {GridRowStringOperatorsDefault} from "../../globals/Constants";
import {ReactSetter} from "../../globals/Types";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import Project from "../../model/Project";
import {SelectionModel} from "../../model/SelectionModel";
import {Language} from "../../model/Language";
import {handleTargetRendered} from "../../utils/LanguageUtils";
import Immutable from "immutable";

interface IProps {
    selection: SelectionModel,
    project: Project,
    setting: PretranslateSetting,
    step: number
}

export default function PretranslateSettingView(props: IProps) {
    const [isOpenCatFiles, setIsOpenCatFiles] = useState(false)
    const tmList = props.project.translationMemories;
    const workflow = props.project.getWorkflowSteps();

    return (
        <Stack spacing={2}>
            <SelectDialog open={isOpenCatFiles}
                          onClose={() => setIsOpenCatFiles(false)}
                          onSelect={async () => {
                              await setPretranslateSettingAction(props.setting.set("catFilesSelection", catFileSelectActions.selection), props.step)
                          }}
                          actions={catFileSelectActions}
                          columns={catFileColumns(catFileSelectActions)}
                          navigateProps={null}
                          useNewSelectionModel={true}/>
            <Stack direction={"row"}>
                <IconButton onClick={async () => await addPretranslateSettingAction()}>
                    <AddIcon/>
                </IconButton>
                {drawDeleteButton(props.step)}
                {drawFilesFilter(props, setIsOpenCatFiles)}
            </Stack>
            <Autocomplete options={tmList.toArray()}
                          getOptionLabel={(option: TranslationMemory) => option.name}
                          renderInput={(params) => drawRenderInput(params, "Translation Memory")}
                          value={props.setting.translationMemory}
                          onChange={async (_, value) =>
                              await setPretranslateSettingAction(props.setting.set("translationMemory", value), props.step)}/>
            <Stack direction={"row"} spacing={5}>
                <Autocomplete options={pretranslateModes}
                              fullWidth={true}
                              getOptionLabel={(option: PretranslateMode) => option.presentation}
                              renderInput={(params) => drawRenderInput(params, "Pretranslate mode")}
                              value={props.setting.mode}
                              onChange={async (_, value) =>
                                  await setPretranslateSettingAction(props.setting.set("mode", value), props.step)}/>
                <Autocomplete options={workflow.toArray()}
                              fullWidth={true}
                              getOptionLabel={(option: WorkflowStep) => option.name}
                              renderInput={(params) => drawRenderInput(params, "Workflow")}
                              value={props.setting.workflowStep}
                              onChange={async (_, value) =>
                                  await setPretranslateSettingAction(props.setting.set("workflowStep", value), props.step)}/>
                <Autocomplete multiple
                              fullWidth={true}
                              size={"medium"}
                              options={props.project.targets.toArray()}
                              renderInput={(params) =>
                                  <TextField {...params} label={"Target"} variant={"standard"}
                                             value={props.setting.targetLanguages}/>}
                              renderTags={handleTargetRendered}
                              getOptionLabel={(option: Language) => option.name}
                              value={props.setting.targetLanguages?.toArray()}
                              onChange={async (_e, value) =>
                                  await setPretranslateSettingAction(props.setting.set("targetLanguages", Immutable.List(value)), props.step)}
                />
                <Autocomplete fullWidth={true} options={Array.from({length: 11}, (_, i) => String(i))}
                              renderInput={(params) => drawRenderInput(params, "Minimal amount of words in segment")}
                              value={String(props.setting.minimalWordsAmount)}
                              onChange={async (_, value) => {
                                  const parsed = value === null ? 0 : Number.parseInt(value);
                                  await setPretranslateSettingAction(props.setting.set("minimalWordsAmount", parsed), props.step)
                              }}/>
            </Stack>
        </Stack>

    );
}

function drawFilesFilter(props: IProps, setIsOpenCatFiles: ReactSetter<boolean>) {
    const selectedFiles = props.selection.countSelected(catFileSelectActions.state.page.totalElements);
    const selectedFilesText = selectedFiles == 0 ? "" : `( ${selectedFiles} selected )`
    return (
        <Button onClick={async () => {
            setIsOpenCatFiles(true);
            await catFileSelectActions.fetch(new CatFilesFilter({projectId: props.project.id}));
            catFileSelectActions.setSelectionModel(props.selection);
        }}>
            {`Files filter ${selectedFilesText}`}
        </Button>
    )
}

function drawDeleteButton(step: number) {
    if (pretranslateStore.getState().settings.size == 1)
        return null;

    return (
        <IconButton onClick={async () => await removePretranslateSettingAction(step)}>
            <DeleteIcon/>
        </IconButton>
    )
}

function drawRenderInput(params: AutocompleteRenderInputParams, label: string) {
    return <TextField {...params} label={label} variant={"standard"}/>
}

function catFileColumns(actions: CatFileSelectActions): GridColDef[] {
    return [
        {
            field: 'name',
            renderHeader: headerWithSelectAll(actions),
            headerName: 'Name',
            width: 500,
            sortable: false,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        }
    ];
}