import Immutable, {List} from "immutable";
import {WorkflowStepModel} from "./WorkflowStepModel";
import WorkflowStep from "./WorkflowStep";
import {Language} from "./Language";
import TranslationMemory, {ProjectTranslationMemory} from "./TranslationMemory";

interface IProject {
    id: number,
    name: string,
    source: Language,
    targets: List<Language>,
    translationMemories: List<TranslationMemory>,
    writableTm: ProjectTranslationMemory | null,
    glossaries: List<number>,
    placeholdersSetId: number | null,
    refuseSaveSegmentsWithPlaceholderErrors: boolean,
    workflow: List<WorkflowStepModel>
}

const ProjectRecord = Immutable.Record<IProject>({
    id: 0,
    name: '',
    source: Language.Empty,
    targets: List(),
    workflow: List(),
    translationMemories: List(),
    writableTm: null,
    glossaries: List(),
    placeholdersSetId: null,
    refuseSaveSegmentsWithPlaceholderErrors: false
})

export default class Project extends ProjectRecord {
    public getWorkflowSteps() : List<WorkflowStep> {
        return this.workflow.map(value => new WorkflowStep({
            id: value.id,
            name: value.name
        }))
    }

    public getWorkflowStepById(id: number) : WorkflowStep | undefined {
        return this.getWorkflowSteps().find(step => step.id === id);
    }

    public getFirstStep(): WorkflowStepModel {
        return this.workflow.first();
    }
}