import {Slate} from "slate-react";
import React from "react";
import {TranslationMemorySegment} from "../../model/TranslationMemorySegment";
import {RenderedSourceElement} from "../cat-file-editor/frames/segments/rendering/RenderedSourceElement";
import {RenderedText} from "../cat-file-editor/frames/segments/rendering/RenderedText";
import {CustomEditable} from "../../utils/slate/SlateUtils";
import {SegmentCell, SegmentsTableRow} from "../../globals/CommonComponents";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";

type FoundTranslationMemorySegmentViewProperties = {
    segment: TranslationMemorySegment,
    style: React.CSSProperties
}

export default function FoundTranslationMemorySegmentView(props: FoundTranslationMemorySegmentViewProperties) {
    return <SegmentsTableRow isEditing={false} isLocked={false}
                             style={{...props.style, ...{wordBreak: 'break-all'}}} direction={"row"}>
        <SegmentCell style={{flexGrow: 1}}>
            <Slate editor={props.segment.sourceEditor} initialValue={props.segment.sourceEditor.children}>
                <CustomEditable readOnly={true}
                                renderElement={renderProps =>
                                    <RenderedSourceElement children={renderProps.children}
                                                           element={renderProps.element}
                                                           attributes={renderProps.attributes}
                                                           onPlaceholder={() => {
                                                           }}
                                                           id={props.segment.id}/>}
                                renderLeaf={(props) =>
                                    <RenderedText children={props.children} attributes={props.attributes}
                                                  leaf={props.leaf} text={props.text}/>}/>
                <Typography style={{marginTop: '0px', paddingTop: '0px', lineHeight: 1.5}} fontSize={12}
                            color={"#97A0AF"}>{props.segment.sourceId}</Typography>
            </Slate>
        </SegmentCell>
        <SegmentCell style={{flexGrow: 1}}>
            <Slate editor={props.segment.targetEditor} initialValue={props.segment.targetEditor.children}>
                <CustomEditable readOnly={true}
                                renderElement={renderProps =>
                                    <RenderedSourceElement children={renderProps.children}
                                                           element={renderProps.element}
                                                           attributes={renderProps.attributes}
                                                           onPlaceholder={() => {
                                                           }}
                                                           id={props.segment.id}/>}
                                renderLeaf={(props) =>
                                    <RenderedText children={props.children} attributes={props.attributes}
                                                  leaf={props.leaf} text={props.text}/>}/>
            </Slate>
        </SegmentCell>
        <SegmentCell style={{flexGrow: 1}}>
            <Stack>
                <Typography>{props.segment.translationMemory.name}</Typography>
                <Typography style={{marginTop: '0px', paddingTop: '0px', lineHeight: 1.5}} fontSize={12}
                            color={"#97A0AF"}>
                    {props.segment.language}
                </Typography>
            </Stack>
        </SegmentCell>
    </SegmentsTableRow>;
}