import React from "react";
import {Box, Button, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {cancelPretranslateAction} from "../../flux/pretranslate/PretranslateActions";
import {PretranslateStatus} from "../../model/PretranslateStatus";

interface IProps {
    status: PretranslateStatus
}

export default function PretranslateInProgressView(props: IProps) {
    if (props.status.isCanceling)
        return <CircularProgress/>

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" value={props.status.percent}/>
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography
                    variant="body2"
                    sx={{color: 'text.secondary'}}
                >{`${props.status.percent}%`}</Typography>
            </Box>
            <Button onClick={async () => await cancelPretranslateAction()}>
                Cancel
            </Button>
        </Box>
    );
}
