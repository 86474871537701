import axios, {AxiosResponse} from "axios";
import {IUserGroupResponse, toUserGroupModel} from "./model/UserRoleApiModel";
import {List} from "immutable";

export function listGroups() {
    return axios
        .get(endpoint)
        .then((response: AxiosResponse<IUserGroupResponse[]>) => {
            return List(response.data.map(role => toUserGroupModel(role)));
        });
}

const endpoint = "/api/common/userGroups"