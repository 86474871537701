import React, {useEffect, useRef, useState} from "react";
import {hasModel} from "../../../../../globals/Utils";
import {CircularProgress, Menu, MenuItem, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import {CatFile} from "../../../../../model/CatFile";
import {selectedCatFilesBuildActions} from "../../../../../flux/common/build/SelectedCatFilesBuildActions";
import {catFileBuildActions} from "../../../../../flux/common/build/CatFileBuildActions";
import SegmentListFilter from "../../../../../flux/segment/list/SegmentListFilter";

type DownloadFileActionProps = {
    catFile: CatFile
}

export default function DownloadFileAction(props: DownloadFileActionProps) {
    const [isOpen,setIsOpen] = useState(false);
    const downloadButtonRef = useRef<HTMLButtonElement | null>(null);

    const buildState = selectedCatFilesBuildActions.state;
    const [buildingFiles, setBuildingFiles]
        = useState(buildState.building);

    useEffect(() => {
        const buildStoreListener = selectedCatFilesBuildActions.addListener(() => {
            const state = selectedCatFilesBuildActions.state;
            setBuildingFiles(state.building);
        });

        return () => buildStoreListener.remove();
    }, []);

    const buildCatFile = (isTranslationMode: boolean) => {
        catFileBuildActions.build(new SegmentListFilter({
            projectId: props.catFile.projectId,
            catFile: props.catFile,
            language: props.catFile.language.id
        }), isTranslationMode);
        setIsOpen(false);
    };

    const handleSaveXliffClicked = () => {
        buildCatFile(true);
    };

    const handleSaveTranslationClicked = () => {
        buildCatFile(false);
    };

    if (hasModel(props.catFile, buildingFiles))
        return <CircularProgress size={20}/>;
    else {
        return <Box>
            <GridActionsCellItem label={'Download'} icon={<Tooltip title={"Download"} arrow><DownloadIcon/></Tooltip>}
                                 ref={downloadButtonRef}
                                 onClick={() => setIsOpen(true)}/>
            <Menu open={isOpen} onClose={() => setIsOpen(false)} anchorEl={downloadButtonRef.current}>
                <MenuItem onClick={handleSaveTranslationClicked}>Download translation</MenuItem>
                <MenuItem onClick={handleSaveXliffClicked}>Download xliff</MenuItem>
            </Menu>
        </Box>;
    }
}