import {dispatcher} from "../Dispatcher";
import {setNotificationList} from "./NotificationPayload";
import {deleteAllNotifications, deleteNotification, fetchNotifications} from "../../api/NotificationsApi";
import NotificationModel from "../../model/NotificationModel";

export async function getNotificationListAction() {
    const notifications = await fetchNotifications();
    dispatcher.dispatch(setNotificationList(notifications));
}

export async function deleteNotificationAction(notification: NotificationModel) {
    await deleteNotification(notification);
    await getNotificationListAction();
}

export async function deleteAllNotificationsAction() {
    await deleteAllNotifications();
    await getNotificationListAction();
}