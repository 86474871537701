import {Model} from "../../model/common/IModel";
import ListView, {ISelectViewProps} from "./ListView";
import {Button, Dialog, Stack} from "@mui/material";
import React from "react";
import {Set} from "immutable";
import {IFilter} from "../../model/common/Filter";
import Box from "@mui/material/Box";
import {CommonListActions} from "../../flux/common/list/CommonListActions";

interface IProps<M extends Model, F extends IFilter<F>> extends ISelectViewProps<M, F> {
    open: boolean,
    onClose: () => void,
    onSelect: (selected: Set<M>) => void // deprecated
}

export default function SelectDialog<M extends Model, F extends IFilter<F>>(props: IProps<M, F>) {

    return (
        <Dialog open={props.open} onClose={props.onClose}
                slotProps={{paper: {sx: { width: '80vw', maxWidth: '700px' }}}}>
            <ListView {...props}/>
            <Button variant={"contained"}
                    onClick={() => {
                        props.onSelect(props.actions.selected);
                        props.actions.select(Set<M>());
                        props.onClose();
                    }}>
                Save
            </Button>
        </Dialog>
    )
}

export function headerWithSelectAll<M extends Model, F extends IFilter<F>>(actions: CommonListActions<M, F>) {
    return () => (
        <Stack direction={"row"}>
            File
            <Box display="flex" alignItems="center" marginLeft="8px">
                {actions.selection.countSelected(actions.state.page.totalElements)}
            </Box>
            <Button variant={"text"}
                    onClick={() => {
                        actions.toggleSelectAllAction()
                    }}>
                {`${actions.selection.isSelectAll ? "Clear" : "Select"} All `}
            </Button>
        </Stack>
    );
}
