export const editorStyle = `
    .vertical-panes {
        height: 100%; 
        width: 100%; 
        display: flex;
    }
    .left-pane {
        height:100%;
        flex-grow: 1;
    }
    .right-pane {
        background-color: #FAFBFC;
        height:100%;
        flex-shrink: 0; 
        min-width:350px;
    }
    .left-horizontal-panes {
        width: 100%;
        height:100%;
    }
    .left-top-pane {
        height:100%;
        flex:100%;
    }
    .left-bottom-pane {
        height:0;
    }
    .right-filter-pane {
        height: 100%; 
    }
    .right-bottom-pane {
        height: 0;
    }
    .right-middle-pane {
        height: 100%; 
        flex:100%;
    }
    .resizer {
        background-color: #a69fa0;
        z-index:100;
    },
`;