import {useEffect} from "react";
import alertStore from "../flux/alert/AlertStore";
import {enqueueSnackbar} from 'notistack';
import Alert from "../model/Alert";

export default function AlertView() {

    useEffect(() => {
        const requestListener = alertStore.addListener(() => {
            const state = alertStore.getState();
            state.alertList.forEach(alert => enqueue(alert));
        });
        return () => requestListener.remove();
    });

    return null;
}

function enqueue(alert: Alert) {
    enqueueSnackbar(alert.message, {
            variant: alert.severity,
            preventDuplicate: true,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: "top",
                horizontal: "center"
            }
        }
    );
}