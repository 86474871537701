import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import {Badge, Button, Menu, MenuItem} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationModel from "../model/NotificationModel";
import {
    deleteAllNotificationsAction,
    deleteNotificationAction
} from "../flux/notification/NotificationActions";
import Immutable from "immutable";

interface NotificationsMenuProps {
    open: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    notifications: Immutable.List<NotificationModel>;
}

export default function NotificationsMenu(props: NotificationsMenuProps){
    return (
        <>
            <IconButton aria-label="notifications" color="inherit" id="notifications-button"
                        aria-controls={props.open ? 'notifications-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={props.open ? 'true' : undefined}
                        onClick={props.handleClick}>
                <Badge badgeContent={props.notifications.size} color="secondary">
                    <NotificationsIcon/>
                </Badge>
            </IconButton>
            <Menu
                id="notifications-menu"
                anchorEl={props.anchorEl}
                open={props.open}
                onClose={props.handleClose}
            >
                <Button
                    aria-label="clear-all-notifications-button"
                    id="clear-all-notifications-button"
                    endIcon={<CleaningServicesIcon/>}
                    sx={{display: "flex", justifyContent: "center", mx: "auto"}}
                    onClick={() => {
                        handleDeleteNotifications(props.handleClose)
                    }}
                >
                    Mark all notifications as read
                </Button>
                {getMenuItems(props.notifications)}
            </Menu>
        </>
    );
};

function getMenuItems(items: Immutable.List<NotificationModel>) {
    return items.map((notification, index) => (
        <MenuItem
            key={index}
            disableRipple
            sx={{
                pointerEvents: "none",
                display: "flex",
                justifyContent: "space-between",
                "&:hover": {backgroundColor: "transparent"},
                "&.Mui-focusVisible": {backgroundColor: "transparent"},
                "&.Mui-selected": {backgroundColor: "transparent"},
            }}
        >
            {notification.title}
            <IconButton
                aria-label={`clear-notification-${index}`}
                onClick={async () => handleDeleteNotification(notification)}
                size="small"
                sx={{
                    pointerEvents: "auto",
                    zIndex: 1
                }}
            >
                <ClearIcon fontSize="small"/>
            </IconButton>
        </MenuItem>
    ));
}

async function handleDeleteNotification(notification: NotificationModel) {
    await deleteNotificationAction(notification)
}

async function handleDeleteNotifications(handleClose: () => void) {
    handleClose();
    await deleteAllNotificationsAction();
}