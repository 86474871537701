import {SelectionModel} from "../../model/SelectionModel";
import {PretranslateSetting} from "../../model/PretranslateSetting";
import {PretranslateStatus} from "../../model/PretranslateStatus";

export function fromModel(setting: PretranslateSetting): IPretranslateSettingRequest {
    const isEmptySelection = setting.catFilesSelection.countSelected(1) == 0;
    const selection = isEmptySelection ? SelectionModel.allSelected() : setting.catFilesSelection;
    const targetLanguages = setting.targetLanguages == null ? null : setting.targetLanguages.map(l => l.id).toArray();

    return {
        minimalWordsAmount: setting.minimalWordsAmount,
        mode: setting.mode!.mode,
        catFilesSelection: selection,
        translationMemoryId: setting.translationMemory!.id,
        workflowStepId: setting.workflowStep?.id ?? null,
        targetLanguages: targetLanguages,
    }
}

export interface IPretranslateSettingRequest {
    mode: string,
    translationMemoryId: string,
    workflowStepId: number | null,
    minimalWordsAmount: number,
    catFilesSelection: SelectionModel,
    targetLanguages: string[] | null
}

export function toPretranslateStatusModel(response: IPretranslateStatus) {
    return new PretranslateStatus(response);
}

export interface IPretranslateStatus {
    total: number,
    competed: number,
    isCanceling: boolean
}