import {
    Box,
    Checkbox,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    SelectChangeEvent
} from "@mui/material";
import {useState} from "react";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import Project from "../../../../model/Project";
import {List} from "immutable";
import {GridFilterInputValueProps, GridFilterItem} from "@mui/x-data-grid";
import {CustomBox, CustomChipM1, CustomFormControl, StyledSelect} from "../../../../globals/CommonComponents";

export default function SelectCatFileStatus(props: GridFilterInputValueProps) {
    const filterValue = props.item.value ? props.item.value as List<number> : undefined;
    const initialProjectState = projectPageStore.getState();

    const [project] = useState<Project>(initialProjectState.project);

    return <Box>
        <CustomFormControl>
            <InputLabel id={"select-cat-file-status-label"}>Value</InputLabel>
            <StyledSelect labelId={"select-cat-file-status-label"} multiple
                    value={filterValue ? filterValue.toArray() : []}
                    onChange={e =>
                        handleSelectChanged(e as SelectChangeEvent<number[]>, props.item, props.applyValue)}
                    input={<OutlinedInput label={"Value"}/>}
                    renderValue={selected => drawFilterElement(project, selected as number[])}>
                {project.getWorkflowSteps().map(step => <MenuItem value={step.id}
                                                                  key={"select-cat-file-status-" + step.id}>
                    <Checkbox checked={filterValue ? filterValue.includes(step.id) : false}/>
                    <ListItemText primary={step.name}/>
                </MenuItem>)}
            </StyledSelect>
        </CustomFormControl>
    </Box>
}

function drawFilterElement(project: Project, selected: number[]) {
    return <CustomBox>
        {selected.map(value => <CustomChipM1
            label={project.getWorkflowStepById(value)?.name}
            variant={"outlined"}
            key={"selected-cat-file-status-" + value} size={"small"}/>)}
    </CustomBox>;
}

function handleSelectChanged(e: SelectChangeEvent<number[]>,
                                    item: GridFilterItem,
                                    applyValue: (value: GridFilterItem) => void) {
    const selectValue = e.target.value;
    const updatedValue = List(typeof (selectValue) === 'string'
        ? selectValue.split(',').map(value => Number(value))
        : selectValue);
    applyValue({...item, value: updatedValue.isEmpty() ? undefined : updatedValue});
}