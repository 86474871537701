import {IBackendPage} from "../model/Page";
import GroupedListItem from "../model/GropedListItem";
import {List} from "immutable";
import {IModel} from "../model/common/IModel";

enum GroupElementType  {
    Elements = "Elements",
    Group = "Group"
}

export type EmbeddedResponse<T> = {
    _embedded: T
};

export interface EmbeddedResponseWithPagination<T> extends EmbeddedResponse<T> {
    page: IBackendPage
}

export interface IGroupedListResponse<T> extends EmbeddedResponseWithPagination<IGroupedListResponse<T>[]> {
    type: GroupElementType,
    name: string | undefined,
    value: string | undefined,
    children: IGroupedListResponse<T>[] | T[]
}

export interface IResponseData {
    message: string
}

export function toFlatGroupedList<T extends IModel>(response: IGroupedListResponse<T>[]) {
    return buildGroupedList(response, 1)
        .map((value, key) => {
            if (value.isHeader)
                return new GroupedListItem<T>({
                    header: value.header,
                    headerLevel: value.headerLevel,
                    id: "header_" + key
                });
            else
                return value;
        })
}

export function buildGroupedList<T extends IModel>(response: IGroupedListResponse<T>[], headerLevel: number) {
    let elements = List<GroupedListItem<T>>();
    if (headerLevel === undefined)
        headerLevel = 1;

    for (const child of response) {

        if (child.type === GroupElementType.Elements)
            elements = elements.concat(child.children.map(value => asElement(value as T)));
        else {
            elements = elements
                .push(asGroup(child, headerLevel))
                .concat(buildGroupedList<T>(child.children as IGroupedListResponse<T>[], headerLevel + 1))
        }
    }

    return elements;
}

function asElement<T extends IModel>(value: T) {
    return new GroupedListItem<T>({
        id: value.id,
        element: value
    });
}

function asGroup<T extends IModel>(value: IGroupedListResponse<T>, headerLevel: number) {
    return new GroupedListItem<T>({
        header: `${value.name}: ${value.value}`,
        headerLevel: headerLevel
    });
}