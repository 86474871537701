import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError} from "../globals/ApiGlobals";
import {ConcordanceSearchResult} from "../model/ConcordanceSearch";
import {List} from "immutable";
import {IConcordanceSearchResponse, toConcordanceSearchResultModel} from "./model/TranslationMemoryApiModel";
import {Page} from "../model/Page";
import TranslationMemory from "../model/TranslationMemory";
import {PretranslateSetting} from "../model/PretranslateSetting";
import {fromModel, IPretranslateStatus, toPretranslateStatusModel} from "./model/PretranslateApiModel";
import {PretranslateStatus} from "../model/PretranslateStatus";

export function pretranslate(projectId: number,
                             settings: List<PretranslateSetting>) {
    const url = buildUrl(endpoint, {}, {projectId: projectId});
    return axios
        .post(url, settings.map(value => fromModel(value)))
        .catch(dispatchError);
}

export function cancelPretranslate(projectId: number) {
    const url = buildUrl(endpoint + "/cancel", {}, {projectId: projectId});
    return axios
        .post(url)
        .catch(dispatchError);
}

export function pretranslateStatus(projectId: number) {
    const url = buildUrl(endpoint + "/status", {}, {projectId: projectId});
    return axios
        .get(url)
        .then((response: AxiosResponse<IPretranslateStatus>) => {
            return toPretranslateStatusModel(response.data);
        })
        .catch(error => {
            dispatchError(error)
            return new PretranslateStatus()
        });
}

export function executeConcordanceSearch(valueToFind: string,
                                         concordanceSearchType: string,
                                         sourceLanguage: string,
                                         targetLanguage: string,
                                         translationMemories: List<TranslationMemory>) {
    return axios
        .post(endpointDirect + "/concordance-search", {
            valueToFind: valueToFind,
            concordanceSearchType: concordanceSearchType,
            sourceLanguage: sourceLanguage,
            targetLanguage: targetLanguage,
            translationMemoryIds: translationMemories.map(tm => tm.id).toArray()
        })
        .then((response: AxiosResponse<IConcordanceSearchResponse[]>) => {
            const list = response
                .data
                .map(value => toConcordanceSearchResultModel(value));
            return new Page<ConcordanceSearchResult>().setList(List(list));
        })
        .catch(dispatchFetchPageError<ConcordanceSearchResult>);
}

const endpoint = "/api/projects/pretranslate";
const endpointDirect = "/api/pretranslate/pretranslate";
