import {List, Map, Record} from "immutable";
import {GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel} from "@mui/x-data-grid";
import {IFilter} from "../../../model/common/Filter";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../../utils/ListViewUtils";
import {isFilterInvalid} from "../../../utils/FilterUtils";

interface IProjectsListOperatorsFilter {
    projectOperator: string | null,
    sourceLanguageOperator: string | null
    targetLanguageOperator: string | null
}

interface IProjectsListFilter extends IProjectsListOperatorsFilter {
    project: string | null,
    sourceLanguage: string | null,
    targetLanguages: List<string>,
    orderBy: string | null,
    orderDirection: string | null
}

const ProjectsListFilterRecord = Record<IProjectsListFilter>({
    project: null,
    projectOperator: null,
    sourceLanguage: null,
    sourceLanguageOperator: null,
    targetLanguages: List<string>(),
    targetLanguageOperator: null,
    orderBy: null,
    orderDirection: null
});

export default class ProjectListFilter extends ProjectsListFilterRecord implements IFilter<ProjectListFilter> {
    updateWithGridFilterModel(model: GridFilterModel): ProjectListFilter {
        return updateWithGridFilterModel<IProjectsListFilter, ProjectListFilter>(this, model, AvailableProjectsListFilters);
    }

    updateWithGridSortingModel(model: GridSortModel): ProjectListFilter {
        return updateWithGridSortingModel<IProjectsListFilter, ProjectListFilter>(this, model, AvailableProjectsListSortedFields);
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "project", this.project);
        addSearchParam(result, "projectOperator", this.projectOperator);
        addSearchParam(result, "sourceLanguage", this.sourceLanguage);
        addSearchParam(result, "sourceLanguageOperator", this.sourceLanguageOperator);

        if (this.targetLanguages) {
            const array: string[] = [...this.targetLanguages];
            addSearchParam(result, "targetLanguages", array);
        } else {
            addSearchParam(result, "targetLanguages", null);
        }

        addSearchParam(result, "targetLanguageOperator", this.targetLanguageOperator);
        addSearchParam(result, "orderBy", this.orderBy);
        addSearchParam(result, "orderDirection", this.orderDirection);

        return result.join('&');
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.project && this.projectOperator)
            items.push({field: "project", operator: this.projectOperator, value: this.project});
        if (this.sourceLanguage && this.sourceLanguageOperator)
            items.push({field: "sourceLanguage", operator: this.sourceLanguageOperator, value: this.sourceLanguage});
        const targetLanguageOperator = this.targetLanguageOperator;
        if (this.targetLanguages && targetLanguageOperator)
            this.targetLanguages.forEach(value => {
                items.push({field: "targetLanguages", operator: targetLanguageOperator, value: value});
            })
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        if (this.orderBy && this.orderDirection) {
            const foundEntry = AvailableProjectsListSortedFields.findEntry(value =>
                value === this.orderBy);
            if (foundEntry === undefined)
                return [];
            return [{field: foundEntry[0], sort: this.orderDirection as GridSortDirection}];
        }
        return [];
    }

    isValid(): boolean {
        return !(isFilterInvalid(this.project, this.projectOperator)
            || isFilterInvalid(this.sourceLanguage, this.sourceLanguageOperator)
            || isFilterInvalid(
                this.targetLanguages ? this.targetLanguages.toArray() : this.targetLanguages,
                this.sourceLanguageOperator));
    }

    static fromSearchParams(searchParams: URLSearchParams) {
        let project = searchParams.get("project");
        if (project)
            project = decodeURIComponent(project);

        return new ProjectListFilter({
            project: project,
            projectOperator: searchParams.get("projectOperator"),
            sourceLanguage: searchParams.get("sourceLanguage"),
            sourceLanguageOperator: searchParams.get("sourceLanguageOperator"),
            targetLanguages: List(searchParams.getAll("targetLanguages").map(language => decodeURIComponent(language))),
            targetLanguageOperator: searchParams.get("targetLanguageOperator"),
            orderBy: searchParams.get("orderBy"),
            orderDirection: searchParams.get("orderDirection")
        })
    }
}

const AvailableProjectsListFilters = Map<string, string>()
    .set('project', 'projectOperator')
    .set('sourceLanguage', 'sourceLanguageOperator')
    .set('targetLanguages', 'targetLanguageOperator')

const AvailableProjectsListSortedFields = Map<string, string>()
    .set('project', 'name');