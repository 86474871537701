import {dispatcher} from "../Dispatcher";
import Alert from "../../model/Alert";
import {setAlertList, setAlertsIdInitialized, setWaitingForAlert} from "./AlertPayload";
import {alertNotification, alertSubscription} from "../../api/NotificationsApi";
import {List} from "immutable";
import alertStore from "./AlertStore";

export function setAlertAction(alert: Alert) {
    if (alert.severity === "error")
        console.error(alert);

    dispatcher.dispatch(setAlertList(List.of(alert)));
}

export function setWaitingForAlertAction(waitingForAlert: boolean) {
    dispatcher.dispatch(setWaitingForAlert(waitingForAlert));
}

export async function initAlertsSubscriptionAction() {
    if (alertStore.getState().isInitialized)
        return;

    dispatcher.dispatch(setAlertsIdInitialized());

    const notifications = await alertNotification();
    const alert = notifications.map(value => value.asAlert())
    dispatcher.dispatch(setAlertList(alert));

    alertSubscription(notifications => {
        const alerts = notifications.map(value => value.asAlert());
        dispatcher.dispatch(setAlertList(alerts))
    });
}
