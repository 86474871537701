import {BasePoint, BaseSelection, createEditor, Editor} from "slate";
import {Editable, ReactEditor, withReact} from "slate-react";
import React from "react";
import {styled} from "@mui/material/styles";
import {withHistory} from "slate-history";
import {SlateEntityType, SlateParagraph} from "./SlateModels";
import slateSerializer from "./SlateSerializer";

const InlineBugFixSpan = styled("span")({
    fontSize: 0
});

export const CustomEditable = styled(Editable)({
    textAlign: 'start',
    wordBreak: "break-word"
});

type CustomEditableTargetProps = {
    isEditing: boolean,
    style: React.CSSProperties
}

export const CustomEditableTarget = styled(CustomEditable, {
    shouldForwardProp: propName => propName !== 'isEditing' && propName !== 'style'
})<CustomEditableTargetProps>(props => ({
    border: props.isEditing ? '1px solid #B3BAC5' : 'none',
    borderRadius: "4px",
    padding: "8px",
    background: props.isEditing ? '#FFFFFF' : 'none',
    height: `calc(${props.style.height}px - 20px)`,
    width: "100%",
    flexGrow: 1,
    outline: "none",
    overflowY: "auto"
}));

export const CustomEditableSmallText = styled(Editable)({
    textAlign: 'start',
    fontSize: 12
});

export const InlineChromiumBugfix = () => (
    <InlineBugFixSpan contentEditable={false}>
        {String.fromCodePoint(160) /* Non-breaking space */}
    </InlineBugFixSpan>
);


export function editorText(editor: ReactEditor) {
    return slateSerializer.serialize(editor.children);
}

export function createSlateWithHistory(text = "") {
    const editor = withInlines(withHistory(withReact(createEditor())));
    editor.children = slateSerializer.deserialize(text).children;
    return editor
}

export function createSlate(text = "") {
    const editor = withInlines(withReact(createEditor()));
    editor.children = slateSerializer.deserialize(text).children;
    return editor
}

export function withInlines(editor: Editor): Editor {
    const {isElementReadOnly, isInline, isSelectable} = editor;

    editor.isInline = (element) => element.type === SlateEntityType.Placeholder
        || element.type === SlateEntityType.EscapedText
        || isInline(element);
    editor.isElementReadOnly = (element) => element.type === SlateEntityType.Placeholder
        || element.type === SlateEntityType.EscapedText
        || isElementReadOnly(element);
    editor.isSelectable = (element) => element.type !== SlateEntityType.Placeholder
        && element.type !== SlateEntityType.EscapedText
        && isSelectable(element);
    return editor;
}

export function placeholderContentPresentation(content: string) {
    content = content.replaceAll('\n', '\\n');
    const maxLength = 150;

    return content.length > maxLength ? content.substring(0, maxLength) + "..." : content;
}

export function checkIfCursorIsOnLastSymbol(selection: BaseSelection, childNodes: any) {
    if (!selection)
        return true;
    const childContainerIndexIsBigger = selection.anchor.path[0] > selection.focus.path[0];
    const childIndexIsBigger = selection.anchor.path[0] === selection.focus.path[0] && selection.anchor.path[1] > selection.focus.path[1];
    const sameChildButOffsetIsBigger = selection.anchor.path[0] === selection.focus.path[0] && selection.anchor.path[1] === selection.focus.path[1] && selection.anchor.offset > selection.focus.offset;
    const firstSelectedPoint = (childContainerIndexIsBigger || childIndexIsBigger || sameChildButOffsetIsBigger)
        ? selection.focus
        : selection.anchor;

    if (childNodes.length - 1 === firstSelectedPoint.path[1]) {
        // check offset if node is equal to last one
        const lastChild = childNodes[childNodes.length - 1];
        const textToCompare = !!lastChild.text ? lastChild.text : (!!lastChild.rowText ? lastChild.rowText : '');
        return firstSelectedPoint.offset === textToCompare.length;
    }
    return false;
}

export function sortRangePoints(point1: BasePoint, point2: BasePoint) {
    const path1 = point1.path;
    const path2 = point2.path;
    const smallestLength = path1.length < path2.length ? path1.length : path2.length;
    for (let i = 0; i < smallestLength; i++) {
        if (path1[i] !== path2[i])
            return path1[i] < path2[i] ? [point1, point2] : [point2, point1];
    }
    return point1.offset < point2.offset ? [point1, point2] : [point2, point1];
}

export function getTextBetweenPoints(points: BasePoint[], editorParagraph: SlateParagraph) {
    if (points.length !== 2)
        return "";
    const path1 = points[0].path;
    const path2 = points[1].path;
    if (path1.length < 2 || path2.length < 2)
        return "";
    const selectedChildren = editorParagraph.children.slice(path1[1], path2[1] + 1);

    const selectedText: string[] = selectedChildren.map((child: any) => !!child.text ? child.text : "");
    if (selectedText.length === 1)
        return selectedText[0].slice(points[0].offset, points[1].offset).trim();
    selectedText[0] = selectedText[0].slice(points[0].offset);
    selectedText[selectedText.length - 1] = selectedText[selectedText.length - 1].slice(0, points[1].offset);
    return selectedText.join("").trim();
}
