import {Button, Dialog, DialogTitle, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Tenant} from "../../model/Tenant";
import {tenantListActions} from "../../flux/tenant/TenantListActions";
import {List} from "immutable";
import {ReactSetter} from "../../globals/Types";

interface IProps {
    tenant: Tenant,
    open: boolean,
    onClose: () => void
}

export function TenantEditor(props: IProps) {
    const [name, setName] = useState(props.tenant.name);
    const [errorInName, setErrorInName] = useState(false)

    useEffect(() => {
        setName(props.tenant.name);
    }, [props.tenant]);

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>Tenant</DialogTitle>
            <Stack direction={"column"} alignItems={"center"} spacing={2} padding={2}>
                <TextField fullWidth={true}
                           id="tenant-name"
                           label="Name"
                           variant="standard"
                           value={name}
                           error={errorInName}
                           helperText={errorInName ? "empty name" : ""}
                           onChange={(e) => {
                               setErrorInName(false);
                               setName(e.target.value);
                           }}/>

                <Button fullWidth={true}
                        variant={"contained"}
                        onClick={() => onSave(name, setErrorInName, props)}>
                    Save
                </Button>
            </Stack>

        </Dialog>
    );
}

function onSave(name: string, setErrorInName: ReactSetter<boolean>, props: IProps) {
    if (name.trim() === '') {
        setErrorInName(true)
        return;
    }

    tenantListActions.saveAll(List.of(props.tenant.set("name", name)));
    props.onClose();
}