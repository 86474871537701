import {SelectChangeEvent} from "@mui/material";
import SegmentListFilter from "../flux/segment/list/SegmentListFilter";
import {NavigateFunction} from "react-router-dom";
import {List} from "immutable";
import segmentEditorStore from "../flux/segment/editor/SegmentEditorStore";
import {getSegmentPath} from "../routes/editor/CatFileEditorRoute";

export function handleWorkflowFilterChanged(event: SelectChangeEvent<number[]>,
                                            filter: SegmentListFilter,
                                            navigate: NavigateFunction) {
    const {
        target: {value}
    } = event;
    const workflow = typeof value === 'string' ? value.split(',') : value;
    const updatedFilter = filter.set("workflowIds", List(workflow.map(id => Number(id))));
    updatePage(navigate, updatedFilter);
}

export function updatePage(navigate: NavigateFunction, updatedFilter: SegmentListFilter) {
    const currentSegment = segmentEditorStore.getState().segment;
    const url = getSegmentPath(
        updatedFilter,
        updatedFilter.isEmpty && currentSegment ? currentSegment.order + 1 : null);
    navigate(url, {replace: true});
}

export function isFilterInvalid(value: string | string[] | number[] | boolean | null,
                                valueOperator: string | null) {
    return valueOperator !== null
        && valueOperator !== 'isEmpty'
        && valueOperator !== 'isNotEmpty'
        && (Array.isArray(value) ? value.length === 0 : value === null);
}