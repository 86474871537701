import {Record} from "immutable";
import {AlertColor} from "@mui/material";
import Alert from "./Alert";

interface INotification {
    id: string,
    title: string,
    content: string,
    severity: AlertColor
}

const NotificationRecord = Record<INotification>({
    id: "",
    title: "",
    content: "",
    severity: "error"
});

export default class NotificationModel extends NotificationRecord {
    asAlert() {
        return new Alert({
            message: this.title,
            severity: this.severity
        });
    }
};