import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import {Pane, ResizablePanes} from "resizable-panes-react";
import {editorStyle} from "../../globals/themes/EditorStyle";
import TopFrame from "./TopFrame";
import {drawWorkaroundContainer} from "../../utils/EditorUtils";
import BottomFrame from "./BottomFrame";
import {useState} from "react";

export default function TranslationMemoryEditorView() {
    const [isBottomHidden, setBottomHidden] = useState(false);

    return <Box sx={{height: '100%', paddingTop: '64px'}}>
        <Stack height={'100%'}>
            <ResizablePanes uniqueId={"translation-memory-editor-horizontal-panes"}
                            unit={"ratio"}
                            unmountOnHide={false}
                            resizerSize={1}
                            detectionRadius={40}
                            resizerClass={"resizer"}
                            visibility={{
                                'workaround-pane': true,
                                'top-pane': true,
                                'bottom-pane': !isBottomHidden
                            }}>
                {drawWorkaroundContainer()}
                <Pane id={"top-pane"} minSize={0.5} size={0.7} className={"top-pane"}>
                    <TopFrame isBottomHidden={isBottomHidden} setIsBottomHidden={setBottomHidden}/>
                </Pane>
                <Pane id={"bottom-pane"} maxSize={0.5} size={0.3} className={"bottom-pane"}>
                    <BottomFrame/>
                </Pane>
            </ResizablePanes>
            <style>{editorStyle}</style>
        </Stack>
    </Box>;
}