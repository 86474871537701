import {Record} from "immutable";
import TranslationMemory from "../../model/TranslationMemory";
import {Language} from "../../model/Language";
import {IFilter} from "../../model/common/Filter";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam} from "../../utils/ListViewUtils";

export interface ITranslationMemorySegmentFilter {
    translationMemory: TranslationMemory,
    language: Language | null,
    translationMemoryName: string | null,
    source: string | null,
    target: string | null,
    sourceId: string | null,
    matchCase: boolean,
    pageSize: number
}

const TranslationMemorySegmentFilterRecord = Record<ITranslationMemorySegmentFilter>({
    translationMemory: new TranslationMemory(),
    language: null,
    translationMemoryName: null,
    source: null,
    target: null,
    sourceId: null,
    matchCase: false,
    pageSize: 25
});

export default class TranslationMemorySegmentFilter extends TranslationMemorySegmentFilterRecord
    implements IFilter<TranslationMemorySegmentFilter> {

    updateWithGridFilterModel(): TranslationMemorySegmentFilter {
        throw new Error("Unsupported")
    }

    updateWithGridSortingModel(): TranslationMemorySegmentFilter {
        throw new Error("Unsupported")
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(
            result,
            "translationMemoryName",
            this.translationMemoryName ? encodeURIComponent(this.translationMemoryName) : null);
        addSearchParam(result, "language", this.language? this.language.id : null);
        addSearchParam(result, "source", this.source ? encodeURIComponent(this.source) : null);
        addSearchParam(result, "target", this.target ? encodeURIComponent(this.target) : null);
        addSearchParam(result, "sourceId", this.sourceId ? encodeURIComponent(this.sourceId) : null);
        addSearchParam(result, "matchCase", this.matchCase);
        return result.join("&");
    }

    isValid(): boolean {
        return true;
    }

    static fromSearchParams(translationMemory: TranslationMemory,
                            language: Language | null,
                            searchParams: URLSearchParams) {
        let translationMemoryName = searchParams.get("translationMemoryName");
        if (translationMemoryName)
            translationMemoryName = decodeURIComponent(translationMemoryName);

        let source = searchParams.get("source");
        if (source)
            source = decodeURIComponent(source);

        let target = searchParams.get("target");
        if (target)
            target = decodeURIComponent(target);

        let sourceId = searchParams.get("sourceId");
        if (sourceId)
            sourceId = decodeURIComponent(sourceId);

        return new TranslationMemorySegmentFilter({
            translationMemory: translationMemory,
            language: language,
            translationMemoryName: translationMemoryName,
            source: source,
            target: target,
            sourceId: sourceId,
            matchCase: searchParams.get("matchCase") === "true"
        });
    }

}