import * as React from "react";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Project from "../../model/Project";
import segmentListStore from "../../flux/segment/list/SegmentListStore";
import {getCatFilesPath} from "../../routes/project/tabs/CatFilesRoute";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import {Tooltip} from "@mui/material";
import {OpenProps} from "../../globals/Types";
import {BackButton, BackButtonText, CustomArrowBack} from "../../globals/CommonComponents";

export default function ProjectBackButton(props: OpenProps) {
    const projectState = projectPageStore.getState();

    const navigate = useNavigate();
    const [currentProject, setCurrentProject] = useState<Project>(projectState.project);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
        });

        return () => {
            projectPageListener.remove();
        }
    }, []);

    if (!props.isOpen)
        return null;

    return <Tooltip title={currentProject.name}>
        <BackButton onClick={() => handleBackClicked(navigate, currentProject)} variant="menu2" size="small">
            <CustomArrowBack fontSize="inherit"/>
            <BackButtonText variant="body2">{currentProject.name}</BackButtonText>
        </BackButton>
    </Tooltip>;
}

function handleBackClicked(navigate: NavigateFunction, project: Project) {
    const projectUrl = segmentListStore.getState().projectUrl;
    navigate(projectUrl ? projectUrl : getCatFilesPath(new CatFilesFilter({projectId: project.id})));
}
