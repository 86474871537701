import {List} from "immutable";
import {Model} from "../model/common/IModel";
import dayjs from "dayjs";

const he = require('he');

export function dateTimeFormat(date: Date) {
    return dayjs(date).format("MM-DD-YYYY HH:mm");
}

export function dateFormat(date: Date) {
    return dayjs(date).format("MM-DD-YYYY");
}

export function timeFormat(date: Date) {
    return dayjs(date).format("HH:mm");
}

// TODO: Synchronize time between client and server
export function utcDate(date: Date) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
}

export function numberFormat(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function startOfDay(date: Date) {
    const result = new Date(date);
    result.setHours(0, 0, 0, 0);
    return result;
}

export function endOfDay(date: Date) {
    const result = new Date(date);
    result.setHours(23, 59, 59, 999);
    return result;
}

export function timeoutPromise(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function hasModel(model: Model, models: List<Model>) {
    const founded = models.find(value => value.id === model.id);
    return !!founded;
}

export function enumByValue<T extends Object>(value: string, enumValues: T[]) {
    const index = enumValues.map(v => v.toString()).indexOf(value);
    return enumValues[index];
}

export function textToUserPresentation(s: string) {
    s = he.decode(s);
    s = s.replaceAll('\u00A0', '\u00B0');
    return s;
}