import {ReduceStore} from "flux/utils";
import {ConcordanceSearchPayload, ConcordanceSearchPayloadAction} from "./ConcordanceSearchPayload";
import Immutable from "immutable";
import {ConcordanceSearchResult} from "../../model/ConcordanceSearch";
import {dispatcher, Payload} from "../Dispatcher";
import {Page} from "../../model/Page";

export enum ConcordanceSearchType {
    Source = "Source",
    Target = "Target"
}

interface IConcordanceSearchState {
    concordanceSearchResults: Page<ConcordanceSearchResult>,
    concordanceSearchType: ConcordanceSearchType,
    concordanceSearchText: string
}

const ConcordanceSearchStateFactory
    = Immutable.Record<IConcordanceSearchState>({
    concordanceSearchResults: new Page<ConcordanceSearchResult>(),
    concordanceSearchType: ConcordanceSearchType.Source,
    concordanceSearchText: ""
});

export class ConcordanceSearchState extends ConcordanceSearchStateFactory {
}

export class ConcordanceSearchStore extends ReduceStore<ConcordanceSearchState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ConcordanceSearchState {
        return new ConcordanceSearchState();
    }

    reduce(state: ConcordanceSearchState, payload: ConcordanceSearchPayload): ConcordanceSearchState {
        switch (payload.action) {
            case ConcordanceSearchPayloadAction.SetConcordanceSearchResults:
                return state.set("concordanceSearchResults", payload.page);
            case ConcordanceSearchPayloadAction.SetConcordanceSearchType:
                return state.set("concordanceSearchType", payload.type);
            case ConcordanceSearchPayloadAction.SetConcordanceSearchText:
                return state.set("concordanceSearchText", payload.text);
        }
        return state;
    }
}

const concordanceSearchStore = new ConcordanceSearchStore();
export default concordanceSearchStore;
