import { ReduceStore } from "flux/utils";
import { Payload, dispatcher } from "../Dispatcher";
import {List, Record } from "immutable";
import {UserGroup} from "../../model/UserGroup";
import {UserGroupsAction, UserGroupsPayload} from "./UserGroupsPayload";

interface IUserGroupsState {
    groups: List<UserGroup>
}

const UserGroupsStateFactory = Record<IUserGroupsState>({
    groups: List()
});

class UserGroupsState extends UserGroupsStateFactory {
    getRoleByName(name: string): UserGroup | undefined {
        return this.groups.find(r => r.name === name);
    }
}

export class UserGroupsStore extends ReduceStore<UserGroupsState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): UserGroupsState {
        return new UserGroupsState();
    }

    reduce(state: UserGroupsState, payload: UserGroupsPayload): UserGroupsState {
        switch(payload.action) {
            case UserGroupsAction.SetUserGroup:
                state = state.set("groups", payload.groups);
                break;
        }
        return state;
    }
}

const userGroupsStore = new UserGroupsStore();
export default userGroupsStore;