import {Record} from "immutable";
import {Editor} from "slate";
import {createSlate} from "../utils/slate/SlateUtils";
import TranslationMemory from "./TranslationMemory";

interface ITranslationMemorySegment {
    id: string,
    source: string,
    sourceId: string,
    previousSource: string,
    nextSource: string,
    target: string,
    language: string,
    editorName: string,
    lastEditDateTime: string | null,
    sourceEditor: Editor,
    targetEditor: Editor,
    currentTarget: string,
    translationMemory: TranslationMemory
}

const TranslationMemorySegmentRecord = Record<ITranslationMemorySegment>({
    id: "",
    source: "",
    sourceId: "",
    previousSource: "",
    nextSource: "",
    target: "",
    language: "",
    editorName: "",
    lastEditDateTime: null,
    sourceEditor: createSlate(),
    targetEditor: createSlate(),
    currentTarget: "",
    translationMemory: new TranslationMemory()
})

export class TranslationMemorySegment extends TranslationMemorySegmentRecord {
}