import {List} from "immutable";
import NotificationModel from "../../model/NotificationModel";

export enum NotificationAction {
    SetNotificationList = "NotificationAction.SetNotificationListAction"
}

interface ISetNotificationPayload {
    action: NotificationAction.SetNotificationList,
    notificationList: List<NotificationModel>
}

export type NotificationPayload = ISetNotificationPayload;

export function setNotificationList(notificationList: List<NotificationModel>): NotificationPayload {
    return {
        action: NotificationAction.SetNotificationList,
        notificationList: notificationList
    }
}

