import {CatEventGroup} from "../CatEventGroup";
import {TranslationMemorySegment} from "../TranslationMemorySegment";

export enum GroupedActivityListItemType {
    Header = 1,
    Group = 2
}

interface IGroupedActivityListHeader {
    type: GroupedActivityListItemType.Header,
    day: Date,
    date: Date
}

interface IGroupedActivityListRow {
    type: GroupedActivityListItemType.Group,
    group: CatEventGroup,
    date: Date
}

export type TranslationMemorySegmentEntity = {
    segment: TranslationMemorySegment
}

export type GroupedActivityListEntity = IGroupedActivityListRow | IGroupedActivityListHeader;

export type ListEntity = GroupedActivityListEntity | TranslationMemorySegmentEntity;