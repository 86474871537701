import {ReduceStore} from "flux/utils";
import Immutable, {List} from "immutable";
import {AlertAction, AlertPayload} from "./AlertPayload";
import {dispatcher, Payload} from "../Dispatcher";
import Alert from "../../model/Alert";

interface IAlertState {
    isInitialized: boolean,
    alertList: List<Alert>,
    waitingForAlert: boolean
}

const AlertStateFactory = Immutable.Record<IAlertState>({
    isInitialized: false,
    alertList: List(),
    waitingForAlert: false
});

class AlertState extends AlertStateFactory {
}

export class AlertStore extends ReduceStore<AlertState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): AlertState {
        return new AlertState();
    }

    reduce(state: AlertState, payload: AlertPayload): AlertState {
        switch (payload.action) {
            case AlertAction.IsInitialized:
                return state.set("isInitialized", true);
            case AlertAction.SetAlert:
                return state.set("alertList", payload.alert);
            case AlertAction.SetWaitingForAlert:
                return state.set("waitingForAlert", payload.waitingForAlert);
            default:
                return state;
        }
    }
}

const alertStore = new AlertStore();
export default alertStore;
