import {Record, Set} from "immutable";

interface ISelection {
    isSelectAll: boolean,
    invertedSelection: Set<number | string>,
}

const SelectionRecord = Record<ISelection>({
    isSelectAll: false,
    invertedSelection: Set<number | string>()
})

export class SelectionModel extends SelectionRecord {
    static allSelected() {
        return new SelectionModel({isSelectAll: true});
    }

    get isSelectAll() {
        return this.get("isSelectAll")
    };

    get invertedSelection() {
        return this.get("invertedSelection")
    };

    public countSelected(totalElements: number) {
        if (this.isSelectAll)
            return totalElements - this.invertedSelection.size;
        else
            return this.invertedSelection.size;
    }

    public isSelected(id: number | string) {
        if (this.isSelectAll)
            return !this.invertedSelection.has(id)
        else
            return this.invertedSelection.has(id);
    }

    public toggleSelectAll() {
        return this
            .set("isSelectAll", !this.isSelectAll)
            .set("invertedSelection", Set());
    }

    public select(id: number | string) {
        if (this.isSelectAll)
            return this.removeFromInvertedSelection(id);
        else
            return this.addToInvertedSelection(id);
    }

    public deselect(id: number | string) {
        if (!this.isSelectAll)
            return this.removeFromInvertedSelection(id);
        else
            return this.addToInvertedSelection(id);
    }

    private addToInvertedSelection(id: number | string) {
        const updated = this.invertedSelection.add(id);
        return this.set("invertedSelection", updated)
    }

    private removeFromInvertedSelection(id: number | string) {
        const updated = this.get("invertedSelection").remove(id);
        return this.set("invertedSelection", updated)
    }
}