import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Close, Search} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import Project from "../../../../model/Project";
import {Language} from "../../../../model/Language";
import {NavigateFunction, useMatches, useNavigate} from "react-router-dom";
import {ProjectSearchId, ProjectSearchPath} from "../../../../routes/project/ProjectSearchRoute";
import {MatchesType} from "../../../../routes/Root";
import {CustomDrawer, DrawerPart, SearchBox} from "../../../../globals/CommonComponents";
import {flatGroupedSegmentListActions} from "../../../../flux/segment/list/SegmentListActions";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import {OpenProps, ReactSetter} from "../../../../globals/Types";
import {SearchDrawerWidth} from "../../../../globals/Constants";
import {dispatcher} from "../../../../flux/Dispatcher";
import {setProjectSearchMenuOpen} from "../../../../flux/project/search/ProjectSearchPayload";

const SearchIcon = styled(Search)({
    margin: '4px 8px 4px 0px',
    color: 'rgba(0, 0, 0, 0.54)'
});

export default function SearchInProject(props: OpenProps) {
    const initialProjectPageState = projectPageStore.getState();

    const [project, setProject] = useState<Project | null>(initialProjectPageState.project);
    const [targetLanguage, setTargetLanguage] = useState<Language | null>(null);
    const [source, setSource] = useState("");
    const [target, setTarget] = useState("");
    const [sourceId, setSourceId] = useState("");
    const [matchCase, setMatchCase] = useState(false);
    const navigate = useNavigate();
    const matches = useMatches() as MatchesType;

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        return () => projectPageListener.remove();
    }, []);

    return (
        <CustomDrawer width={SearchDrawerWidth} open={props.isOpen} anchor={"right"} variant={"permanent"}>
            <DrawerPart>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant={"h6"}>Search</Typography>
                    <SearchIcon/>
                    {showCloseButton(matches) && <IconButton onClick={handleDrawerClosed}>
                        <Close/>
                    </IconButton>}
                </Stack>
                <SearchBox>
                    <TextField variant={"standard"}
                               label={"in source"}
                               value={source}
                               onChange={e => setSource(e.target.value)}
                               slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                    <TextField variant={"standard"}
                               label={"in target"}
                               value={target}
                               onChange={e => setTarget(e.target.value)}
                               slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                    <TextField variant={"standard"}
                               label={"in string IDs"}
                               value={sourceId}
                               onChange={e => setSourceId(e.target.value)}
                               slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                    <Autocomplete options={getLanguages(project)}
                                  getOptionLabel={(option: Language) => option.name}
                                  value={targetLanguage}
                                  onChange={(_e, value) =>
                                      setTargetLanguage(value)}
                                  renderInput={(params) =>
                                      <TextField {...params} variant={"standard"} label={"Target language"}
                                                 slotProps={{
                                                     inputLabel: {
                                                         style: {marginTop: '10px', overflow: 'visible'}
                                                     }
                                                 }}/>}/>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={() => setMatchCase(!matchCase)}/>}
                                          label={"Match case"} value={matchCase}/>
                    </FormGroup>
                </SearchBox>
            </DrawerPart>
            <Divider/>
            <DrawerPart>
                <Stack direction={"row"} justifyContent={"flex-end"} spacing={0.5}>
                    <Button disabled={areDefaultsSet(source, target, sourceId, targetLanguage)}
                            onClick={() => handleClearClicked(setSource, setTarget, setSourceId, setTargetLanguage)}>
                        Clear
                    </Button>
                    <Button variant={"contained"}
                            disabled={!areSearchDataValid(source, target, sourceId,)}
                            onClick={() => handleSearchClicked(
                                project,
                                source,
                                target,
                                sourceId,
                                targetLanguage,
                                matchCase,
                                navigate)}>
                        Search
                    </Button>
                </Stack>
            </DrawerPart>
        </CustomDrawer>
    );
}

function handleDrawerClosed() {
    dispatcher.dispatch(setProjectSearchMenuOpen(false));
}

function handleClearClicked(setSource: ReactSetter<string>,
                            setTarget: ReactSetter<string>,
                            setSourceId: ReactSetter<string>,
                            setTargetLanguage: ReactSetter<Language | null>) {
    setSource("");
    setTarget("");
    setSourceId("");
    setTargetLanguage(null);
}

function handleSearchClicked(project: Project | null,
                             source: string,
                             target: string,
                             sourceId: string,
                             targetLanguage: Language | null,
                             matchCase: boolean,
                             navigate: NavigateFunction) {
    if (!project)
        return;

    const filter = new SegmentListFilter()
        .set("projectId", project.id)
        .set("source", source !== "" ? source : null)
        .set("target", target !== "" ? target : null)
        .set("sourceId", sourceId !== "" ? sourceId : null)
        .set("language", targetLanguage === null ? null : targetLanguage.id)
        .set("sourceMatchCase", matchCase)
        .set("targetMatchCase", matchCase);

    flatGroupedSegmentListActions.fetch(filter);

    navigate(ProjectSearchPath.replaceAll(":id", String(project.id)));
}

function getLanguages(project: Project | null): Language[] {
    if (!project)
        return [];

    return project.targets.toArray();
}

function areDefaultsSet(source: string,
                        target: string,
                        sourceId: string,
                        targetLanguage: Language | null) {
    return source === ""
        && target === ""
        && sourceId === ""
        && !targetLanguage;
}

function areSearchDataValid(source: string,
                            target: string,
                            sourceId: string) {
    const minLength = 3;
    return source.length >= minLength
        || target.length >= minLength
        || sourceId.length >= minLength;
}

function showCloseButton(matches: MatchesType) {
    const lastMatch = matches[matches.length - 1];
    return lastMatch.id !== ProjectSearchId;
}