import React, {useState} from "react";
import {IconButton, Stack} from "@mui/material";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import AddIcon from "@mui/icons-material/Add";
import {tenantListActions} from "../../flux/tenant/TenantListActions";
import NullFilter from "../../flux/common/NullFilter";
import {getTenantsPath} from "../../routes/admin/TenantsRoute";
import {TenantEditor} from "./TenantEditor";
import {Tenant} from "../../model/Tenant";
import EditIcon from "@mui/icons-material/Edit";
import {ReactSetter} from "../../globals/Types";

export default function TenantsList() {
    const [openEditor, setOpenEditor] = useState(false);
    const [currentTenant, setCurrentTenant] = useState<Tenant>(new Tenant());

    return (
        <Stack sx={{height: '100%'}}>
            <TenantEditor open={openEditor} tenant={currentTenant} onClose={() => {
                setOpenEditor(false)
                setCurrentTenant(new Tenant());
            }}/>
            <Stack sx={{height: '100%'}}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => setOpenEditor(true)}>
                        <AddIcon/>
                    </IconButton>
                </Stack>
                <ListView actions={tenantListActions}
                          columns={columns(setOpenEditor, setCurrentTenant)}
                          navigateProps={{navigator: getTenantsPath}}
                          selection={false}
                          initialFilter={new NullFilter()}/>
            </Stack>
        </Stack>
    );
}

function columns(setOpenEditor: ReactSetter<boolean>, setCurrentTenant: ReactSetter<Tenant>): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            flex: 10,
            filterable:false,
            sortable:false,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            filterable: false,
            sortable: false,
            getActions: (params: GridRowParams<Tenant>) => [
                <GridActionsCellItem label={'Update'} icon={<EditIcon/>}
                                     onClick={() => {
                                         setCurrentTenant(params.row)
                                         setOpenEditor(true)
                                     }}/>
            ]
        }
    ];
}