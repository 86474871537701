import {Button, Chip, Collapse, Container, Divider, LinearProgress, Link, Stack} from "@mui/material";
import React, {CSSProperties, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import {List, Map, Set} from "immutable";
import {styled} from "@mui/material/styles";
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import UndoIcon from '@mui/icons-material/Undo';
import {eventGroupListActions} from "../../flux/event/EventGroupListActions";
import {CatEventGroup} from "../../model/CatEventGroup";
import {dateFormat, startOfDay, timeFormat} from "../../globals/Utils";
import authStore from "../../flux/auth/AuthStore";
import {EventListActions} from "../../flux/event/EventListActions";
import {CatEvent} from "../../model/CatEvent";
import ListView from "../common/ListView";
import PrivateComponent from "../PrivateComponent";
import {AvailableResourceType} from "../../model/AvailableResources";
import CatEventFilter from "../../flux/event/CatEventFilter";
import {VariableSizeList} from "react-window";
import {CatEventTypeEnum} from "../../model/CatEventTypeModel";
import LazyLoadedList from "../common/LazyLoadedList";
import {Size} from "react-virtualized-auto-sizer";
import {GroupedActivityListEntity, GroupedActivityListItemType} from "../../model/common/ListEntity";
import {ReactSetter} from "../../globals/Types";

const RollbackButton = styled(Button)({
    textTransform: "none"
});

const rowHeight = 50;
const detailsHeight = 500;

export default function GroupedActivityList() {
    const listRef = useRef<VariableSizeList | null>(null);
    const [expandedGroups, setExpandedGroups] = useState(Set<CatEventGroup>);

    return (
        <Container sx={{height: '100%'}}>
            <LazyLoadedList actions={eventGroupListActions}
                            itemSize={(index, _size, items) =>
                                itemSize(index, items, expandedGroups)}
                            itemsConverter={itemsToListItems}
                            drawItems={(index: number,
                                        size: Size,
                                        style: CSSProperties,
                                        items: List<GroupedActivityListEntity>) =>
                                drawEventGroups(
                                    index,
                                    size.width,
                                    style,
                                    items,
                                    expandedGroups,
                                    setExpandedGroups,
                                    listRef.current!)}/>
        </Container>
    );
}

function itemSize(index: number, items: List<GroupedActivityListEntity>, expandedGroups: Set<CatEventGroup>) {
    const item = items.get(index);
    let size = rowHeight;
    if (item?.type == GroupedActivityListItemType.Group && expandedGroups.contains(item.group))
        size += detailsHeight;
    return size;
}

function itemsToListItems(items: List<CatEventGroup>) {
    let result = List<GroupedActivityListEntity>();
    dayToEventGroupsMap(items)
        .forEach((value, key) => {
            result = result.push({
                type: GroupedActivityListItemType.Header,
                day: key,
                date: key
            });
            const entities = value.map(row => {
                return {
                    type: GroupedActivityListItemType.Group,
                    group: row,
                    date: key
                } as GroupedActivityListEntity
            })
            result = result.concat(entities);
        });

    return result.sort((a, b) => {
        const dateComparison = b.date.getTime() - a.date.getTime();
        if (dateComparison !== 0)
            return dateComparison;

        return a.type - b.type;
    });
}

function drawEventGroups(index: number,
                         width: number,
                         style: React.CSSProperties,
                         items: List<GroupedActivityListEntity>,
                         expandedGroups: Set<CatEventGroup>,
                         setExpandedGroups: ReactSetter<Set<CatEventGroup>>,
                         listRef: VariableSizeList) {
    const entity = items.get(index)!;
    if (!entity) {
        return (
            <Container style={style}>
                <LinearProgress/>
            </Container>
        )
    }

    switch (entity.type) {
        case GroupedActivityListItemType.Group:
            return drawGroup(entity.group, expandedGroups, setExpandedGroups, index, style, width, listRef);
        case GroupedActivityListItemType.Header:
            return (
                <Divider sx={{marginY: 1}} style={style}>
                    <Chip label={dateFormat(entity.day)} variant={"outlined"}/>
                </Divider>
            )
    }
}

function drawGroup(eventGroup: CatEventGroup,
                   expandedGroups: Set<CatEventGroup>,
                   setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>,
                   key: number,
                   style: React.CSSProperties,
                   width: number,
                   listRef: VariableSizeList) {
    const controlsSize = 200;
    return (
        <Stack direction={"column"} key={"group-" + key} style={{...style, ...{wordBreak: 'break-all'}}}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Link fontWeight={"bold"}
                      width={width - controlsSize}
                      fontSize={14}
                      underline={"none"}
                      component={"button"}
                      color={"inherit"}
                      textAlign={"left"}
                      onClick={() => handleExpandGroupClicked(eventGroup, expandedGroups, setExpandedGroups, listRef)}
                >
                    {groupPresentation(eventGroup)}
                </Link>
                <Stack direction={"row"} alignItems={"center"} width={controlsSize}>
                    {drawExpandIcon(eventGroup, expandedGroups, setExpandedGroups, listRef)}
                    {drawGroupRollback(eventGroup)}
                    <Typography fontWeight={"bold"} fontSize={14}>{timeFormat(eventGroup.date)}</Typography>
                </Stack>
            </Stack>
            {drawDetails(eventGroup, expandedGroups)}
        </Stack>
    )
}

function drawDetails(eventGroup: CatEventGroup, expandedGroups: Set<CatEventGroup>) {
    const isExpanded = isGroupExpanded(eventGroup, expandedGroups)
    let details = null;
    if (isExpanded) {
        const actions = eventGroupListActions.getDetails(eventGroup);
        details = <ListView actions={actions}
                            columns={columns(eventGroup, actions as EventListActions)}
                            selection={false}
                            navigateProps={null}
                            height={detailsHeight}
                            initialFilter={new CatEventFilter()}/>;
    }

    return (
        <Collapse in={isExpanded}>
            {details}
        </Collapse>
    )
}

function groupPresentation(eventGroup: CatEventGroup) {
    const details = `${filePresentation(eventGroup)} ${languagePresentation(eventGroup)} ${workflowPresentation(eventGroup)}`;
    return `${userPresentation(eventGroup)} ${eventGroup.eventType.presentation()}. ${details}`;
}

function userPresentation(eventGroup: CatEventGroup) {
    const user = authStore.getState().user
    if (!user)
        return "";

    if (user.id === eventGroup.userId)
        return "You:";
    else
        return `${eventGroup.username}:`;
}

function filePresentation(eventGroup: CatEventGroup) {
    if (!eventGroup.catFileName)
        return '';

    return `File: "${eventGroup.catFileName}".`;
}

function languagePresentation(eventGroup: CatEventGroup) {
    if (!eventGroup.targetLanguage)
        return '';

    return `Language: "${eventGroup.targetLanguage}".`;
}

function workflowPresentation(eventGroup: CatEventGroup) {
    if (!eventGroup.workflowStepName)
        return '';

    return `Workflow: "${eventGroup.workflowStepName}."`;
}

function dayToEventGroupsMap(groups: List<CatEventGroup>): Map<Date, List<CatEventGroup>> {
    return groups
        .reduce((map, item: CatEventGroup) => {
            const date = startOfDay(item.get('date'));
            if (!date)
                return map;

            return map.update(date, itemList => {
                if (!itemList)
                    return List([item]);
                return itemList.push(item);
            });

        }, Map());
}

function isGroupExpanded(eventGroup: CatEventGroup, expandedGroups: Set<CatEventGroup>) {
    return expandedGroups.contains(eventGroup);
}

function handleExpandGroupClicked(eventGroup: CatEventGroup,
                                  expandedGroups: Set<CatEventGroup>,
                                  setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>,
                                  listRef: VariableSizeList) {
    let updated;
    if (expandedGroups.contains(eventGroup))
        updated = expandedGroups.delete(eventGroup);
    else
        updated = expandedGroups.add(eventGroup);

    setExpandedGroups(updated);
    listRef.resetAfterIndex(0);
}

function drawExpandIcon(eventGroup: CatEventGroup,
                        expandedGroups: Set<CatEventGroup>,
                        setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>,
                        listRef: VariableSizeList) {
    return (
        <IconButton onClick={() => handleExpandGroupClicked(eventGroup, expandedGroups, setExpandedGroups, listRef)}>
            {isGroupExpanded(eventGroup, expandedGroups) ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
    )
}

function columns(eventGroup: CatEventGroup, eventListActions: EventListActions): GridColDef[] {
    let values: GridColDef[] = [];
    switch (eventGroup.eventType.type) {
        case CatEventTypeEnum.TranslationRolledBackEvent:
        case CatEventTypeEnum.SegmentTranslationSavedEvent:
        case CatEventTypeEnum.AddedDraftTranslationEvent:
        case CatEventTypeEnum.RevertedWorkflowStepEvent:
        case CatEventTypeEnum.ClearTargetEvent:
            values = [
                {
                    field: 'source',
                    filterable: false,
                    sortable: false,
                    headerName: 'source',
                    flex: 1,
                    valueGetter: params => params.row.dataAsJson.source
                },
                {
                    field: 'target',
                    filterable: false,
                    sortable: false,
                    headerName: 'target',
                    flex: 1,
                    valueGetter: params => params.row.dataAsJson.target
                },
                {
                    field: 'code',
                    filterable: false,
                    sortable: false,
                    headerName: 'code',
                    width: 50,
                    valueGetter: params => params.row.dataAsJson.languageCode
                },
                {
                    field: 'workflowStepName',
                    filterable: false,
                    sortable: false,
                    headerName: 'workflow',
                    width: 120
                }
            ]
            break;
        case CatEventTypeEnum.Deprecated_TranslationAssignee:
        case CatEventTypeEnum.ConfirmedTranslationEvent:
            values = [
                {
                    field: 'source',
                    filterable: false,
                    sortable: false,
                    headerName: 'source',
                    flex: 1,
                    valueGetter: params => params.row.dataAsJson.source
                },
                {
                    field: 'target',
                    filterable: false,
                    sortable: false,
                    headerName: 'target',
                    flex: 1,
                    valueGetter: params => params.row.dataAsJson.target
                },
                {
                    field: 'code',
                    filterable: false,
                    sortable: false,
                    headerName: 'code',
                    width: 50,
                    valueGetter: params => params.row.dataAsJson.languageCode
                },
                {
                    field: 'workflowStepName',
                    filterable: false,
                    sortable: false,
                    headerName: 'workflow',
                    width: 120,
                },
                {
                    field: 'bestMatchScore',
                    filterable: false,
                    sortable: false,
                    headerName: 'TM match',
                    width: 100,
                    valueGetter: params => params.row.bestMatchScore
                }
            ]
            break;
        case CatEventTypeEnum.CatFileCreatedEvent:
        case CatEventTypeEnum.CatFileDeletedEvent:
        case CatEventTypeEnum.CatFileUpdatedEvent:
        case CatEventTypeEnum.CatFileMTEvent:
        case CatEventTypeEnum.CatFilePretranslatedEvent:
        case CatEventTypeEnum.CatFileTranslationUploadedEvent:
            values = [
                {
                    field: 'catFileName',
                    filterable: false,
                    sortable: false,
                    headerName: 'name',
                    flex: 1
                }
            ]
            break;
        case CatEventTypeEnum.CatFileRolledBackEvent:
            values = [
                {
                    field: 'catFileName',
                    filterable: false,
                    sortable: false,
                    headerName: 'name',
                    flex: 1
                },
                {
                    field: 'state',
                    filterable: false,
                    sortable: false,
                    headerName: 'state',
                    width: 100,
                    valueGetter: params => params.row.dataAsJson.state
                }
            ]
            break;
        case CatEventTypeEnum.SegmentRecreatedEvent:
        case CatEventTypeEnum.SegmentCreatedEvent:
        case CatEventTypeEnum.SegmentDeletedEvent:
        case CatEventTypeEnum.SegmentUpdatedEvent:
        case CatEventTypeEnum.SegmentRolledBackEvent:
            values = [
                {
                    field: 'data',
                    filterable: false,
                    sortable: false,
                    headerName: 'data',
                    flex: 1,
                    valueGetter: params => params.row.data
                }
            ]
            break;

        default:
            return [];
    }

    values.push({
        field: 'date',
        filterable: false,
        sortable: false,
        headerName: 'date',
        width: 60,
        valueGetter: params => timeFormat(params.row.date)
    })

    values.push({
        field: 'Rollback',
        headerName: '',
        align: 'right',
        type: 'actions',
        width: 150,
        getActions: (params: GridRowParams<CatEvent>) => [
            drawEventRollback(params.row, eventListActions)
        ]
    })

    return values;
}

function drawEventRollback(catEvent: CatEvent, eventListActions: EventListActions) {
    return (
        <PrivateComponent resource={AvailableResourceType.Events} writeAllow={true}>
            <RollbackButton startIcon={<UndoIcon/>} onClick={() => eventListActions.rollback(catEvent)}>
                Rollback
            </RollbackButton>
        </PrivateComponent>
    )
}

function drawGroupRollback(eventGroup: CatEventGroup) {
    return (
        <PrivateComponent resource={AvailableResourceType.Events} writeAllow={true}>
            <RollbackButton onClick={() => eventGroupListActions.rollback(eventGroup)}
                            startIcon={<UndoIcon/>}>
                Rollback
            </RollbackButton>
        </PrivateComponent>
    )
}