import {OpenProps, ReactSetter} from "../../globals/Types";
import Box from "@mui/material/Box";
import {InputAdornment, Menu, MenuItem, SelectChangeEvent, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DropdownArrow, DropdownMenuLink, MenuLinkText, SearchField} from "../../globals/CommonComponents";
import {Search} from "@mui/icons-material";
import TranslationMemory from "../../model/TranslationMemory";
import {Page} from "../../model/Page";
import {translationMemoryListActions} from "../../flux/translation-memory/list/TranslationMemoryListActions";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {getTranslationMemoryPath} from "../../routes/editor/TranslationMemoryEditorRoute";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";
import CustomPagination from "../common/CustomPagination";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";

export default function TranslationMemoryDropdown(props: OpenProps) {
    const [translationMemories, setTranslationMemories] = useState(translationMemoryListActions.state.page);
    const [filter, setFilter]
        = useState<TranslationMemorySegmentFilter | null>(translationMemoryListActions.state.filter);
    const [searchTranslationMemoryFilter, setSearchTranslationMemoryFilter] = useState("");
    const [selectTranslationMemoryMenuAnchor, setSelectTranslationMemoryMenuAnchor]
        = useState<HTMLElement | null>(null);
    const [selectTranslationMemoryMenuOpen, setSelectTranslationMemoryMenuOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const translationMemorySegmentListListener = translationMemorySegmentListActions
            .addListener(() => {
                setFilter(translationMemorySegmentListActions.state.filter)
            });

        const translationMemoriesListener = translationMemoryListActions.store.addListener(() => {
            setTranslationMemories(translationMemoryListActions.store.getState().page);
        });

        return () => {
            translationMemorySegmentListListener.remove();
            translationMemoriesListener.remove();
        }
    });

    if (!props.isOpen || !filter)
        return null;

    return <Box>
        <Tooltip title={filter.translationMemory.name}>
            <DropdownMenuLink variant={"menu"} onClick={e =>
                handleSelectTranslationMemoryMenuOpened(
                    e,
                    setSelectTranslationMemoryMenuAnchor,
                    setSelectTranslationMemoryMenuOpen)}>
                <MenuLinkText>{filter.translationMemory.name}</MenuLinkText>
                <DropdownArrow fontSize="inherit"/>
            </DropdownMenuLink>
        </Tooltip>
        <Menu open={selectTranslationMemoryMenuOpen} anchorEl={selectTranslationMemoryMenuAnchor}
              onClose={() => handleSelectTranslationMemoryMenuClosed(
                  setSelectTranslationMemoryMenuAnchor,
                  setSelectTranslationMemoryMenuOpen,
                  setSearchTranslationMemoryFilter)}>
            {drawTranslationMemoryMenuItems(
                translationMemories,
                searchTranslationMemoryFilter,
                filter,
                setSelectTranslationMemoryMenuAnchor,
                setSelectTranslationMemoryMenuOpen,
                setSearchTranslationMemoryFilter,
                navigate)}
        </Menu>
    </Box>;
}

function handleSelectTranslationMemoryMenuOpened(event: React.MouseEvent<HTMLElement>,
                                                 setSelectTranslationMemoryMenuAnchor: ReactSetter<HTMLElement | null>,
                                                 setSelectTranslationMemoryMenuOpen: ReactSetter<boolean>) {
    setSelectTranslationMemoryMenuAnchor(event.currentTarget);
    setSelectTranslationMemoryMenuOpen(true);
}

function handleSelectTranslationMemoryMenuClosed(setSelectTranslationMemoryMenuAnchor: ReactSetter<HTMLElement | null>,
                                                 setSelectTranslationMemoryMenuOpen: ReactSetter<boolean>,
                                                 setSearchTranslationMemoryFilter: ReactSetter<string>) {
    setSelectTranslationMemoryMenuAnchor(null);
    setSelectTranslationMemoryMenuOpen(false);
    setSearchTranslationMemoryFilter("");
}

function drawTranslationMemoryMenuItems(translationMemories: Page<TranslationMemory>,
                                        searchTranslationMemoryFilter: string,
                                        filter: TranslationMemorySegmentFilter,
                                        setSelectTranslationMemoryMenuAnchor: ReactSetter<HTMLElement | null>,
                                        setSelectTranslationMemoryMenuOpen: ReactSetter<boolean>,
                                        setSearchTranslationMemoryFilter: ReactSetter<string>,
                                        navigate: NavigateFunction) {
    const menuItems = [
        <SearchField key={"translation-memory-menu-search"} size={"small"}
                     placeholder={"Search..."}
                     slotProps={{
                         input: {
                             startAdornment: <InputAdornment position={"start"}>
                                 <Search/>
                             </InputAdornment>
                         }
                     }}
                     onChange={e =>
                         handleSearchTranslationMemoryTextChanged(e, setSearchTranslationMemoryFilter)}
                     onKeyDown={e => e.stopPropagation()}/>];

    translationMemories.list.map(tm => {
        if (!tm.name.toLowerCase().includes(searchTranslationMemoryFilter))
            return;
        menuItems.push(<MenuItem key={"translation-memory-menu-" + tm.id}
                                 onClick={() => handleSelectTranslationMemory(
                                     tm,
                                     filter,
                                     setSelectTranslationMemoryMenuAnchor,
                                     setSelectTranslationMemoryMenuOpen,
                                     setSearchTranslationMemoryFilter,
                                     navigate)}>
            {tm.name}
        </MenuItem>);
    });

    menuItems.push(<CustomPagination pageable={translationMemories.pageable}
                                     onChange={handleTranslationMemoriesPageChange}
                                     onRowsPerPageChange={handleTranslationMemoriesRowsPerPageChange}/>);

    return menuItems;
}

function handleSelectTranslationMemory(translationMemory: TranslationMemory,
                                       filter: TranslationMemorySegmentFilter,
                                       setSelectTranslationMemoryMenuAnchor: ReactSetter<HTMLElement | null>,
                                       setSelectTranslationMemoryMenuOpen: ReactSetter<boolean>,
                                       setSearchTranslationMemoryFilter: ReactSetter<string>,
                                       navigate: NavigateFunction) {
    const selected = translationMemory.targetLanguages.get(0);
    if (!selected)
        return;
    handleSelectTranslationMemoryMenuClosed(
        setSelectTranslationMemoryMenuAnchor,
        setSelectTranslationMemoryMenuOpen,
        setSearchTranslationMemoryFilter);
    navigate(getTranslationMemoryPath(
            filter.set("translationMemory", translationMemory).set("language", selected)),
        {replace: true});
}

function handleSearchTranslationMemoryTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                  setSearchTranslationMemoryFilter: ReactSetter<string>) {
    setSearchTranslationMemoryFilter(event.target.value.toLowerCase());
}

function handleTranslationMemoriesRowsPerPageChange(e: SelectChangeEvent<number>) {
    const translationMemoryListState = translationMemoryListActions.state;
    translationMemoryListActions.fetch(
        translationMemoryListState.filter,
        translationMemoryListState.page.setSize(Number(e.target.value)).pageable);
}

function handleTranslationMemoriesPageChange(page: number) {
    const translationMemoryListState = translationMemoryListActions.state;
    translationMemoryListActions.fetch(
        translationMemoryListState.filter,
        translationMemoryListState.page.setPageNumber(page).pageable);
}