import {Container} from "@mui/material";
import React from "react";
import sanitizeHtml from "sanitize-html";

export interface TargetDiffViewProps {
    htmlDiff?: string,
    defaultTarget?: string
}

export default function TargetDiffView(props: TargetDiffViewProps) {
    let diff;
    if (!!props.htmlDiff)
        diff = sanitizeHtml(props.htmlDiff, {allowedTags: ['del', 'ins']})
    else if (!!props.defaultTarget)
        diff = props.defaultTarget;
    else
        diff = '';

    return (
        <Container dangerouslySetInnerHTML={{__html: diff}}
                   sx={{
                       wordWrap: 'break-word',
                       del: {
                           bgcolor: 'lightpink'
                       },
                       ins: {
                           bgcolor: 'lightgreen'
                       }
                   }}>
        </Container>
    );
}