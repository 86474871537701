import {ReduceStore} from "flux/utils";
import {CommonListAction} from "./CommonListPayload";
import {Record, Set} from 'immutable';
import {Page} from "../../../model/Page";
import {Model} from "../../../model/common/IModel";
import {dispatcher, Payload} from "../../Dispatcher";
import {IFilter} from "../../../model/common/Filter";
import {SelectionModel} from "../../../model/SelectionModel";

interface ICommonSelectState<M extends Model, F extends IFilter<F>> {
    isLoading: boolean,
    isInitial: boolean,
    filter: F | null,
    selected: Set<M>,
    selectionModel: SelectionModel,
    page: Page<M>
}

const CommonStateFactory = Record<ICommonSelectState<any, any>>({
    isInitial: true,
    isLoading: false,
    filter: null,
    selected: Set(),
    selectionModel: new SelectionModel(),
    page: new Page()
});

class CommonSelectState<M extends Model, F extends IFilter<F>>
    extends CommonStateFactory implements ICommonSelectState<M, F> {
}

export class CommonListStore<M extends Model, F extends IFilter<F>>
    extends ReduceStore<CommonSelectState<M, F>, Payload> {

    constructor() {
        super(dispatcher);
        this._storeId = this.getDispatchToken();
    }

    private readonly _storeId: string;

    get storeId() {
        return this._storeId;
    }

    getInitialState() {
        return new CommonSelectState();
    }

    reduce(state: CommonSelectState<M, F>, payload: Payload): CommonSelectState<M, F> {
        if (!("storeId" in payload))
            return state;

        if (payload.storeId !== this._storeId)
            return state;

        switch (payload.action) {
            case CommonListAction.Select:
                return state.set("selected", payload.selected);
            case CommonListAction.SetFilter:
                return state
                    .set("filter", payload.filter)
            case CommonListAction.SetPage:
                return state
                    .set("page", payload.page)
                    .set("filter", payload.filter)
                    .set("isInitial", false)
            case CommonListAction.SetIsLoading:
                return state.set("isLoading", payload.isLoading)
            case CommonListAction.SelectionModelSelect:
                return state.set("selectionModel", state.selectionModel.select(payload.id));
            case CommonListAction.SelectionModelDeselect:
                return state.set("selectionModel", state.selectionModel.deselect(payload.id));
            case CommonListAction.ToggleSelectAll:
                return state.set("selectionModel", state.selectionModel.toggleSelectAll());
            case CommonListAction.SetSelectionModel:
                return state.set("selectionModel", payload.selectionModel);
            default:
                return state;
        }
    }
}