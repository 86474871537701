import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
    Button,
    Chip,
    Container,
    Fab,
    FormControl,
    Grid2,
    Link,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import Drawer, {DrawerProps} from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import {Link as ReactRouterLink} from "react-router-dom";
import {ArrowBackIos} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

export const CustomArrowBack = styled(ArrowBackIos)({
    marginRight: '8px',
    fontSize: '0.75rem',
    fontWeight: 'normal'
});

export const DropdownArrow = styled(ArrowBackIos)({
    marginLeft: '8px',
    marginBottom: '7px',
    transform: 'rotate(270deg)',
    alignItems: 'center',
    display: 'flex'
});

export const CustomBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5
});

export const DataGridContainer = styled(Box)({
    minHeight: 300,
    width: '100%',
    flex: 1,
    overflow: 'auto',
    '& .activity-list--target': {
        whiteSpace: 'pre-wrap'
    },
    '& .translation-memory--state': {
        color: 'red'
    }
});

export const ButtonWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    gap: '10px'
});

export const SearchBox = styled(Box)({
    marginTop: '24px'
});

export const SimpleButton = styled(Button)({
    textTransform: 'none'
});

type CollapseExpandButtonProps = {
    isBottomHidden: boolean
}

export const CollapseExpandButton = styled(Button, {
    shouldForwardProp: propName => propName !== 'isBottomHidden'
})<CollapseExpandButtonProps>(() => ({
    width: '100%',
    height: '22px',
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4F5F7',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    textAlign: 'center',
    fontSize: '14px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#E0E0E0',
    },
    '& .button-text': {
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.14px',
        textAlign: 'left',
        marginRight: '8px',
        color: '#091E42'
    },
    '& .arrow-icon': {
        width: '24px',
        height: '24px',
    },
}));

export const BackButton = styled(Button)({
    width: '300px',
    gap: '5px',
    justifyContent: 'flex-start'
})

export const CustomChip = styled(Chip)({
    height: 18,
    fontSize: 12
});

export const CustomChipM1 = styled(Chip)({
    margin: 1
})

export const OverflowContainer = styled(Container)({
    overflowY: 'auto',
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

export const NoPaddingContainer = styled(Container)({
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

export const SegmentCell = styled(Container)({
    textAlign: 'center',
    verticalAlign: 'top',
    padding: '5px 5px !important'
});

export const InfoContainer = styled(Container)({
    overflowY: 'auto',
    height: '100%',
    maxHeight: '100%'
});

interface CustomDrawerProps extends DrawerProps {
    width: number;
}

export const CustomDrawer = styled(Drawer)<CustomDrawerProps>(
    (props: CustomDrawerProps) => ({
        width: props.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: props.width,
            transform: `translateX(${props.width}px)`,
            ...(props.open && {
                transform: 'translateX(0px)'
            })
        }
    })
);

export const RightBottomFab = styled(Fab)({
    position: 'absolute',
    bottom: 30,
    right: 30,
});

export const CustomFormControl = styled(FormControl)({
    margin: 1,
    width: '100%',
    '& .MuiInputLabel-root': {
        fontSize: '14px',
        position: 'relative',
        top: '10px',
        color: 'black',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#B0B0B0',
        top: '6px',
    },
});

export const BreakWordGrid = styled(Grid2)({
    wordWrap: 'break-word'
});

type SearchResultGridProps = {
    isSelected: boolean
}

export const SearchResultGrid = styled(Grid2, {
    shouldForwardProp: propName => propName !== 'isSelected'
})<SearchResultGridProps>((props) => ({
    backgroundColor: props.isSelected ? '#f3f0fb' : '#FAFBFC',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f6f6f6'
    }
}));

export const DropdownMenuLinkShort = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
    }
});

export const DropdownMenuLink = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
    },
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
});

export const SaveTranslationButton = styled(LoadingButton)({
    height: '30px',
    padding: '4px 10px',
    margin: 0,
    display: 'inline-flex',
    justifyContent: 'center'
});

export const SlateParagraph = styled('p')({
    marginTop: 0,
    marginBottom: '2px'
});

export const CommonLink = styled(ReactRouterLink)({
    textDecoration: 'none',
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
});

export const ButtonSegmentCell = styled(SegmentCell)({
    minWidth: '150px',
    width: '400px',
    whiteSpace: 'nowrap',
    paddingRight: '10px',
    paddingLeft: 0
});

export const StyledSelect = styled(Select)({
    borderRadius: '4px',
    backgroundColor: 'transparent',
    fontSize: '14px',
    height: '36px',
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        bottom: '1px',
        left: 0,
        width: '100%',
        height: '1px',
        backgroundColor: '#b0b0b0'
    },
    '&.Mui-focused:before': {
        backgroundColor: 'black',
        height: '2px'
    },
});

export const FoundFragment = styled('span')({
    color: 'red'
});

export const Placeholder = styled('span')({
    cursor: 'pointer',
    margin: '0px 1px',
    padding: '1px 4px',
    marginBottom: '0px',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 207, 108, 1)',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgba(173, 211, 146, 1)'
    }
});

type SegmentsTableRowProps = {
    isEditing: boolean,
    isLocked: boolean
}

function rowColor(props: SegmentsTableRowProps) {
    if (props.isLocked)
        return 'rgba(198,198,224,0.6)';
    if (props.isEditing)
        return '#f4f5f7';
    return '#ffffff';
}

export const SegmentsTableRow = styled(Stack, {
    shouldForwardProp: propName => propName !== 'isEditing' && propName !== 'isLocked'
})<SegmentsTableRowProps>((props) => ({
    backgroundColor: rowColor(props),
    '&:hover': {
        backgroundColor: '#FAFBFC'
    }
}));

export const DrawerPart = styled(Stack)({
    padding: '8px 24px'
});

export const SimpleTab = styled(Tab)({
    textTransform: 'none',
    padding:'16px'
});

export const ResponsiveTabs = styled(Tabs) ({
    display: 'flex',
    overflow: 'hidden',
    padding: 0,
    margin: 0,
});

export const SearchField = styled(TextField)({
    padding: 1
});

export const Bold = styled(Typography)({
    fontWeight: 'bold'
});

export const SmallText = styled(Typography)({
    fontSize: 12
});

export const BackButtonText = styled(Typography)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20.8px',
    letterSpacing: '0.15px'
});

export const MenuLinkText = styled(Typography)({
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px'
});
