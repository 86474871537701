import {ReduceStore} from "flux/utils";
import {PretranslateAction, PretranslatePayload} from "./PretranslatePayload";
import {List, Record} from "immutable";
import {PretranslateSetting} from "../../model/PretranslateSetting";
import {dispatcher, Payload} from "../Dispatcher";
import {restore} from "./PretranslateStoreConverter";
import {PretranslateStatus} from "../../model/PretranslateStatus";

interface IPretranslateState {
    status: PretranslateStatus
    settings: List<PretranslateSetting>;
}

const PretranslateFactory = Record<IPretranslateState>({
    status: new PretranslateStatus(),
    settings: List.of(new PretranslateSetting())
});

export class PretranslateState extends PretranslateFactory {
}

export class PretranslateStore extends ReduceStore<PretranslateState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): PretranslateState {
        return new PretranslateState();
    }

    reduce(state: PretranslateState, payload: PretranslatePayload): PretranslateState {
        switch (payload.action) {
            case PretranslateAction.SetSettings:
                return state.set("settings", payload.settings);
            case PretranslateAction.Restore:
                return restore(payload.data)
            case PretranslateAction.SetStatus:
                return state.set("status", payload.status)
            default:
                return state;
        }
    }
}

const pretranslateStore = new PretranslateStore();
export default pretranslateStore;