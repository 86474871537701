import TranslationMemoryEditorView from "../../components/translation-memory-editor/TranslationMemoryEditorView";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";

export default function TranslationMemoryEditorRoute() {
    return <TranslationMemoryEditorView/>;
}

export function getTranslationMemoryPath(filter: TranslationMemorySegmentFilter) {
    return TranslationMemoryEditorPath
        .replaceAll(":translationMemoryId", filter.translationMemory.id)
        .replaceAll(":language", filter.language ? filter.language.id : "") + "?" + filter.toSearchParams();
}

export const TranslationMemoryEditorPath = "/editor/translation-memory/:translationMemoryId/language/:language";
export const TranslationMemoryEditorId = "TranslationMemoryEditorRoute";