import {CustomDrawer, DrawerPart, SearchBox} from "../../globals/CommonComponents";
import {SearchDrawerWidth} from "../../globals/Constants";
import React, {useEffect, useState} from "react";
import {
    Autocomplete, Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import {Close} from "@mui/icons-material";
import {MatchesType} from "../../routes/Root";
import {
    getTranslationMemorySearchPath,
    TranslationMemoriesSearchId
} from "../../routes/translation-memory/TranslationMemoriesSearchRoute";
import {NavigateFunction, useMatches, useNavigate} from "react-router-dom";
import languageListStore from "../../flux/language/list/LanguageListStore";
import TranslationMemorySegmentFilter from "../../flux/translation-memory-segment/TranslationMemorySegmentFilter";
import {OpenProps} from "../../globals/Types";
import {dispatcher} from "../../flux/Dispatcher";
import {setTranslationMemorySearchMenuOpen} from "../../flux/translation-memory/search/TranslationMemorySearchPayload";
import {
    translationMemorySegmentListActions
} from "../../flux/translation-memory-segment/list/TranslationMemorySegmentListActions";

export default function SearchInTranslationMemories(props: OpenProps) {
    const initialLanguagesState = languageListStore.getState();

    const [supportedLanguages, setSupportedLanguages] = useState(initialLanguagesState.languages);
    const [filter, setFilter] = useState<TranslationMemorySegmentFilter>(new TranslationMemorySegmentFilter());
    const matches = useMatches() as MatchesType;
    const navigate = useNavigate();

    useEffect(() => {
        const languageListListener = languageListStore.addListener(() => {
            const languageListState = languageListStore.getState();
            setSupportedLanguages(languageListState.languages);
        });

        return () => languageListListener.remove();
    }, []);

    if (!filter)
        return null;

    return <CustomDrawer width={SearchDrawerWidth} open={props.isOpen} anchor={"right"} variant={"permanent"}>
        <DrawerPart>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant={"h6"}>Search</Typography>
                <SearchIcon/>
                {showCloseButton(matches) && <IconButton onClick={handleDrawerClosed}>
                    <Close/>
                </IconButton>}
            </Stack>
            <SearchBox>
                <TextField disabled={translationMemorySegmentListActions.state.isLoading}
                           variant={"standard"} label={"in translation memory"}
                           value={filter.translationMemoryName ? filter.translationMemoryName : ""}
                           onChange={e =>
                               setFilter(filter.set("translationMemoryName", e.target.value))}
                           slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                <TextField disabled={translationMemorySegmentListActions.state.isLoading}
                           variant={"standard"} label={"in source"} value={filter.source ? filter.source : ""}
                           onChange={e =>
                               setFilter(filter.set("source", e.target.value))}
                           slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                <TextField disabled={translationMemorySegmentListActions.state.isLoading}
                           variant={"standard"} label={"in target"} value={filter.target ? filter.target : ""}
                           onChange={e =>
                               setFilter(filter.set("target", e.target.value))}
                           slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                <TextField disabled={translationMemorySegmentListActions.state.isLoading}
                           variant={"standard"} label={"in string IDs"} value={filter.sourceId ? filter.sourceId : ""}
                           onChange={e =>
                               setFilter(filter.set("sourceId", e.target.value))}
                           slotProps={{inputLabel: {style: {marginTop: '10px', overflow: 'visible'}}}}/>
                <Autocomplete disabled={translationMemorySegmentListActions.state.isLoading}
                              options={supportedLanguages.getLanguages().toArray()}
                              getOptionLabel={option => option.name} value={filter.language}
                              onChange={(_e, value) =>
                                  setFilter(filter.set("language", value))}
                              renderInput={(params) =>
                                  <TextField {...params} variant={"standard"} label={"Target language"}
                                             slotProps={{
                                                 inputLabel: {
                                                     style: {marginTop: '10px', overflow: 'visible'}
                                                 }
                                             }}/>}/>
                <FormGroup>
                    <FormControlLabel control={<Checkbox disabled={translationMemorySegmentListActions.state.isLoading}
                                                         checked={filter.matchCase}
                                                         onChange={() =>
                                                             setFilter(filter.set("matchCase", !filter.matchCase))}/>}
                                      label={"Match case"}/>
                </FormGroup>
            </SearchBox>
        </DrawerPart>
        <Divider/>
        <DrawerPart>
            <Stack direction={"row"} justifyContent={"flex-end"} spacing={0.5}>
                <Button disabled={translationMemorySegmentListActions.state.isLoading || areDefaultsSet(filter)}
                        onClick={() => setFilter(new TranslationMemorySegmentFilter())}>
                    Clear
                </Button>
                <Button variant={"contained"}
                        disabled={translationMemorySegmentListActions.state.isLoading || !areSearchDataValid(filter)}
                        onClick={() => handleSearchClicked(filter, navigate)}>
                    Search
                </Button>
            </Stack>
        </DrawerPart>
    </CustomDrawer>;
}

function showCloseButton(matches: MatchesType) {
    const lastMatch = matches[matches.length - 1];
    return lastMatch.id !== TranslationMemoriesSearchId;
}

function handleDrawerClosed() {
    dispatcher.dispatch(setTranslationMemorySearchMenuOpen(false));
}

function handleSearchClicked(filter: TranslationMemorySegmentFilter, navigate: NavigateFunction) {
    navigate(getTranslationMemorySearchPath(filter));
}

function areSearchDataValid(filter: TranslationMemorySegmentFilter) {
    const minLength = 3;
    return filter.source && filter.source.length >= minLength
            || filter.target && filter.target.length >= minLength
            || filter.sourceId && filter.sourceId.length >= minLength;
}

function areDefaultsSet(filter: TranslationMemorySegmentFilter) {
    return filter.equals(new TranslationMemorySegmentFilter());
}