import ListView from "../common/ListView";
import {userListActions} from "../../flux/users/select/UserListActions";
import UsersListFilter from "../../flux/users/UsersListFilter";
import {getPermissionsUsersPath} from "../../routes/project/tabs/permissions/PermissionsUsersRoute";
import {useProject} from "../project/ProjectView";
import {useSearchParams} from "react-router-dom";
import {GridRowStringOperatorsWithAnyOf} from "../../globals/Constants";
import {getGridStringOperators} from "@mui/x-data-grid";

export default function PermissionsUsersView() {
    const {project} = useProject();
    const [searchParams] = useSearchParams();

    return <ListView key={"select-permission-tab0"} actions={userListActions} columns={columns}
                     navigateProps={{navigator: getPermissionsUsersPath}}
                     initialFilter={UsersListFilter.fromSearchParams(project.id, searchParams)}/>
}

const columns = [{
    field: 'name',
    sortable: true,
    filterable: true,
    headerName: 'Name',
    width: 600,
    filterOperators: getGridStringOperators().filter(operator =>
        GridRowStringOperatorsWithAnyOf.includes(operator.value))
}];