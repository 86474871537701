import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import {adminUserListActions} from "../../flux/users/select/AdminUserListActions";
import {getAdminUsersPath} from "../../routes/admin/AdminUsersRoute";
import {User} from "../../model/User";
import {UserEditor} from "./UserEditor";
import EditIcon from "@mui/icons-material/Edit";
import UsersListFilter from "../../flux/users/UsersListFilter";
import {useSearchParams} from "react-router-dom";
import {GridRowAnyOfOperator, GridRowStringOperatorsDefault} from "../../globals/Constants";
import {getFilterOperators} from "../../utils/ListViewUtils";
import SelectGroup from "./SelectGroup";
import {ReactSetter} from "../../globals/Types";

export default function AdminUsersList() {
    const [openEditor, setOpenEditor] = useState(false);
    const [searchParams] = useSearchParams();
    const [filter, setFilter] = useState(UsersListFilter.fromSearchParams(null, searchParams));
    const [currentUser, setCurrentUser] = useState<User>(new User());

    useEffect(() => {
        const filter = UsersListFilter.fromSearchParams(null, searchParams);
        setFilter(filter);
        adminUserListActions.fetch(filter);
    }, [searchParams])

    return (
        <Stack sx={{height: '100%'}}>
            <UserEditor open={openEditor}
                        onClose={() => {
                            setOpenEditor(false);
                            setCurrentUser(new User());
                        }}
                        user={currentUser}/>
            <ListView actions={adminUserListActions} columns={columns(setOpenEditor, setCurrentUser)}
                      navigateProps={{navigator: getAdminUsersPath}} initialFilter={filter} selection={false}/>
        </Stack>
    );
}

function columns(setOpenEditor: ReactSetter<boolean>, setCurrentUser: ReactSetter<User>): GridColDef[] {
    return [
        {
            field: 'firstName',
            headerName: 'First name',
            width: 200,
            flex: 2,
            filterOperators: getFilterOperators(GridRowStringOperatorsDefault)
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 200,
            flex: 2,
            filterOperators: getFilterOperators(GridRowStringOperatorsDefault)
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: 200,
            flex: 2,
            filterOperators: getFilterOperators(GridRowStringOperatorsDefault)
        },
        {
            field: 'userGroup',
            headerName: 'Roles',
            width: 200,
            flex: 2,
            renderCell: (params: GridRenderCellParams<User>) =>
                params.row.groups.map(g => g.name).join(", "),
            filterOperators: getFilterOperators(GridRowAnyOfOperator)
                .map(operator => ({...operator, InputComponent: SelectGroup})),
            sortable: false
        },
        {
            field: 'tenant',
            headerName: 'Tenant',
            width: 200,
            flex: 2,
            valueGetter: params => params.row.tenant?.name,
            filterOperators: getFilterOperators(GridRowStringOperatorsDefault)
        },
        {
            field: 'enabled',
            headerName: 'Status',
            type: 'boolean',
            width: 200,
            flex: 2
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            filterable: false,
            sortable: false,
            getActions: (params: GridRowParams<User>) => [
                <GridActionsCellItem label={'Update'} icon={<EditIcon/>}
                                     onClick={() => {
                                         setCurrentUser(params.row);
                                         setOpenEditor(true);
                                     }}/>
            ]
        }
    ];
}