import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError} from "../globals/ApiGlobals";
import {UserSetting} from "../model/UserSetting";
import {IUserSettingResponse, toModel} from "./model/SettingsApiModel";

export function saveSetting(setting: UserSetting) {
    const url = buildUrl(endpoint);
    return axios
        .post(url, setting)
        .catch(dispatchError);
}

export function fetchSetting(key: string) {
    const url = buildUrl(endpoint, {}, {key: key});
    return axios
        .get(url)
        .then((response: AxiosResponse<IUserSettingResponse>) => {
            const data = response
                .data;
            return toModel(data);
        })
        .catch((error) => {
            dispatchError(error);
            return new UserSetting();
        });
}

const endpoint = "/api/common/user-settings";