import NotificationModel from "../../model/NotificationModel";
import {AlertColor} from "@mui/material";

export function toModel(response: INotificationResponse) {
    return new NotificationModel({
            id: response.id,
            title: response.title,
            severity: response.severity as AlertColor,
            content: response.content
        }
    );
}

export interface INotificationResponse {
    id: string,
    title: string,
    content: string,
    severity: string
}