import {ReduceStore} from "flux/utils";
import Immutable, {List} from "immutable";
import {ProjectEditorAction, ProjectEditorPayload} from "./ProjectEditorPayload";
import {dispatcher, Payload} from "../../Dispatcher";
import {Language} from "../../../model/Language";
import {WorkflowStepModel} from "../../../model/WorkflowStepModel";

interface IProjectEditorState {
    uploadFilesOpen: boolean,
    updateTranslationsOpen: boolean,
    files: Immutable.List<File>,
    multiLanguage: boolean,
    step: number,
    projectName: string,
    sourceLanguage: Language | null,
    targetLanguages: List<Language>,
    workflowType: string,
    workflowSteps: WorkflowStepModel | null,
    workflowEditorOpen: boolean
}

const ProjectEditorStateFactory = Immutable.Record<IProjectEditorState>({
    uploadFilesOpen: false,
    updateTranslationsOpen: false,
    files: Immutable.List<File>(),
    multiLanguage: false,
    step: 0,
    projectName: "",
    sourceLanguage: null,
    targetLanguages: List<Language>(),
    workflowType: "default",
    workflowSteps: null,
    workflowEditorOpen: false
});

class ProjectEditorState extends ProjectEditorStateFactory {
}

export class ProjectEditorStore extends ReduceStore<ProjectEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ProjectEditorState {
        return new ProjectEditorState();
    }

    reduce(state: ProjectEditorState, payload: ProjectEditorPayload): ProjectEditorState {
        switch (payload.action) {
            case ProjectEditorAction.SetFiles:
                return state.set("files", payload.files);
            case ProjectEditorAction.SetStep:
                return state.set("step", payload.step);
            case ProjectEditorAction.SetIsUploadFilesOpen:
                if (payload.uploadFilesOpen) {
                    return state.set("uploadFilesOpen", true);
                }
                return this.getInitialState();
            case ProjectEditorAction.SetIsUpdateTranslationsOpen:
                if (payload.updateTranslationsOpen) {
                    return state.set("updateTranslationsOpen", true);
                }
                return this.getInitialState();
            case ProjectEditorAction.SetMultiLanguageMode:
                return state.set("multiLanguage", payload.multiLanguageMode);
            case ProjectEditorAction.SetProjectName:
                return state.set("projectName", payload.projectName);
            case ProjectEditorAction.SetSourceLanguage:
                return state.set("sourceLanguage", payload.sourceLanguage);
            case ProjectEditorAction.SetTargetLanguages:
                return state.set("targetLanguages", payload.targetLanguages);
            case ProjectEditorAction.SetWorkflowType:
                return state.set("workflowType", payload.workflowType);
            case ProjectEditorAction.SetWorkflowSteps:
                return state.set("workflowSteps", payload.workflowSteps);
            case ProjectEditorAction.SetWorkflowEditorOpen:
                return state.set("workflowEditorOpen", payload.workflowEditorOpen);
        }
        return state;
    }
}

const projectEditorStore = new ProjectEditorStore();
export default projectEditorStore;