import {ArrowBackIos, Search} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Project from "../../model/Project";
import segmentListStore from "../../flux/segment/list/SegmentListStore";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import {InputAdornment, Menu, MenuItem, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Language} from "../../model/Language";
import {LanguageList} from "../../model/LanguageList";
import SegmentListFilter from "../../flux/segment/list/SegmentListFilter";
import languageListStore from "../../flux/language/list/LanguageListStore";
import {getSegmentPath} from "../../routes/editor/CatFileEditorRoute";
import Typography from "@mui/material/Typography";
import {OpenProps} from "../../globals/Types";
import Box from "@mui/material/Box";
import {DropdownMenuLinkShort} from "../../globals/CommonComponents";

const MenuLinkText = styled(Typography)({
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px'
});

const SearchField = styled(TextField)({
    padding: 1
});
export default function CatFileLanguageDropdown(props: OpenProps) {
    const projectState = projectPageStore.getState();
    const initialSegmentListFilterState = segmentListStore.getState();
    const initialLanguageListState = languageListStore.getState();
    const navigate = useNavigate();

    const [currentProject, setCurrentProject] = useState<Project>(projectState.project);
    const [filter, setFilter] = useState(initialSegmentListFilterState.filter);
    const [supportedLanguages, setSupportedLanguages] = useState(initialLanguageListState.languages);
    const [searchLanguageFilter, setSearchLanguageFilter] = useState("");
    const [sourceLanguage, setSourceLanguage] = useState<Language | null>(projectState?.project.source);
    const [selectLanguageMenuAnchor, setSelectLanguageMenuAnchor] = useState<HTMLElement | null>(null);
    const [selectLanguageMenuOpen, setSelectLanguageMenuOpen] = useState(false);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
            if (project) {
                setSourceLanguage(project.source);
            }
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setFilter(state.filter);
        });

        const languageListListener = languageListStore.addListener(() => {
            const state = languageListStore.getState();
            setSupportedLanguages(state.languages);
        });

        return () => {
            projectPageListener.remove();
            languageListListener.remove();
            segmentListListener.remove();
        }
    }, []);

    if (!props.isOpen)
        return null;

    return <Box>
        <DropdownMenuLinkShort variant="menu" onClick={e =>
            handleSelectLanguageMenuOpen(e, setSelectLanguageMenuAnchor, setSelectLanguageMenuOpen)}>
            <MenuLinkText>{sourceLanguage?.id.toUpperCase() + " " + "→" + " " + getTargetLanguage(supportedLanguages, filter)?.id.toUpperCase()}</MenuLinkText>
            <ArrowBackIos fontSize="inherit"
                          sx={{
                              marginLeft: '8px',
                              marginBottom: '7px',
                              fontSize: '0.75rem',
                              fontWeight: 'normal',
                              transform: 'rotate(270deg)',
                              alignItems: 'center',
                              display: 'flex'
                          }}/>
        </DropdownMenuLinkShort>
        <Menu open={selectLanguageMenuOpen} anchorEl={selectLanguageMenuAnchor}
              onClose={() => handleSelectLanguageMenuClosed(
                  setSelectLanguageMenuAnchor,
                  setSelectLanguageMenuOpen,
                  setSearchLanguageFilter)}>
            {drawLanguageMenuItems(
                currentProject,
                sourceLanguage,
                supportedLanguages,
                searchLanguageFilter,
                filter,
                setSelectLanguageMenuAnchor,
                setSelectLanguageMenuOpen,
                setSearchLanguageFilter,
                navigate)}
        </Menu>
    </Box>;
}

function handleSelectLanguageMenuOpen(event: React.MouseEvent<HTMLElement>,
                                      setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                                      setSelectLanguageMenuOpen: (o: boolean) => void) {
    setSelectLanguageMenuAnchor(event.currentTarget);
    setSelectLanguageMenuOpen(true);
}

function handleSelectLanguageMenuClosed(setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                                        setSelectLanguageMenuOpen: (o: boolean) => void,
                                        setSearchLanguageFilter: (s: string) => void) {
    setSelectLanguageMenuAnchor(null);
    setSelectLanguageMenuOpen(false);
    setSearchLanguageFilter("");
}

function getTargetLanguage(supportedLanguages: LanguageList, filter: SegmentListFilter) {
    const foundLanguage = supportedLanguages.findByCode(filter.nonNullLanguage);
    if (!foundLanguage)
        return null;
    return foundLanguage;
}

function drawLanguageMenuItems(currentProject: Project | null,
                               sourceLanguage: Language | null,
                               supportedLanguages: LanguageList,
                               searchLanguageFilter: string,
                               filter: SegmentListFilter,
                               setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                               setSelectLanguageMenuOpen: (o: boolean) => void,
                               setSearchLanguageFilter: (s: string) => void,
                               navigate: NavigateFunction) {
    const menuItems = [<SearchField key={"language-menu-search"} size={"small"}
                                    placeholder={"Search..."}
                                    slotProps={{
                                        input: {
                                            startAdornment: <InputAdornment position={"start"}>
                                                <Search/>
                                            </InputAdornment>
                                        }
                                    }}
                                    onChange={e =>
                                        handleSearchLanguageTextChanged(e, setSearchLanguageFilter)}
                                    onKeyDown={e => e.stopPropagation()}/>];
    currentProject?.targets.map(targetLanguage => {
        const foundLanguage = supportedLanguages.findByCode(targetLanguage.id);
        if (!foundLanguage || !foundLanguage.name.toLowerCase().includes(searchLanguageFilter))
            return;
        menuItems.push(<MenuItem key={"language-menu-" + foundLanguage.id}
                                 onClick={() => handleSelectLanguage(
                                     foundLanguage.id,
                                     filter,
                                     setSelectLanguageMenuAnchor,
                                     setSelectLanguageMenuOpen,
                                     setSearchLanguageFilter,
                                     navigate)}>
            {sourceLanguage?.id.toUpperCase() + " - " + foundLanguage.id.toUpperCase()}
        </MenuItem>);
    });
    return menuItems;
}

function handleSearchLanguageTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                         setSearchLanguageFilter: (s: string) => void) {
    setSearchLanguageFilter(event.target.value.toLowerCase());
}

function handleSelectLanguage(languageCode: string,
                              filter: SegmentListFilter,
                              setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                              setSelectLanguageMenuOpen: (o: boolean) => void,
                              setSearchLanguageFilter: (s: string) => void,
                              navigate: NavigateFunction) {
    handleSelectLanguageMenuClosed(setSelectLanguageMenuAnchor, setSelectLanguageMenuOpen, setSearchLanguageFilter);
    navigate(getSegmentPath(filter.set("language", languageCode), 0), {replace: true});
}

