import {Record} from "immutable";
import {RowsPerPageDefault} from "../globals/Constants";
import {IBackendPage} from "./Page";

interface IPageable {
    totalPages: number,
    totalElements: number,
    number: number,
    size: number
}

const PageableRecord = Record<IPageable>({
    totalPages: 0,
    totalElements: 0,
    number: 1,
    size: RowsPerPageDefault
})

export class Pageable extends PageableRecord {
    public static fromBackendPage(page: IBackendPage) {
        return new Pageable({
            totalPages: page.totalPages,
            totalElements: page.totalElements,
            number: page.number + 1,
            size: page.size
        });
    }

    public asBackendPage() {
        return new Pageable({
            totalPages: this.totalPages,
            totalElements: this.totalElements,
            number: this.number - 1,
            size: this.size
        });
    }

    get next() {
        let number = this.number + 1;
        if (number > this.totalPages)
            number = this.totalPages;

        return new Pageable({
            totalPages: this.totalPages,
            totalElements: this.totalElements,
            number: number,
            size: this.size
        });
    }

    public setBackendPage(pageNumber: number) {
        return new Pageable({
            totalPages: this.totalPages,
            totalElements: this.totalElements,
            number: pageNumber + 1,
            size: this.size
        });
    }

    // TODO remove it, and use infinite list
    static all() {
        return new Pageable({
            totalPages: 1,
            totalElements: 2000,
            number: 1,
            size: 2000
        });
    }
}