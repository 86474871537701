import {List, Record} from "immutable";
import {Tenant} from "./Tenant";
import {UserGroup} from "./UserGroup";

interface UserType {
    firstName: string,
    lastName: string,
    id: string,
    tenant: Tenant,
    email: string | null,
    groups: List<UserGroup>,
    enabled: boolean
}

const UserRecord = Record<UserType>({
    firstName: '',
    lastName: '',
    id: '',
    tenant: new Tenant(),
    email: null,
    groups: List(),
    enabled: true
});

export class User extends UserRecord {
    get name() {
        return this.firstName + " " + this.lastName;
    }
}