import React, {useEffect, useState} from "react";
import {Button, Container, Stack} from "@mui/material";
import {NavigateFunction, useLoaderData, useNavigate} from "react-router-dom";
import Project from "../../model/Project";
import Immutable from "immutable";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import {getCatFilesPath} from "../../routes/project/tabs/CatFilesRoute";
import pretranslateStore from "../../flux/pretranslate/PretranslateStore";
import PretranslateSettingView from "./PretranslateSettingView";
import {PretranslateSetting} from "../../model/PretranslateSetting";
import {
    pretranslateAction,
    refreshPretranslateStatusAction
} from "../../flux/pretranslate/PretranslateActions";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import PretranslateInProgressView from "./PretranslateInProgressView";
import {useInterval} from "../../globals/CustomHooks";

export default function PretranslateView() {
    const state = pretranslateStore.getState();

    const [settings, setSettings] = useState(state.settings);
    const [status, setStatus] = useState(state.status);
    const navigate = useNavigate();
    const project = useLoaderData() as Project;

    useEffect(() => {
        const listener = pretranslateStore.addListener(() => {
            const state = pretranslateStore.getState()
            setSettings(state.settings);
            setStatus(state.status);
        });

        return () => listener.remove();
    })

    useInterval(() => refreshPretranslateStatusAction(), 5000);

    if (status.inProgress)
        return <PretranslateInProgressView status={status}/>;

    return (
        <Container maxWidth={false}>
            <Stack spacing={5}>
                {drawSettings(settings)}
                <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button onClick={() => handleBackClicked(project, navigate)}>Back</Button>
                    <Button variant={"contained"}
                            disabled={PretranslateSetting.isInvalid(settings)}
                            onClick={async () => {
                                await pretranslateAction();
                                navigate(getCatFilesPath(new CatFilesFilter({projectId: project.id})));
                            }}>
                        Pretranslate
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
}

function drawSettings(settings: Immutable.List<PretranslateSetting>) {
    const project = projectPageStore.getState().project;
    return settings.map((setting, step) =>
        <PretranslateSettingView key={`pretranslate-setting-${step}`}
                                 setting={setting}
                                 step={step}
                                 selection={setting.catFilesSelection}
                                 project={project}/>
    );
}

function handleBackClicked(project: Project, navigate: NavigateFunction) {
    navigate(getCatFilesPath(new CatFilesFilter({projectId: project.id})));
}